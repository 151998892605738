// authApi.js
import axiosInstance from "./axios";

const authApi = {


  getcountries: (page, pageSize) => {
    // Replace 'your_countries_endpoint' with the actual endpoint for fetching countries
    return axiosInstance.get(`/general/types/Country?page=${page}&pageSize=${pageSize}`);
  },

  generatehotp: (country_code, phoneNumber) => {
    return axiosInstance.post('/generateotp', { country_code, phone: phoneNumber });
  },

  login: async (phone, otp) => {
    const response = await axiosInstance.post('/user/loginph', { phone, otp });
    console.log(response, "token3")
    return response;
  },

  register: (userData) => {
    // Replace 'your_register_endpoint' with the actual endpoint for user registration
    return axiosInstance.post('/register', userData);
  },
};
export default authApi;
