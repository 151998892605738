import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import MemberSection from './components/MemberSection';

export default function Member() {
  return (
    <div>
     
     <Header/>
      <MemberSection/>
      <Footer/>
      
    </div>
);
}
