import React from 'react';
import { BiLock, BiVideo, BiShield, BiBook, BiDollarCircle, BiMap, BiFlag, BiEnvelope } from 'react-icons/bi';
import Header from './Header';
import Footer from './Footer';

export default function Besafe() {
  return (
    <div>
      <Header />
      <section>
        <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
          <div className="carousel-item123" style={{ backgroundImage: `url("/images/Blue Grey Minimalist Modern Business Card  (1).png` }}></div>
        </div>

        <section className="why-choose-section pt-5 pb-5" style={{ backgroundColor: "#E0E0E0", backgroundPosition: 'center center' }}>
          <div className="container">
            <h2 className="text-center mb-5">BE SAFE ONLINE</h2>
            <p className='lead text-center' style={{ color: "#2C1154", fontSize: '0.9rem', fontWeight: 'normal', marginBottom: "50px" }}>
              We firmly believe in safeguarding your privacy as an inherent right, taking every measure to ensure your control and protection
            </p>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="card why-choose-card text-center">
                  <div className="card-body">
                    <BiLock className="icon-lg mb-2" />
                    <h5 className="card-title" style={{ fontSize: "20px" }}>Personal Data Privacy</h5>
                    <p className="card-text">Your personal privacy holds utmost importance to us. Our systems are intricately designed to empower you with control over all your personal information.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="card why-choose-card text-center">
                  <div className="card-body">
                    <BiVideo className="icon-lg mb-2" />
                    <h5 className="card-title" style={{ fontSize: "20px" }}>A Safe Way to Chat & Video Call & Contact</h5>
                    <p className="card-text">We facilitate direct communication between members, fostering transparency and trust.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="card why-choose-card text-center">
                  <div className="card-body">
                    <BiShield className="icon-lg mb-2" />
                    <h5 className="card-title" style={{ fontSize: "20px" }}>Stay Safe with Us</h5>
                    <p className="card-text">While we fortify our digital platform for your safety, we encourage you to conduct your own verifications. Prioritize safety by refraining from sharing sensitive account details and meeting in public places.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="card why-choose-card text-center">
                  <div className="card-body">
                    <BiBook className="icon-lg mb-2" />
                    <h5 className="card-title" style={{ fontSize: "20px" }}>Do not share account details</h5>
                    <p className="card-text">Avoid disclosing your account information like bank details or home address to individuals you have recently connected with.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="card why-choose-card text-center">
                  <div className="card-body">
                    <BiMap className="icon-lg mb-2" />
                    <h5 className="card-title" style={{ fontSize: "20px" }}>Meet at Public places</h5>
                    <p className="card-text">Opt for public venues like cafes, restaurants, or shopping centers for initial meetings with new acquaintances.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="card why-choose-card text-center">
                  <div className="card-body">
                    <BiDollarCircle className="icon-lg mb-2" />
                    <h5 className="card-title" style={{ fontSize: "20px" }}>Refrain from giving financial help</h5>
                    <p className="card-text">Exercise caution and resist offering financial aid to members who request it urgently, especially if they are newly acquainted.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-choose-section pt-5 pb-5" style={{ backgroundColor: "#E0E0E0", backgroundPosition: 'center center' }}>
          <div className="container">
            <h2 className="text-center mb-5">We stand united in this journey</h2>
            <p className='lead text-center' style={{ color: "#2C1154", fontSize: '0.9rem', fontWeight: 'normal', marginBottom: "50px" }}>We meticulously review every profile to ensure your safety and peace of mind. Should you encounter any profiles that raise concerns or witness misuse of our platform, we encourage you to report it promptly. Your vigilance is integral to upholding the integrity of our community.</p>
            <div className="row" >
              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card why-choose-card text-center h-100">
                  <div className="card-body">
                    <BiFlag className="icon-lg mb-2" />
                    <h5 className="card-title" style={{ fontSize: "20px" }}>Flag Profile</h5>
                    <p className="card-text">If you receive inappropriate messages or encounter suspicious profiles, please notify us immediately.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card why-choose-card text-center h-100">
                  <div className="card-body">
                    <BiEnvelope className="icon-lg mb-2" />
                    <h5 className="card-title" style={{ fontSize: "20px" }}>Reach out to us</h5>
                    <p className="card-text"> If you encounter offensive messages or suspect any profiles, reach out to us via email at Help@SamastiMatrimony.com, and our team will investigate promptly.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="text-center pb-5" style={{ backgroundColor: "#E0E0E0", minHeight: '8vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold', marginRight: '1rem', marginLeft: '1rem' }}>We are dedicated to prioritizing safety on this journey and collaborate closely with legal authorities to take the required measures</p>
        </section>


      </section>
      <Footer />
    </div>
  );
}
