import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Contactsection from './components/Contactsection';

export default function Contact() {
  return (
    <div>
     
     <Header/>
      <Contactsection/>
      <Footer/>
      
    </div>
);
}
