import React from 'react';
import { Link } from 'react-router-dom';
import { BiUserPlus, BiBrain, BiFilter } from 'react-icons/bi';
export default function Membership() {
  return (
    <div>

      <section className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#2C1154', padding: '30px 0' }}>
        <div className="container mt-5">
          <div className="row" style={{ marginRight: '30px', }}>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <h2 className='text-center text-white' style={{ marginTop: '10px' }}  >Membership Plans</h2>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-4">
              <div className="card">
                <div className="card-header bg-primary text-white">
                  <h4>Basic Plan</h4>
                </div>
                <div className="card-body">
                  <h5 className="card-title">₹500/month</h5>
                  <ul className="list-unstyled">
                    <li>Access to basic features</li>
                    <li>Limited matches per month</li>
                    <li>Email support</li>
                  </ul>
                  <Link to="/membership" className="btn btn-primary">View Plan</Link>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-4">
              <div className="card">
                <div className="card-header bg-success text-white">
                  <h4>Premium Plan</h4>
                </div>
                <div className="card-body">
                  <h5 className="card-title">₹1000/month</h5>
                  <ul className="list-unstyled">
                    <li>Access to premium features</li>
                    <li>Unlimited matches</li>
                    <li>24/7 customer support</li>
                  </ul>
                  <Link to="/membership" className="btn btn-success">View Plan</Link>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-4">
              <div className="card">
                <div className="card-header bg-warning text-white">
                  <h4>Ultimate Plan</h4>
                </div>
                <div className="card-body">
                  <h5 className="card-title">₹2000/month</h5>
                  <ul className="list-unstyled">
                    <li>Access to all features</li>
                    <li>Personalized matches</li>
                    <li>Priority support</li>
                  </ul>
                  <Link to="/membership" className="btn btn-warning ">View Plan</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-us-section pt-5 pb-100" style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: 'white' }}>
        <div className="container" style={{ marginTop: "-40px" }}>
          <div className="row align-items-center">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h2 style={{ color: "#2C1154", fontSize: '27px', fontWeight: 'bold', textAlign: 'center' }}>About Us</h2>
              <p className="lead responsive-text" style={{ color: "#2C1154", }}>
                Samasti Matrimony is a premier online matrimonial service committed to helping individuals find their life partners on a single platform. With a focus on creating meaningful alliances and successful marriages, our portal caters to people from diverse backgrounds, regions, castes, and religions. We provide comprehensive services to privileged Indians across Hindu, Sikh, Muslim, Christian, Jain, and other communities worldwide. Our user-friendly platform allows serious individuals to explore and connect with their potential life partners effortlessly. We offer a range of features that make the search for your dream partner a delightful experience. Our team of professionals ensures that we deliver superior services compared to other matrimonial platforms through in-depth analysis and an updated database.
              </p>
              <a href="/about" className="btn btn" style={{ backgroundColor: '#2C1154', marginBottom: "15px" }}>View More</a>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img src="/images/haldi.jpg" style={{ width: "100%", height: "350px", marginBottom: "80px" }} alt="Haldi Ceremony" />
            </div>
          </div>
        </div>
      </section>




      <section className="features" style={{
        backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#2C1154', height: 'auto',
        padding: '30px 0',
        color: 'white'
      }} >
        <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '100%' }}>
          <div className="text-center col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h1 className="section-title text-white" >Features</h1>
            <p className="section-title mb-4">Our matrimony platform is like a breath of fresh air. Clean and trendy design with ready-to-use features we are sure you will love.</p>
            <div className="row justify-content-center" style={{ marginRight: '30px', }}>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-4">
                <div className="card custom-card" >
                  <div className="card-body text-center">
                    <BiUserPlus size={40} style={{ color: '#2C1154' }} />
                    <h3 className="card-title mt-3" >Simple To Use</h3>
                    <p className="card-text">Simple steps to follow to have a matching connection.</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-4">
                <div className="card custom-card" >
                  <div className="card-body text-center">
                    <BiBrain size={40} style={{ color: '#2C1154' }} />
                    <h3 className="card-title mt-3">Smart Matching</h3>
                    <p className="card-text">Create connections with users that are like you and connect with them.</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-4">
                <div className="card custom-card" >
                  <div className="card-body text-center">
                    <BiFilter size={40} style={{ color: '#2C1154' }} />
                    <h3 className="card-title mt-3" >Filter Very Fast</h3>
                    <p className="card-text">Do not waste your time! Find only what you are interested</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




    </div>
  );
}
