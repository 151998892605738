import React from 'react';

const ActivePlans = ({ subscription }) => {
    // Get the current date
    const currentDate = new Date();

    // Calculate remaining days
    const endDate = new Date(subscription.endDate);
    const timeDifference = endDate - currentDate;
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Format the last date
    const formattedLastDate = endDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    let remainingDaysText;
    if (remainingDays === 0) {
        remainingDaysText = 'Today is the last day';
    } else if (remainingDays === 1) {
        remainingDaysText = '1 day remaining';
    } else {
        remainingDaysText = `${remainingDays} days remaining`;
    }

    return (
        <div style={styles.container}>
            <div className="membershipOffer" style={styles.membershipOffer}>
                <div>
                    <h2 style={styles.offerTitle}>{subscription.subscription.name}</h2>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={styles.offerPrice}>
                            ₹{subscription.subscription.price}
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                            {subscription.subscription.duration} /{parseInt(subscription.subscription.duration) === 1 ? "Month" : "Months"}
                        </span>
                    </div>
                    <div style={{ fontWeight: "600" }}>{`Last Date: ${formattedLastDate}`}</div>
                    <div style={{ fontWeight: "600" }}>{remainingDaysText}</div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#fff',
        padding: 16,
        borderRadius: 10,
        boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)',
        margin: 16,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 16,
        color: 'gray',
    },
    membershipOffer: {
        marginBottom: 20,
        border: '1px solid #ddd',
        padding: 16,
        borderRadius: 8,
        backgroundColor: '#fff',
        boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)',
    },
    offerTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'gray',
    },
    offerPrice: {
        fontSize: 18,
        color: 'gray',
        fontWeight: 'bold',
        marginBottom: 10,
    },
    offerDescription: {
        fontSize: 16,
        color: '#333',
    },
};

export default ActivePlans;
