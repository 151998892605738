import React from 'react';

export default function AboutSection() {
    return (
        <div>

            <section className="page-header-section ptb-50" style={{
                backgroundImage: `linear-gradient(to bottom, rgba(44, 17, 84, 0.8), rgba(44, 17, 84, 0.8)), url("/images/white.jpg")`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="page-header-content text-center">
                                <h1 className="mb-3" style={{ color: 'white', fontSize: '2.5rem', fontWeight: 'bold', marginTop: "50px" }}>About Us</h1>
                                <p className="lead text-white responsive-text" >Samasti Matrimony, we are dedicated to helping individuals find their perfect life partners. With a commitment to fostering meaningful connections.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* minheigh100 */}

            <section className="contact-us-section pt-5 pb-5" style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#2C1154', minHeight: '60vh' }}>
                <div className="container" style={{ marginTop: "30px" }}>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <p className="lead text-white responsive-text">Samasti Matrimony is a premier online matrimonial service committed to helping individuals find their life partners on a single platform. With a focus on creating meaningful alliances and successful marriages, our portal caters to people from diverse backgrounds, regions, castes, and religions. We provide comprehensive services to privileged Indians across Hindu, Sikh, Muslim, Christian, Jain, and other communities worldwide. Our user-friendly platform allows serious individuals to explore and connect with their potential life partners effortlessly. We offer a range of features that make the search for your dream partner a delightful experience. Our team of professionals ensures that we deliver superior services compared to other matrimonial platforms through in-depth analysis and an updated database.</p>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <img src="/images/wedding3.png" className="img-fluid" alt="Wedding" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="why-choose-section pt-5 pb-5" style={{ backgroundImage: "url('/image/background-4032775_1280.png')", backgroundPosition: 'center center', backgroundSize: 'cover', height: 'auto' }}>
                <div className="container">
                    <h2 className="text-center mb-5">Why Choose Samasti Matrimony</h2>
                    <div className="row" style={{ marginRight: '30px' }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card why-choose-card text-center">
                                <div className="card-body">
                                    <i className="bi bi-geo-alt-fill icon-lg mb-3"></i>
                                    <h5 className="card-title">Locality Matches</h5>
                                    <p className="card-text">We prioritize locality matches to ensure compatibility and ease of communication.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card why-choose-card text-center">
                                <div className="card-body">
                                    <i className="bi bi-person-badge-fill icon-lg mb-3"></i>
                                    <h5 className="card-title">Verified Contacts</h5>
                                    <p className="card-text">All profiles undergo stringent verification processes to ensure authenticity and reliability.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card why-choose-card text-center">
                                <div className="card-body">
                                    <i className="bi bi-chat-left-text-fill icon-lg mb-3"></i>
                                    <h5 className="card-title">Direct Communication</h5>
                                    <p className="card-text">We facilitate direct communication between members, fostering transparency and trust.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card why-choose-card text-center">
                                <div className="card-body">
                                    <i className="bi bi-images icon-lg mb-3"></i>
                                    <h5 className="card-title">Additional Photos</h5>
                                    <p className="card-text">Members can add multiple photos to their profiles, providing a comprehensive view of their personality and lifestyle.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="card why-choose-card text-center">
                                <div className="card-body">
                                    <i className="bi bi-shield-lock-fill icon-lg mb-3"></i>
                                    <h5 className="card-title">Enhanced Security</h5>
                                    <p className="card-text">Our platform employs advanced security measures to safeguard member information and privacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-us-section pt-5 pb-5" style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#2C1154' }}>
                <div className="container" style={{ marginTop: "-40px" }}>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <h2 className="text-white">Our Mission</h2>
                            <p className="lead text-white responsive-text">Samasti Matrimony is a premier online matrimonial service committed to helping individuals find their life partners on a single platform. With a focus on creating meaningful alliances and successful marriages, our portal caters to people from diverse backgrounds, regions, castes, and religions. We provide comprehensive services to privileged Indians across Hindu, Sikh, Muslim, Christian, Jain, and other communities worldwide. Our user-friendly platform allows serious individuals to explore and connect with their potential life partners effortlessly. We offer a range of features that make the search for your dream partner a delightful experience. Our team of professionals ensures that we deliver superior services compared to other matrimonial platforms through in-depth analysis and an updated database.</p>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <img src="/images/wedding2.png" className="img-fluid mb-5" alt="Wedding" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="services-section" style={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', paddingTop: '100px', paddingBottom: '100px', backgroundColor: 'white' }}>
                <div className="container" style={{ marginTop: "-40px" }}>
                    <h2 className="text-center" style={{ color: "#2C1154" }}>Our Service</h2>
                    <p className="lead text-black responsive-text">Samasti Matrimony is comprised of highly dedicated and experienced individuals who are proactive in offering their helping hands to our members and other people. We are deeply committed to our mission and objectives and have been striving to elevate our portal among the top matrimonial sites. Through our online and offline services, we aim to reach out to as many people as possible, enabling them to benefit from the incredible services offered by Samasti Matrimony.</p>
                    <div className="row" style={{ marginRight: '30px' }}>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                            <div className="card text-center">
                                <div className="card-body">
                                    <i className="bi bi-globe" style={{ fontSize: '3rem', color: '#2C1154' }}></i>
                                    <h5 className="card-title">Online and Offline Memberships</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                            <div className="card text-center">
                                <div className="card-body">
                                    <i className="bi bi-heart" style={{ fontSize: '3rem', color: '#2C1154' }}></i>
                                    <h5 className="card-title">Kundali Match</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                            <div className="card text-center">
                                <div className="card-body">
                                    <i className="bi bi-chat-dots" style={{ fontSize: '3rem', color: '#2C1154' }}></i>
                                    <h5 className="card-title">Direct Communication</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                            <div className="card text-center">
                                <div className="card-body">
                                    <i className="bi bi-shield-lock" style={{ fontSize: '3rem', color: '#2C1154' }}></i>
                                    <h5 className="card-title">Enhanced Security</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                <div className="carousel-item active" style={{ backgroundImage: `url("/images/white.jpg")` }}></div>
                <div className="carousel-item" style={{ backgroundImage: `url("/images/reingro.jpg")` }}></div>
                <div className="carousel-item" style={{ backgroundImage: 'url("/images/flowers.jpg")' }}></div>
                {/* <div className="carousel-item" style={{ backgroundImage: 'url("../assets/img/hero-carousel/hero-carousel-4.jpg")' }}></div> */}


            </div>
            <p className="text-center mt-4" style={{ fontSize: '1rem' }}>
                Samasti Matrimony is dedicated to bringing you genuine matchmaking services, not a dating app/website.
            </p>
        </div>
    );
}
