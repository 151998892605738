import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function UpdateAbout({ details }) {
    const maxLength = 150; // Maximum characters to display initially

    const [showAll, setShowAll] = useState(false);

    const displayText = details?.about ?? '';
    const truncatedText = displayText.substring(0, maxLength);

    const toggleText = () => {
        setShowAll(!showAll);
    };

    return (
        <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white', border: 'none', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.25)', }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <span style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginBottom: '16px'
                    }}>About</span>
                </div>

                {displayText ? (
                    <p style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap', overflow: 'hidden' }}>
                        {showAll ? displayText : `${truncatedText}${displayText.length > maxLength ? '...' : ''}`}
                    </p>
                ) : null}

                {displayText.length > maxLength && (
                    <div style={{ alignSelf: 'center', marginTop: '20px' }}>
                        <button style={{
                            padding: '12px 24px',
                            borderRadius: '25px',
                            alignItems: 'center',
                            backgroundColor: 'orange',
                            border: 'none',
                            cursor: 'pointer',
                            display: 'flex',
                        }} onClick={toggleText}>
                            <span style={{ color: 'white', fontSize: '16px' }}>
                                {showAll ? "Show Less" : "Read More"}
                            </span>
                            {showAll ? <ExpandLessIcon style={{ color: 'black' }} /> : <ExpandMoreIcon style={{ color: 'black' }} />}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
