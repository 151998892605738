// import React, { useState, useEffect } from 'react';
// import { useParams, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import chatApi from '../../app/Services/Api/chatApi';
// import Header2 from './Header2';
// import Footer2 from './Footer2';

// export default function Chat() {
//     const { conversationID } = useParams();
//     const location = useLocation();

//     const { profile_pic } = location.state || {};
//     const token = useSelector((state) => state.auth?.token);
//     const userId = useSelector((state) => state.auth?.user?.userId?._id);
//     const [messages, setMessages] = useState([]);
//     const [receiverId, setReceiverId] = useState('');
//     const [page, setPage] = useState(1);
//     const [loading, setLoading] = useState(false);
//     const [isOpen, setIsOpen] = useState(true);

//     useEffect(() => {
//         if (conversationID) {
//             getConversations();
//         }
//     }, [conversationID]);

//     const handleClose = () => {
//         setIsOpen(false);
//     };

//     const getConversations = async () => {
//         try {
//             setLoading(true);
//             const response = await chatApi.getAllMessages(token, conversationID, page);

//             const receiverId = response.messages.length > 0 ? response.messages[0].receiver._id : null;
//             setReceiverId(receiverId);

//             const formattedMessages = response.messages.map((message) => ({
//                 _id: message._id,
//                 text: message.content,
//                 createdAt: new Date(message.timestamp),
//                 user: {
//                     _id: message.sender._id === userId ? 1 : 2,
//                     name: message.sender.username,
//                     avatar: profile_pic,
//                 },
//             }));

//             setMessages((prevMessages) => [...prevMessages, ...formattedMessages]);
//         } catch (error) {
//             console.error('Error fetching conversations:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const onSend = async (newMessages = []) => {
//         try {
//             const message = {
//                 conversationId: conversationID,
//                 sender: userId,
//                 receiver: receiverId,
//                 content: newMessages[0].text,
//             };

//             const response = await chatApi.createMessage(token, message);

//             const sentMessage = {
//                 _id: response.newMessage._id,
//                 text: response.newMessage.content,
//                 createdAt: new Date(response.newMessage.timestamp),
//                 user: {
//                     _id: 1,
//                     name: 'USER',
//                     avatar: profile_pic,
//                 },
//             };

//             setMessages((prevMessages) => [...prevMessages, sentMessage]);
//         } catch (error) {
//             console.error('Error sending message:', error);
//         }
//     };

//     // const loadMoreMessages = async () => {
//     //     try {
//     //         const response = await chatApi.getAllMessages(token, conversationID, page + 1);

//     //         if (response.messages.length > 0) {
//     //             setPage((prevPage) => prevPage + 1);

//     //             const formattedMessages = response.messages.map((message) => ({
//     //                 _id: message._id,
//     //                 text: message.content,
//     //                 createdAt: new Date(message.timestamp),
//     //                 user: {
//     //                     _id: message.sender._id === userId ? 1 : 2,
//     //                     name: message.sender.username,
//     //                     avatar: profile_pic,
//     //                 },
//     //             }));

//     //             setMessages((prevMessages) => [...prevMessages, ...formattedMessages]);
//     //         }
//     //     } catch (error) {
//     //         console.error('Error loading more messages:', error);
//     //     }
//     // };
//     const loadMoreMessages = () => {
//         setPage(page + 1);
//     };


//     return (
//         <>
//             <Header2 />
//             <div className="mypref" style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 minHeight: '100vh',
//                 backgroundImage: 'linear-gradient(to bottom, rgba(44, 17, 84, 0.7), rgba(44, 17, 84, 0.45)), url("/images/swing.jpg")',
//                 backgroundSize: 'cover',
//                 backgroundPosition: 'center'
//             }}>
//                 <div className="container4" style={{ flex: 1, marginBottom: 10, justifyContent: 'center', backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', flexBasis: 'column', margin: '20px', padding: '20px', overflow: 'hidden', maxWidth: '800px', borderRadius: '10px' }}>

//                     {isOpen && (
//                         <>
//                             <a
//                                 href="/inbox"
//                                 onClick={handleClose}
//                                 style={{
//                                     position: 'absolute',
//                                     top: '10px',
//                                     right: '10px',
//                                     color: '#FFFFFF',
//                                     background: 'none',
//                                     border: 'none',
//                                     cursor: 'pointer',
//                                     fontSize: '35px',
//                                 }}
//                             >
//                                 ×
//                             </a>
//                             <div className="mesgs">
//                                 <div className="msg_history" id="msgHistory">
//                                     {loading && <div>Loading...</div>}
//                                     {messages.length > 0 && (
//                                         <button className="button" style={{ backgroundColor: "transparent", color: 'gray', margin: '30px' }} onClick={loadMoreMessages}>
//                                             Load earlier messages
//                                         </button>
//                                     )}
//                                     {/* {messages.map((message) => (
//                                         <div key={message._id} className={message.user._id === 1 ? "outgoing_msg" : "incoming_msg"}>
//                                             {message.user._id !== 1 && (
//                                                 <div className="incoming_msg_img">
//                                                     <img src={message.user.avatar} alt="user" />
//                                                 </div>
//                                             )}
//                                             <div className={message.user._id === 1 ? "sent_msg" : "received_msg"}>
//                                                 <p>{message.text}</p>
//                                                 <span className="time_date">{message.createdAt.toLocaleString()}</span>
//                                             </div>
//                                         </div>
//                                     ))} */}
//                                     {messages.map((message, index) => (
//                                         <div key={index} className={message.user._id === 1 ? "outgoing_msg" : "incoming_msg"}>
//                                             {message.user._id !== 1 && (
//                                                 <div className="rounded-circle">
//                                                     <img src={message.user.avatar} alt="user" style={{ height: 40, width: 40, objectFit: 'cover' }} />
//                                                 </div>
//                                             )}
//                                             <div className={message.user._id === 1 ? "sent_msg" : "received_msg"}>
//                                                 <p>{message.text}</p>
//                                                 <span className="time_date">{message.createdAt.toLocaleString()}</span>
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>
//                                 <div className="type_msg" >
//                                     <div className="input_msg_write">
//                                         <input
//                                             type="text"
//                                             className="write_msg"
//                                             placeholder="Type a message"
//                                             onKeyPress={(e) => {
//                                                 if (e.key === 'Enter') {
//                                                     onSend([{ text: e.target.value }]);
//                                                     e.target.value = '';
//                                                 }
//                                             }}
//                                             style={{ color: 'gray' }} />
//                                         <button
//                                             className="msg_send_btn"
//                                             type="button"
//                                             onClick={() => {
//                                                 const input = document.querySelector('.write_msg');
//                                                 onSend([{ text: input.value }]);
//                                                 input.value = '';
//                                             }}
//                                         >
//                                             <i className="bi bi-send-fill" aria-hidden="true" />
//                                         </button>
//                                     </div>
//                                 </div>

//                             </div>
//                         </>
//                     )}
//                 </div>
//             </div>
//             <Footer2 />
//         </>
//     );
// }

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import chatApi from '../../app/Services/Api/chatApi';
import Header2 from './Header2';
import Footer2 from './Footer2';
import { Avatar, IconButton, TextField, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './Chat.css'
export default function Chat() {
    const { conversationID } = useParams();
    const location = useLocation();
    const { profile_pic } = location.state || {};

    const token = useSelector((state) => state.auth?.token);
    const userId = useSelector((state) => state.auth?.user?.userId?._id);
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [receiverId, setReceiverId] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [newMessage, setNewMessage] = useState('');

    const chatRef = useRef(null);
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        if (conversationID) {
            getConversations();
        }
    }, [conversationID]);

    useEffect(() => {
        if (page > 1) {
            getConversations();
        }
    }, [page]);

    const handleClose = () => {
        setIsOpen(false);
        navigate('/inbox');  // Navigate to the inbox
    };

    const getConversations = async () => {
        try {
            setLoading(true);
            const response = await chatApi.getAllMessages(token, conversationID, page);

            const receiverId = response.messages.length > 0 ? response.messages[0].receiver._id : null;
            setReceiverId(receiverId);

            const formattedMessages = response.messages.map((message) => ({
                _id: message._id,
                text: message.content,
                createdAt: new Date(message.timestamp),
                user: {
                    _id: message.sender._id === userId ? 1 : 2,
                    name: message.sender.username,
                    avatar: profile_pic,
                },
            }));

            setMessages((prevMessages) => [...formattedMessages.reverse(), ...prevMessages]);

            // Adjust scroll position after loading
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight - scrollHeight;
            }
        } catch (error) {
            console.error('Error fetching conversations:', error);
        } finally {
            setLoading(false);
        }
    };

    const onSend = async () => {
        if (!newMessage.trim()) return;
        try {
            const message = {
                conversationId: conversationID,
                sender: userId,
                receiver: receiverId,
                content: newMessage,
            };

            const response = await chatApi.createMessage(token, message);

            const sentMessage = {
                _id: response.newMessage._id,
                text: response.newMessage.content,
                createdAt: new Date(response.newMessage.timestamp),
                user: {
                    _id: 1,
                    name: 'USER',
                    avatar: profile_pic,
                },
            };

            setMessages((prevMessages) => [sentMessage, ...prevMessages]);
            setNewMessage('');

            // Scroll to bottom after sending a message
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleScroll = () => {
        if (chatRef.current.scrollTop === 0 && !loading) {
            // Save current scroll height before loading more messages
            setScrollHeight(chatRef.current.scrollHeight);
            setPage((prevPage) => prevPage + 1);
        }
    };

    return (
        <>
            <Header2 />

            <div className="chat-container">
                <div className="chat-box">
                    {isOpen && (
                        <>
                            <IconButton className="close-button" style={{
                                color: 'white',
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                background: 'none',
                                border: 'none',
                                fontSize: '24px',
                                cursor: 'pointer',
                                padding: 0
                            }} onClick={handleClose}>
                                ×
                            </IconButton>
                            <div className="message-history" ref={chatRef} onScroll={handleScroll}>
                                {loading && <CircularProgress className="hidden-progress" style={{ color: '#2C1154' }} />}
                                {messages.map((message, index) => (
                                    <div key={index} className={message.user._id === 1 ? "outgoing-message" : "incoming-message"}>
                                        {message.user._id !== 1 && (
                                            <Avatar className="avatar11" src={message.user.avatar} />
                                        )}
                                        <div className={message.user._id === 1 ? "sent-message" : "received-message"}>
                                            <p>{message.text}</p>
                                            <span className="timestamp">{message.createdAt.toLocaleString()}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="message-input">
                                <input
                                    className="input-field"
                                    placeholder="Type a message"
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            onSend();
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <IconButton className="send-button" style={{ color: 'orange' }} onClick={onSend}>
                                    <SendIcon />
                                </IconButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Footer2 />
        </>
    );
}