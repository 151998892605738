import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import generaldata from '../../../Assets/GeneralData/generaldata';
import profileApi from '../profileApi';
import Select from 'react-select';

const EditFamily = ({ details }) => {
  const [selectedFatherStatus, setSelectedFatherStatus] = useState(null);
  const [selectedMotherStatus, setSelectedMotherStatus] = useState(null);
  const [selectedNativePlace, setSelectedNativePlace] = useState('');
  const [numberOfBrothers, setNumberOfBrothers] = useState('');
  const [numberOfSisters, setNumberOfSisters] = useState('');
  const [selectedFamilyType, setSelectedFamilyType] = useState(null);
  const [selectedFamilyValues, setSelectedFamilyValues] = useState(null);
  const [selectedFamilyAffluence, setSelectedFamilyAffluence] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.auth?.token);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const filterDataByType = (type) => {
    return generaldata.filter((item) => item.type === type).map((item) => ({
      value: item._id.$oid,
      label: item.name,
    }));
  };

  useEffect(() => {
    if (details?.fatherstatus) setSelectedFatherStatus({ value: details.fatherstatus._id, label: details.fatherstatus.name });
    if (details?.motherstatus) setSelectedMotherStatus({ value: details.motherstatus._id, label: details.motherstatus.name });
    if (details?.nativeplace) setSelectedNativePlace(details.nativeplace.toString());
    if (details?.noofbro) setNumberOfBrothers(details.noofbro.toString());
    if (details?.noofsis) setNumberOfSisters(details.noofsis.toString());
    if (details?.familytype) setSelectedFamilyType({ value: details.familytype._id, label: details.familytype.name });
    if (details?.familyvalues) setSelectedFamilyValues({ value: details.familyvalues._id, label: details.familyvalues.name });
    if (details?.familyaffluence) setSelectedFamilyAffluence({ value: details.familyaffluence._id, label: details.familyaffluence.name });
  }, [details]);

  const saveDetails = async () => {
    try {
      const data = {
        fatherstatus: selectedFatherStatus?.value,
        motherstatus: selectedMotherStatus?.value,
        nativeplace: selectedNativePlace,
        noofbro: parseInt(numberOfBrothers),
        noofsis: parseInt(numberOfSisters),
        familytype: selectedFamilyType?.value,
        familyvalues: selectedFamilyValues?.value,
        familyaffluence: selectedFamilyAffluence?.value
      };

      const response = await profileApi.updatefamily(token, data);
      if (response) {
        alert('Updated');
        toggleEditMode();
      }
    } catch (error) {
      console.error('Error saving details:', error);
    }
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      margin: '10px 0',
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #2C1154' : null,
      '&:hover': {
        borderColor: state.isFocused ? '#2C1154' : '#ccc',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2C1154' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#2C1154',
        color: 'white',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#333',
    }),
  };
  // col-xxl-6
  return (
    <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white' }}>
      <h2 style={{ color: 'white' }}>Family Details</h2>

      <button className='btn btn' style={{ marginBottom: 10, backgroundColor: 'orange', fontSize: '16px' }} onClick={isEditMode ? saveDetails : toggleEditMode}>
        {isEditMode ? 'Save' : 'Edit'}
      </button>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Father Status</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={filterDataByType('Fatherstatus')}
            value={selectedFatherStatus}
            onChange={(option) => setSelectedFatherStatus(option)}
            placeholder="Select Father Status"
          />
        ) : (
          <p>{selectedFatherStatus?.label}</p>
        )}
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Mother Status</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={filterDataByType('Motherstatus')}
            value={selectedMotherStatus}
            onChange={(option) => setSelectedMotherStatus(option)}
            placeholder="Select Mother Status"
          />
        ) : (
          <p>{selectedMotherStatus?.label}</p>
        )}
      </div>
      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Family Type</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={filterDataByType('Familytype')}
            value={selectedFamilyType}
            onChange={(option) => setSelectedFamilyType(option)}
            placeholder="Select Family Type"
          />
        ) : (
          <p>{selectedFamilyType?.label}</p>
        )}
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Family Values</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={filterDataByType('Familyvalues')}
            value={selectedFamilyValues}
            onChange={(option) => setSelectedFamilyValues(option)}
            placeholder="Select Family Values"
          />
        ) : (
          <p>{selectedFamilyValues?.label}</p>
        )}
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Family Affluence</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={filterDataByType('Familyaffluence')}
            value={selectedFamilyAffluence}
            onChange={(option) => setSelectedFamilyAffluence(option)}
            placeholder="Select Family Affluence"
          />
        ) : (
          <p>{selectedFamilyAffluence?.label}</p>
        )}
      </div>
      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Native Place</label>
        <input
          type="text"
          value={selectedNativePlace}
          onChange={(e) => setSelectedNativePlace(e.target.value)}
          disabled={!isEditMode}
        />
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Number of Brothers</label>
        <input
          type="number"
          value={numberOfBrothers}
          onChange={(e) => setNumberOfBrothers(e.target.value)}
          disabled={!isEditMode}
        />
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Number of Sisters</label>
        <input
          type="number"
          value={numberOfSisters}
          onChange={(e) => setNumberOfSisters(e.target.value)}
          disabled={!isEditMode}
        />
      </div>


    </div>
  );
};

export default EditFamily;
