import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-dark text-light py-4" style={{ backgroundColor: "#2C1154" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h5 style={{ color: "white" }}>Contact Us</h5>

            <p>Email: info@samastimatrymony.com</p>
            <p>Phone: +1 234 567 890</p>
          </div>
          <div className="col-md-3">
            <h5 style={{ color: "white" }}>Useful Links</h5>
            <ul style={{ listStyleType: "revert-layer", padding: 10 }}>
              <li> <a href="/" style={{ color: "white" }}>Home</a></li>
              <li> <a href="/membership" style={{ color: "white" }}>Membership</a></li>
              <li> <a href="/about" style={{ color: "white" }}>About Us</a></li>
              <li> <a href="/contact" style={{ color: "white" }}>Contact Us</a></li>
            </ul>
          </div>

          <div className="col-md-3">
            <h5 style={{ color: "white" }}>Privacy and You</h5>
            <ul style={{ listStyleType: "revert-layer", padding: 10 }}>
              <li> <a href="/terms" style={{ color: "white" }}>Terms of uses</a></li>
              <li> <a href="/privacy" style={{ color: "white" }}>Privacy policy</a></li>
              <li> <a href="/besafe" style={{ color: "white" }}>Be safe Online</a></li>
              <li> <a href="/report" style={{ color: "white" }}>Report Missuse</a></li>
            </ul>
          </div>


          <div className="col-md-3">
            <h5 style={{ color: "white" }}>Follow Us</h5>
            <p>Stay connected with us on social media</p>
            <div className="row">
              <div className='mr-2 col-2'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000" viewBox="0 0 512 512">
                  <rect width="512" height="512" fill="#000" rx="60"></rect>
                  <path fill="#fff" d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"></path>
                </svg>
              </div>

              <div className='mr-2 col-2'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                  <linearGradient id="a" x1="1.464" x2="14.536" y1="14.536" y2="1.464" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#FFC107"></stop>
                    <stop offset=".507" stopColor="#F44336"></stop>
                    <stop offset=".99" stopColor="#9C27B0"></stop>
                  </linearGradient>
                  <path fill="url(#a)" d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0 1.93-1.57 3.5-3.5 3.5H5c-1.93 0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5 3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z"></path>
                  <linearGradient id="b" x1="5.172" x2="10.828" y1="10.828" y2="5.172" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#FFC107"></stop>
                    <stop offset=".507" stopColor="#F44336"></stop>
                    <stop offset=".99" stopColor="#9C27B0"></stop>
                  </linearGradient>
                  <path fill="url(#b)" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"></path>
                  <linearGradient id="c" x1="11.923" x2="12.677" y1="4.077" y2="3.323" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#FFC107"></stop>
                    <stop offset=".507" stopColor="#F44336"></stop>
                    <stop offset=".99" stopColor="#9C27B0"></stop>
                  </linearGradient>
                  <circle cx="12.3" cy="3.7" r=".533" fill="url(#c)"></circle>
                </svg>
              </div>

              <div className='mr-2 col-2' >

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                  <path fill="#1976D2" d="M14 0H2C.897 0 0 .897 0 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2z"></path>
                  <path fill="#FAFAFA" fillRule="evenodd" d="M13.5 8H11V6c0-.552.448-.5 1-.5h1V3h-2a3 3 0 0 0-3 3v2H6v2.5h2V16h3v-5.5h1.5l1-2.5z" clipRule="evenodd"></path>
                </svg>
              </div>


            </div>
          </div>
        </div>

        <div className="footer-legal text-center" style={{ marginTop: '15px' }}>
          <div className="copyright text-center text-lg-left mb-3 mb-lg-0">
            &copy; Copyright <strong><span >Samasti Matrimony</span></strong>. All Rights Reserved
          </div>
          <div className="credits text-center text-lg-left" style={{ color: "white" }}>
            Designed by <a href="https://streakify.in" style={{ color: "skyblue", textAlign: "center", fontWeight: 'bold' }}>Streakify.in</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
