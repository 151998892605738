// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import { Country, State, City } from 'country-state-city';
// import profileApi from '../profileApi';
// import generaldata from '../../../Assets/GeneralData/generaldata';
// import { useSelector } from 'react-redux';



// const EditBasic = ({ details }) => {
//   const [selectedGender, setSelectedGender] = useState(null);
//   const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
//   const [selectedMotherTongue, setSelectedMotherTongue] = useState(null);
//   const [selectedReligion, setSelectedReligion] = useState(null);
//   const [selectedResidency, setSelectedResidency] = useState(null);
//   const [grownupcountry, setGrownUpCountry] = useState(null);
//   const [selectedCommunity, setSelectedCommunity] = useState(null);
//   const [selectedCountry, setSelectedCountry] = useState(null);
//   const [selectedState, setSelectedState] = useState(null);
//   const [selectedCity, setSelectedCity] = useState(null);
//   const [selectedDietPreference, setSelectedDietPreference] = useState('');
//   // const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
//   const [selectedBloodGroup, setSelectedBloodGroup] = useState('');
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [height, setHeight] = useState('');
//   const token = useSelector((state) => state.auth?.token);
//   const [loading, setLoading] = useState(false);
//   console.log(selectedCountry, selectedState, selectedCity, "prrrrrrr");

//   // const Countries = Country.getAllCountries().map((country) => ({
//   //   value: country.isoCode,
//   //   label: country.name,
//   // }));
//   const filteredStates = () => {
//     if (selectedCountry?.isoCode) {
//       const states = State.getStatesOfCountry(selectedCountry?.isoCode);
//       return states || [];
//     }
//     return [];
//   };

//   const filteredCities = () => {
//     if (selectedCountry?.isoCode) {
//       const cities = City.getCitiesOfState(selectedCountry?.isoCode, selectedState?.isoCode);
//       return cities || [];
//     }
//     return [];
//   };

//   const Countries = Country.getAllCountries();

//   const countriesArray = Object.entries(Country.getAllCountries()).map(([isoCode, country]) => ({
//     key: country.isoCode, // Access the ISO code property of the country object
//     label: country.name,
//   }));


//   const stateArray = Object.entries(State.getStatesOfCountry(selectedCountry?.key)).map(([isoCode, state]) => ({
//     key: state.isoCode,
//     label: state.name,
//   }));

//   // Assuming you have already imported the necessary functions or classes, such as City.getCitiesOfState

//   const cityArray = selectedCountry && selectedState
//     ? Object.entries(City.getCitiesOfState(selectedCountry?.key, selectedState?.key))
//       .map(([isoCode, city]) => ({
//         key: city.name,
//         label: city.name,
//       }))
//     : [];


//   useEffect(() => {
//     // Set initial values when component mounts
//     const {
//       religion,
//       gender,
//       maritalStatus,
//       country,
//       mothertongue,
//       community,
//       state,
//       diet,
//       blood_group,
//       // dateOfBirth,
//       city,
//       residencystatus,
//       grewupcountry,
//       height,
//     } = details;
//     console.log(religion,
//       gender,
//       maritalStatus,
//       country,
//       mothertongue,
//       community,
//       state,
//       diet,
//       blood_group,
//       // dateOfBirth,
//       city,
//       residencystatus,
//       grewupcountry,
//       height, "0000000000000000")
//     setSelectedGender({ value: gender, label: gender });
//     setSelectedMaritalStatus({ key: maritalStatus, label: maritalStatus });
//     setSelectedResidency({ value: residencystatus, label: residencystatus });
//     setSelectedReligion({ key: religion?._id, label: religion?.name });
//     setSelectedCountry(country);
//     setSelectedState(state);
//     setSelectedCity(city);
//     setGrownUpCountry(grewupcountry);
//     setSelectedBloodGroup({ key: blood_group?._id, label: blood_group?.name });
//     setSelectedMotherTongue({ key: mothertongue?._id, label: mothertongue?.name });
//     setSelectedCommunity({ key: community?._id, label: community?.name });
//     setSelectedDietPreference({ key: diet, label: diet });
//     setHeight(height?.toString());
//   }, [details]);
//   let countrytiso = ""
//   let countrytname = ""
//   let statetiso = ""
//   let statetname = ""
//   if (Country) {
//     const allCountries = Country.getAllCountries();
//     // Find the country object by its name
//     const countryObject = Object.values(allCountries).find(c => c.name === Country);


//     if (countryObject) {
//       // If the country object is found, set the selected country
//       setSelectedCountry({ key: countryObject.isoCode, label: Country });
//       countrytiso = countryObject.isoCode
//       countrytname = Country
//     }
//   }

//   // Assuming state and city are the names you have
//   if (State) {
//     const allStates = State.getAllStates(selectedCountry?.isoCode);
//     // Find the state object by its name
//     const stateObject = Object.values(allStates).find(s => s.name === State);

//     if (stateObject) {
//       // If the state object is found, set the selected state
//       setSelectedState({ key: stateObject.isoCode, label: State });
//       statetiso = stateObject.isoCode
//       statetname = State
//     }
//   }

//   if (City) {
//     const allCities = City.getCitiesOfState(countrytiso, statetiso);
//     // Find the city object by its name
//     const cityObject = Object.values(allCities).find(c => c.name === City);

//     if (cityObject) {
//       // If the city object is found, set the selected city
//       setSelectedCity({ key: cityObject.name, label: City });
//     }
//   }



//   if (grownupcountry) {
//     const allGrewUpCountries = Country.getAllCountries();
//     // Find the country object by its name
//     const grewUpCountryObject = Object.values(allGrewUpCountries).find(c => c.name === grownupcountry);

//     if (grewUpCountryObject) {
//       // If the country object is found, set the grewupcountry state
//       setGrownUpCountry({ key: grewUpCountryObject.isoCode, label: grownupcountry });
//     }
//   }
//   const toggleEditMode = () => {
//     setIsEditMode(!isEditMode);
//   };

//   const saveDetails = async () => {
//     try {
//       // Validate fields
//       const requiredFields = [
//         { field: selectedMaritalStatus, fieldName: 'Marital Status' },
//         { field: selectedReligion, fieldName: 'Religion' },
//         { field: selectedMotherTongue, fieldName: 'Mother Tongue' },
//         { field: selectedCommunity, fieldName: 'Community' },
//         { field: selectedBloodGroup, fieldName: 'Blood Group' },
//         { field: selectedCountry, fieldName: 'Country' },
//         { field: selectedState, fieldName: 'State' },
//         { field: selectedCity, fieldName: 'City' },
//         { field: grownupcountry, fieldName: 'Grew Up Country' },
//         { field: selectedResidency, fieldName: 'Residency' },
//         { field: height, fieldName: 'Height' },
//       ];

//       for (const item of requiredFields) {
//         if (!item.field) {
//           alert(`Please select ${item.fieldName}`);
//           return;
//         }
//       }

//       setLoading(true);
//       const data = {
//         maritalStatus: selectedMaritalStatus.key,
//         religion: selectedReligion.key,
//         mothertongue: selectedMotherTongue.key,
//         community: selectedCommunity.key,
//         blood_group: selectedBloodGroup.key,
//         country: selectedCountry.label,
//         state: selectedState.label,
//         city: selectedCity.label,
//         grewupcountry: grownupcountry.label,
//         residencystatus: selectedResidency.key,
//         diet: selectedDietPreference.key,
//         height: height.toString(),
//       };

//       const response = await profileApi.updatebasicdetails(token, data);
//       console.log(response, "basiccccccccc")
//       if (response) {
//         setLoading(false);
//         alert('Updated');
//         setIsEditMode(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading(false);
//     }
//   };

//   const filterDataByType = (type) => {
//     return generaldata.filter(item => item.type === type).map(item => ({
//       value: item._id.$oid,
//       label: item.name,
//     }));
//   };

//   // const formatDate = (dateString) => {
//   //   const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
//   //   return new Date(dateString).toLocaleDateString(undefined, options);
//   // };

//   const customStyles = {
//     container: (provided, state) => ({
//       ...provided,
//       width: '100%',
//       margin: '10px 0',
//     }),
//     control: (provided, state) => ({
//       ...provided,
//       border: '1px solid #ccc',
//       borderRadius: '4px',
//       boxShadow: state.isFocused ? '0 0 0 1px #2C1154' : null,
//       '&:hover': {
//         borderColor: state.isFocused ? '#2C1154' : '#ccc',
//       },
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isSelected ? '#2C1154' : 'white',
//       color: state.isSelected ? 'white' : 'black',
//       '&:hover': {
//         backgroundColor: '#2C1154',
//         color: 'white',
//       },
//     }),
//     singleValue: (provided, state) => ({
//       ...provided,
//       color: '#333',
//     }),
//   };
//   const options = [
//     { key: 'male', label: 'Male' },
//     { key: 'female', label: 'Female' },
//     { key: 'Other', label: 'Other' },
//   ];

//   const options1 = [
//     { key: 'Single', label: 'Single' },
//     { key: 'Married', label: 'Married' },
//     { key: 'Divorced', label: 'Divorced' },
//     { key: 'Divorcing', label: 'Divorcing' },
//   ];
//   const options2 = [
//     { key: 'Citizen', label: 'Citizen' },
//     { key: 'Permanent Resident', label: 'Permanent Resident' },
//     { key: 'Student Visa', label: 'Student Visa' },
//     { key: 'Work Permit', label: 'Work Permit' },
//   ];

//   const options3 = [
//     { key: 'Veg', label: 'Veg' },
//     { key: 'Jain', label: 'Jain' },
//     { key: 'Eggetarian', label: 'Eggetarian' },
//     { key: 'Non-Veg', label: 'Non-Veg' },
//     { key: 'Vegetarian', label: 'Vegetarian' },
//     { key: 'Vegan', label: 'Vegan' },
//     { key: 'Occasionally Non-Veg', label: 'Occasionally Non-Veg' },
//   ];
//   return (
//     <div className="card123" style={{ padding: '40px', backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white', border: 'none' }}>
//       <h2 style={{ color: 'white' }}>Basic Details</h2>

//       <button className="btn btn" style={{ marginBottom: 10, backgroundColor: 'orange', fontSize: '16px' }} onClick={isEditMode ? saveDetails : toggleEditMode}>
//         {isEditMode ? 'Save' : 'Edit'}
//       </button>


//       <div className="form-group">
//         <label>Gender</label>
//         {isEditMode ? (
//           <Select
//             options={options}
//             styles={customStyles}
//             value={selectedGender}
//             onChange={(option) => setSelectedGender(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Gender"
//             value={selectedGender?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>Marital Status</label>
//         {isEditMode ? (
//           <Select
//             options={options1}
//             styles={customStyles}
//             value={selectedMaritalStatus}
//             onChange={(option) => setSelectedMaritalStatus(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Marital Status"
//             value={selectedMaritalStatus?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>Height</label>
//         <input
//           className="input"
//           placeholder="Enter Height"

//           value={height}
//           readOnly={!isEditMode}
//           onChange={(e) => setHeight(e.target.value)}
//         />
//       </div>

//       <div className="form-group">
//         <label>Mother Tongue</label>
//         {isEditMode ? (
//           <Select
//             styles={customStyles}
//             options={filterDataByType('Mothertounge')}
//             value={selectedMotherTongue}
//             onChange={(option) => setSelectedMotherTongue(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Mother Tongue"
//             value={selectedMotherTongue?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>Community</label>
//         {isEditMode ? (
//           <Select
//             styles={customStyles}
//             options={filterDataByType('Community')}
//             value={selectedCommunity}
//             onChange={(option) => setSelectedCommunity(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Mother Tongue"
//             value={selectedCommunity?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>Religion</label>
//         {isEditMode ? (
//           <Select
//             styles={customStyles}
//             options={filterDataByType('Religion')}
//             value={selectedReligion}
//             onChange={(option) => setSelectedReligion(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Religion"
//             value={selectedReligion?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>Residency Status</label>
//         {isEditMode ? (
//           <Select
//             options={options2}
//             styles={customStyles}
//             value={selectedResidency}
//             onChange={(option) => setSelectedResidency(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Residency Status"
//             value={selectedResidency?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>Diet Preference</label>
//         {isEditMode ? (
//           <Select
//             options={options3}
//             styles={customStyles}
//             value={selectedDietPreference}
//             onChange={(option) => setSelectedDietPreference(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Diet Preference"
//             value={selectedDietPreference?.label || ''}
//             readOnly
//           />
//         )}
//       </div>
//       {/* 
//       <div className="form-group">
//         <label>Date of Birth</label>
//         {isEditMode ? (
//           <input
//             className="input"
//             type="date"
//             value={selectedDateOfBirth || ''}
//             onChange={(e) => setSelectedDateOfBirth(e.target.value)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Date of Birth"
//             value={selectedDateOfBirth ? formatDate(selectedDateOfBirth) : ''}
//             readOnly
//           />
//         )}
//       </div> */}


//       <div className="form-group">
//         <label>Blood Group</label>
//         {isEditMode ? (
//           <Select
//             styles={customStyles}
//             options={filterDataByType('Bloodgroup')}
//             value={selectedBloodGroup}
//             onChange={(option) => setSelectedBloodGroup(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Blood Group"
//             value={selectedBloodGroup?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>Grew Up In</label>
//         {isEditMode ? (
//           <Select
//             styles={customStyles}
//             options={Country.getAllCountries().map(country => ({
//               value: country.isoCode,
//               label: country.name
//             }))}
//             value={grownupcountry}
//             onChange={(option) => setGrownUpCountry(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Country"
//             value={grownupcountry?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>Country</label>
//         {isEditMode ? (
//           <Select
//             styles={customStyles}
//             options={Country.getAllCountries().map(country => ({
//               value: country.isoCode,
//               label: country.name
//             }))}
//             value={selectedCountry}
//             onChange={(option) => setSelectedCountry(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select Country"
//             value={selectedCountry?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>State</label>
//         {isEditMode ? (
//           <Select
//             styles={customStyles}
//             options={State.getStatesOfCountry(selectedCountry?.value).map(state => ({
//               value: state.isoCode,
//               label: state.name
//             }))}
//             value={selectedState}
//             onChange={(option) => setSelectedState(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select State"
//             value={selectedState?.label || ''}
//             readOnly
//           />
//         )}
//       </div>

//       <div className="form-group">
//         <label>City</label>
//         {isEditMode ? (
//           <Select
//             styles={customStyles}
//             options={City.getCitiesOfState(selectedCountry?.value, selectedState?.value).map(city => ({
//               value: city.name,
//               label: city.name
//             }))}
//             value={selectedCity}
//             onChange={(option) => setSelectedCity(option)}
//           />
//         ) : (
//           <input
//             className="input"
//             placeholder="Select City"
//             value={selectedCity?.label || ''}
//             readOnly
//           />
//         )}
//       </div>
//       {/* {isEditMode && (
//         <div className="form-group">
//           <button className="save-button" onClick={saveDetails} disabled={loading}>
//             {loading ? 'Saving...' : 'Save'}
//           </button>
//         </div>
//       )} */}
//     </div>
//   );
// };

// export default EditBasic;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';
import profileApi from '../profileApi';
import generaldata from '../../../Assets/GeneralData/generaldata';
import { useSelector } from 'react-redux';

const EditBasic = ({ details }) => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
  const [selectedMotherTongue, setSelectedMotherTongue] = useState(null);
  const [selectedReligion, setSelectedReligion] = useState(null);
  const [selectedResidency, setSelectedResidency] = useState(null);
  const [grownupcountry, setGrownUpCountry] = useState(null);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDietPreference, setSelectedDietPreference] = useState('');
  const [selectedBloodGroup, setSelectedBloodGroup] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [height, setHeight] = useState('');
  const token = useSelector((state) => state.auth?.token);
  const [loading, setLoading] = useState(false);

  const Countries = Country.getAllCountries();

  const countriesArray = Object.entries(Country.getAllCountries()).map(([isoCode, country]) => ({
    key: country.isoCode, // Access the ISO code property of the country object
    label: country.name,
  }));


  const stateArray = Object.entries(State.getStatesOfCountry(selectedCountry?.key)).map(([isoCode, state]) => ({
    key: state.isoCode,
    label: state.name,
  }));

  // Assuming you have already imported the necessary functions or classes, such as City.getCitiesOfState

  const cityArray = selectedCountry && selectedState
    ? Object.entries(City.getCitiesOfState(selectedCountry?.key, selectedState?.key))
      .map(([isoCode, city]) => ({
        key: city.name,
        label: city.name,
      }))
    : [];

  useEffect(() => {
    const {
      religion,
      gender,
      maritalStatus,
      country,
      mothertongue,
      community,
      state,
      diet,
      blood_group,
      city,
      residencystatus,
      grewupcountry,
      height,
    } = details;

    setSelectedGender({ key: gender, label: gender });
    setSelectedMaritalStatus({ key: maritalStatus, label: maritalStatus });
    setSelectedResidency({ key: residencystatus, label: residencystatus });
    setSelectedReligion({ key: religion?._id, label: religion?.name });
    setSelectedCountry(country ? { value: country, label: country } : null);
    setSelectedState(state ? { value: state, label: state } : null);
    setSelectedCity(city ? { value: city, label: city } : null);
    setGrownUpCountry(grewupcountry ? { value: grewupcountry, label: grewupcountry } : null);
    setSelectedBloodGroup({ key: blood_group?._id, label: blood_group?.name });
    setSelectedMotherTongue({ key: mothertongue?._id, label: mothertongue?.name });
    setSelectedCommunity({ key: community?._id, label: community?.name });
    setSelectedDietPreference({ key: diet, label: diet });
    setHeight(height?.toString());
  }, [details]);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const saveDetails = async () => {
    try {
      // Validate fields
      const requiredFields = [
        { field: selectedMaritalStatus, fieldName: 'Marital Status' },
        { field: selectedReligion, fieldName: 'Religion' },
        { field: selectedMotherTongue, fieldName: 'Mother Tongue' },
        { field: selectedCommunity, fieldName: 'Community' },
        { field: selectedBloodGroup, fieldName: 'Blood Group' },
        { field: selectedCountry, fieldName: 'Country' },
        { field: selectedState, fieldName: 'State' },
        { field: selectedCity, fieldName: 'City' },
        { field: grownupcountry, fieldName: 'Grew Up Country' },
        { field: selectedResidency, fieldName: 'Residency' },
        { field: height, fieldName: 'Height' },
      ];

      for (const item of requiredFields) {
        if (!item.field) {
          alert(`Please select ${item.fieldName}`);
          return;
        }
      }

      setLoading(true);
      const data = {
        maritalStatus: selectedMaritalStatus.key,
        religion: selectedReligion.key,
        mothertongue: selectedMotherTongue.key,
        community: selectedCommunity.key,
        blood_group: selectedBloodGroup.key,
        country: selectedCountry.label,
        state: selectedState.label,
        city: selectedCity.label,
        grewupcountry: grownupcountry.label,
        residencystatus: selectedResidency.key,
        diet: selectedDietPreference.key,
        height: height.toString(),
      };

      const response = await profileApi.updatebasicdetails(token, data);
      if (response) {
        setLoading(false);
        alert('Updated');
        setIsEditMode(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterDataByType = (type) => {
    return generaldata.filter(item => item.type === type).map(item => ({
      key: item._id.$oid,
      label: item.name,
    }));
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      margin: '10px 0',
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #2C1154' : null,
      '&:hover': {
        borderColor: state.isFocused ? '#2C1154' : '#ccc',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2C1154' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#2C1154',
        color: 'white',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#333',
    }),
  };

  const genderOptions = [
    { key: 'male', label: 'Male' },
    { key: 'female', label: 'Female' },
    { key: 'Other', label: 'Other' },
  ];

  const maritalStatusOptions = [
    { key: 'Single', label: 'Single' },
    { key: 'Married', label: 'Married' },
    { key: 'Divorced', label: 'Divorced' },
    { key: 'Divorcing', label: 'Divorcing' },
  ];

  const residencyOptions = [
    { key: 'Citizen', label: 'Citizen' },
    { key: 'Permanent Resident', label: 'Permanent Resident' },
    { key: 'Student Visa', label: 'Student Visa' },
    { key: 'Work Permit', label: 'Work Permit' },
  ];

  const dietOptions = [
    { key: 'Veg', label: 'Veg' },
    { key: 'Jain', label: 'Jain' },
    { key: 'Eggetarian', label: 'Eggetarian' },
    { key: 'Non-Veg', label: 'Non-Veg' },
    { key: 'Vegetarian', label: 'Vegetarian' },
    { key: 'Vegan', label: 'Vegan' },
    { key: 'Occasionally Non-Veg', label: 'Occasionally Non-Veg' },
  ];
  // card123
  return (
    <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white', border: 'none' }}>
      <h2 style={{ color: 'white' }}>Basic Details</h2>

      <button className="btn btn" style={{ marginBottom: 10, backgroundColor: 'orange', fontSize: '16px' }} onClick={isEditMode ? saveDetails : toggleEditMode}>
        {isEditMode ? 'Save' : 'Edit'}
      </button>

      <div className="form-group">
        <label>Gender</label>
        {isEditMode ? (
          <Select
            options={genderOptions}
            styles={customStyles}
            value={selectedGender}
            onChange={(option) => setSelectedGender(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Gender"
            value={selectedGender?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Marital Status</label>
        {isEditMode ? (
          <Select
            options={maritalStatusOptions}
            styles={customStyles}
            value={selectedMaritalStatus}
            onChange={(option) => setSelectedMaritalStatus(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Marital Status"
            value={selectedMaritalStatus?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Mother Tongue</label>
        {isEditMode ? (
          <Select
            options={filterDataByType('Mothertounge')}
            styles={customStyles}
            value={selectedMotherTongue}
            onChange={(option) => setSelectedMotherTongue(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Mother Tongue"
            value={selectedMotherTongue?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Religion</label>
        {isEditMode ? (
          <Select
            options={filterDataByType('Religion')}
            styles={customStyles}
            value={selectedReligion}
            onChange={(option) => setSelectedReligion(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Religion"
            value={selectedReligion?.label || ''}
            readOnly
          />
        )}
      </div>
      <div className="form-group">
        <label>Country</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={Country.getAllCountries().map(country => ({
              value: country.isoCode,
              label: country.name
            }))}
            value={selectedCountry}
            onChange={(option) => setSelectedCountry(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Country"
            value={selectedCountry?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>State</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={State.getStatesOfCountry(selectedCountry?.value).map(state => ({
              value: state.isoCode,
              label: state.name
            }))}
            value={selectedState}
            onChange={(option) => setSelectedState(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select State"
            value={selectedState?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>City</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={City.getCitiesOfState(selectedCountry?.value, selectedState?.value).map(city => ({
              value: city.name,
              label: city.name
            }))}
            value={selectedCity}
            onChange={(option) => setSelectedCity(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select City"
            value={selectedCity?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Grew Up Country</label>
        {isEditMode ? (
          <Select
            options={Country.getAllCountries().map(country => ({ value: country.name, label: country.name }))}
            styles={customStyles}
            value={grownupcountry}
            onChange={(option) => setGrownUpCountry(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Grew Up Country"
            value={grownupcountry?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Residency</label>
        {isEditMode ? (
          <Select
            options={residencyOptions}
            styles={customStyles}
            value={selectedResidency}
            onChange={(option) => setSelectedResidency(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Residency"
            value={selectedResidency?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Community</label>
        {isEditMode ? (
          <Select
            options={filterDataByType('Community')}
            styles={customStyles}
            value={selectedCommunity}
            onChange={(option) => setSelectedCommunity(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Community"
            value={selectedCommunity?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Blood Group</label>
        {isEditMode ? (
          <Select
            options={filterDataByType('Bloodgroup')}
            styles={customStyles}
            value={selectedBloodGroup}
            onChange={(option) => setSelectedBloodGroup(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Blood Group"
            value={selectedBloodGroup?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Diet Preference</label>
        {isEditMode ? (
          <Select
            options={dietOptions}
            styles={customStyles}
            value={selectedDietPreference}
            onChange={(option) => setSelectedDietPreference(option)}
          />
        ) : (
          <input
            className="input"
            placeholder="Select Diet Preference"
            value={selectedDietPreference?.label || ''}
            readOnly
          />
        )}
      </div>

      <div className="form-group">
        <label>Height</label>
        {isEditMode ? (
          <input
            type="text"
            className="input"
            placeholder="Enter Height"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        ) : (
          <input
            className="input"
            placeholder="Enter Height"
            value={height}
            readOnly
          />
        )}
      </div>
    </div>
  );
};

export default EditBasic;




