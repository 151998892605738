import React from 'react';
import { AccessTime, Star, Place, Brightness5, Group } from '@mui/icons-material'; // MUI icons
export default function UpdateHoroScope({ detailss, onPress, nav }) {
    // Dummy data for testing
    const details = {
        LocationOfBirth: detailss?.placeOfbirth,
        timeOfbirth: detailss?.timeOfBirth,
        Rashi: detailss?.rashi,
        Nakshatra: detailss?.nakshatra,
        gothra: detailss?.gothra,
    };
    console.log(details, "horoooo")
    return (
        <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white' }}>
            <div style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Horoscope Details</span>

                </div>

                <DetailRow icon={<Place style={{ color: 'white', fontSize: 32 }} />} label="Location Of Birth" value={details.LocationOfBirth} />
                <DetailRow icon={<AccessTime style={{ color: 'white', fontSize: 32 }} />} label="Time Of Birth" value={details.timeOfbirth} />
                <DetailRow icon={<Star style={{ color: 'white', fontSize: 32 }} />} label="Rashi" value={details.Rashi} />
                <DetailRow icon={<Brightness5 style={{ color: 'white', fontSize: 32 }} />} label="Nakshatra" value={details.Nakshatra} />
                <DetailRow icon={<Group style={{ color: 'white', fontSize: 32 }} />} label="Gothra" value={details.gothra} />
            </div>
        </div>
    );
}

const DetailRow = ({ icon, label, value }) => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }}>
        <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
            {icon}
        </div>
        <div style={{ flex: 1, marginLeft: '20px' }}>
            <span style={{ color: '#777' }}>{label}</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{value}</span>
            </div>
        </div>
    </div>
);
