// import React from 'react';

// export default function ContactSection() {
//     return (
//         <div>

//             <section className="page-header-section ptb-100" style={{ backgroundImage: `linear-gradient(to bottom, rgba(44, 17, 84, 0.8), rgba(44, 17, 84, 0.8)), url("/images/about.jpg")`,
//     backgroundPosition: 'center center',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat' }}>
//     <div className="container">
//         <div className="row justify-content-center">
//             <div className="col-md-8">
//                 <div className="page-header-content text-center">
//                     <h1 className="mb-3" style={{ color: 'white', fontSize: '2.5rem', fontWeight: 'bold',marginTop:"50px" }}>Contact Us</h1>
//                     <p className="lead text-white" style={{ fontSize: '1.25rem' }}>Need assistance with our matrimonial app? We're here to assist you!</p>
//                 </div>
//             </div>
//         </div>
//     </div>
// </section>


// {/*  <div className="contact-us-form rounded p-4" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', minHeight: '400px', border: '2px solid #87CEEB' }}> */}
// <section className="contact-us-section pt-5 pb-100" style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#2C1154', minHeight: '100vh', }}>
//     <div className="container" style={{padding :"50px",}}>
//         <div className="row align-items-center">
//             <div className="col-md-6" style={{border: '1px solid gray'}}>
//                 <div className="contact-us-form rounded p-4" style={{ minHeight: '400px' }}>
//                     <h2 className="text-center mb-4" style={{ color: 'white', fontSize: '1.8rem' }}>Have a question? Write us</h2>
//                     <form id="emailForm" role="form" className="php-email-form">
//                         <div className="row">
//                             <div className="col-md-6 form-group">
//                                 <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
//                             </div>
//                             <div className="col-md-6 form-group mt-3 mt-md-0">
//                                 <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
//                             </div>
//                         </div>
//                         <div className="form-group mt-4">
//                             <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
//                         </div>
//                         <div className="form-group mt-3">
//                             <textarea className="form-control" name="message" id="message" placeholder="Message" rows="5" required></textarea>
//                         </div>
//                         <div className="text-center mt-4">
//                             <button type="button" className="btn btn-primary btn-lg" style={{ backgroundColor: '#87CEEB', padding: '10px 30px', borderRadius: '5px', cursor: 'pointer' }}>Send Message</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//             <div className="col-md-6" >
//     <img src="/images/wedding1.png" style={{ width: "90%",marginleft:"100px"}} alt="Wedding Rings"></img>
//     <div className="mt-1 text-white text-center" style={{marginRight:"20px",marginTop:"200px"}}>
//         <p >Love is in the details. Let us help you with every step towards your happily ever after.</p>
//         <p>From finding your perfect match to planning the wedding of your dreams, we're here to make your journey to marriage as joyful and stress-free as possible.</p>
//         <p>Contact us today and start your journey to marital bliss!</p>
//     </div>
// </div>

//         </div>
//     </div>
// </section>

// <section className="page-header-section ptb-100" style={{ backgroundColor: "#2C1154", backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
//             <div class="container">
//                 <div class="row">
//                     <div class="col-lg-3 col-md-6 col-sm-6" >
//                         <div class="card single-promo-card single-promo-hover text-center">
//                             <div class="card-body py-5">
//                                 <div class="pb-2">
//                                     <span class="bi bi-telephone-fill icon-lg text-purple zoom-on-hover"></span>
//                                 </div>
//                                 <div>
//                                     <h5 class="mb-0">Call Us</h5>
//                                     <p class="text-muted mb-0">+91-9886176764</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="col-lg-3 col-md-6 col-sm-6">
//                         <div class="card single-promo-card single-promo-hover text-center">
//                             <div class="card-body py-5">
//                                 <div class="pb-2">
//                                     <span class="bi bi-geo-fill icon-lg text-purple zoom-on-hover"></span>
//                                 </div>
//                                 <div>
//                                     <h5 class="mb-0">Visit Us</h5>
//                                     <p class="text-muted mb-0">Banagalore,India-560034</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="col-lg-3 col-md-6 col-sm-6">
//                         <div class="card single-promo-card single-promo-hover text-center">
//                             <div class="card-body py-5">
//                                 <div class="pb-2">
//                                     <span class="bi bi-envelope-fill icon-lg text-purple zoom-on-hover"></span>
//                                 </div>
//                                 <div>
//                                     <h5 class="mb-0">Mail Us</h5>
//                                     <p class="text-muted mb-0">info@streakify.in</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="col-lg-3 col-md-6 col-sm-6">
//                         <div class="card single-promo-card single-promo-hover text-center">
//                             <div class="card-body py-5">
//                                 <div class="pb-2">
//                                     <span class="bi bi-chat-left-dots-fill icon-lg text-purple zoom-on-hover"></span>
//                                 </div>
//                                 <div>
//                                     <h5 class="mb-0">Live Chat</h5>
//                                     <p class="text-muted mb-0">Chat with Us 24/7</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>


// <section className="contact-us-section pt-5 pb-100" style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#2C1154',   }}>
//     <div className="container" >
//     <div className="row align-items-center">
//         <div className="col-md-6">
//           <h3 className="mb-3" style={{ color: 'white', fontSize: '2rem', fontWeight: 'bold',marginTop:"50px" }}>   </h3>
//             <p className="lead text-white" style={{fontSize:"17px"}}>Thank you for taking the time to connect with Samasti Matrimony. We understand that finding the right life partner is an important journey, and we're here to support you every step of the way. Feel free to explore our website, browse through our extensive profiles, and utilize our advanced features and tools designed to enhance your matrimonial experience.</p>
//         </div>
//         <div className="col-md-6">
//         <img src="/images/horse-7333877_1280.png" style={{ width: "40%", height: "300px", float: "right", marginRight: "100px",marginBottom:"16px" }} alt="Wedding Rings" />

//             </div>
//     </div>
// </div>
// </section>

//         </div>
//     );
// }


import React from 'react';

export default function Contactsection() {
    return (
        <div>
            <section className="page-header-section pt-5 pb-5" style={{
                backgroundImage: `linear-gradient(to bottom, rgba(44, 17, 84, 0.8), rgba(44, 17, 84, 0.8)), url("/images/about.jpg")`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 text-center">
                            <div className="page-header-content">
                                <h1 className="text-white mb-3" style={{ fontSize: '2.5rem', fontWeight: 'bold', marginTop: "50px" }}>Contact Us</h1>
                                <p className="lead text-white">Need assistance with our matrimonial app? We&apos;re here to assist you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-us-section pt-5 pb-5" style={{ backgroundColor: '#2C1154' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="contact-us-form rounded p-4" style={{ minHeight: '400px', border: '1px solid gray' }}>
                                <h2 className="text-white mb-4 text-center" style={{ fontSize: '1.8rem' }}>Have a question? Write us</h2>
                                <form id="emailForm" role="form" className="php-email-form">
                                    <div className="row" >
                                        <div className="col-md-6 form-group">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                        </div>
                                    </div>
                                    <div className="form-group mt-4">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                    </div>
                                    <div className="form-group mt-3">
                                        <textarea className="form-control" name="message" id="message" placeholder="Message" rows="5" required></textarea>
                                    </div>
                                    <div className="text-center mt-4">
                                        <button type="button" className="btn btn-lg btn-primary" style={{ backgroundColor: '#87CEEB', padding: '10px 30px', borderRadius: '5px', cursor: 'pointer' }}>Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-white text-center mt-4">
                                <img src="/images/wedding1.png" className="img-fluid" style={{ width: "80%", maxWidth: "400px" }} alt="Wedding Rings" />
                                <p className="mt-4">Love is in the details. Let us help you with every step towards your happily ever after.</p>
                                <p>From finding your perfect match to planning the wedding of your dreams, we&apos;re here to make your journey to marriage as joyful and stress-free as possible.</p>
                                <p>Contact us today and start your journey to marital bliss!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="page-header-section pt-5 pb-5" style={{ backgroundColor: "#2C1154", backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="row justify-content-center" style={{ marginRight: '30px' }}>
                                <div className="col-md-6 col-lg-3 mb-4">
                                    <div className="card single-promo-card single-promo-hover text-center">
                                        <div className="card-body py-5">
                                            <span className="bi bi-telephone-fill icon-lg text-purple zoom-on-hover"></span>
                                            <div>
                                                <h5 className="mb-0">Call Us</h5>
                                                <p className="text-muted mb-0">+91-1234567810</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 mb-4">
                                    <div className="card single-promo-card single-promo-hover text-center">
                                        <div className="card-body py-5">
                                            <span className="bi bi-geo-fill icon-lg text-purple zoom-on-hover"></span>
                                            <div>
                                                <h5 className="mb-0">Visit Us</h5>
                                                <p className="text-muted mb-0">Banagalore, India-560034</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 mb-4">
                                    <div className="card single-promo-card single-promo-hover text-center">
                                        <div className="card-body py-5">
                                            <span className="bi bi-envelope-fill icon-lg text-purple zoom-on-hover"></span>
                                            <div>
                                                <h5 className="mb-0">Mail Us</h5>
                                                <a href="mailto:info@samastimatrimony.com" className="text-muted mb-0">info@samastimatrimony.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 mb-4">
                                    <div className="card single-promo-card single-promo-hover text-center">
                                        <div className="card-body py-5">
                                            <span className="bi bi-chat-left-dots-fill icon-lg text-purple zoom-on-hover"></span>
                                            <div>
                                                <h5 className="mb-0">Live Chat</h5>
                                                <p className="text-muted mb-0">Chat with Us 24/7</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-us-section pt-5 pb-5" style={{ backgroundColor: '#2C1154' }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <p className="lead text-white">Thank you for taking the time to connect with Samasti Matrimony. We understand that finding the right life partner is an important journey, and we&apos;re here to support you every step of the way. Feel free to explore our website, browse through our extensive profiles, and utilize our advanced features and tools designed to enhance your matrimonial experience.</p>
                        </div>
                        <div className="col-md-6">
                            <img src="/images/horse-7333877_1280.png" className="img-fluid" style={{ width: "40%", height: "300px", float: "right", marginRight: "100px", marginBottom: "16px" }} alt="Wedding Rings" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
