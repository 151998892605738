// import React, { useState } from 'react';

// export default function PopularProfiles() {
//   const [selectedGender, setSelectedGender] = useState('All');
//   const profiles = [
//     { id: 1, name: 'smitha John', age: 23, image: '/images/000.jpg', gender: 'Female', about: ' im looking for girl passionated about her career,well settled' },
//     { id: 2, name: 'Karan', age: 28, image: '/images/man1.jpg', gender: 'Male', about: ' im looking for girl passionated about her career,well settled' },
//     { id: 3, name: 'iram', age: 25, image: '/images/ii.jpg', gender: 'Female', about: ' im looking for girl passionated about her career,well settled' },
//     { id: 5, name: 'robin', age: 39, image: '/images/olly.jpg', gender: 'Male', about: ' im looking for girl passionated about her career,well settled' },
//   ];

//   const filteredProfiles = selectedGender === 'All' ? profiles : profiles.filter(profile => profile.gender === selectedGender);

//   return (
//     <div className="container">
//       <div className="section-heading mb-3">
//         <h2 className="h1 mb-1" style={{ textAlign: "center" }}>Popular Profiles</h2>
//       </div>
//       <div className="text-center ">
//         <button
//           className={`btn-2 ${selectedGender === 'All' ? 'active' : ''}`}
//           onClick={() => setSelectedGender('All')}
//           style={{ margin: '0 10px', padding: '10px', fontSize: '16px', borderRadius: '10px' }}
//         >
//           All Profiles
//         </button>
//         <button
//           className={`btn-2 ${selectedGender === 'Male' ? 'active' : ''}`}
//           onClick={() => setSelectedGender('Male')}
//           style={{ margin: '0 10px', padding: '10px', fontSize: '16px', borderRadius: '10px' }}
//         >
//           Male
//         </button>
//         <button
//           className={`btn-2 ${selectedGender === 'Female' ? 'active' : ''}`}
//           onClick={() => setSelectedGender('Female')}
//           style={{ margin: '0 10px', padding: '10px', fontSize: '16px', borderRadius: '10px' }}
//         >
//           Female
//         </button>
//       </div>


//       <div className="row ">
//         {filteredProfiles.map(profile => (
//           <div className="col-md-6 col-lg-3 mt-3" key={profile.id}>
//             <div className="team-style1 text-center">
//               <img src={profile.image} className="border-radius-5" alt={profile.name} style={{ width: 440, height: 300, marginBottom: "10px" }} />
//               <div  >
//                 <h3 className="text- mb-1 h4" style={{ color: "#2C1154", fontWeight: "bold" }}>{profile.name}</h3>
//                 <p className="font-weight-600 text-" style={{ color: "gray", fontWeight: "bold" }}>{profile.age} Years Old</p>
//               </div>
//               <div className="team-overlay">
//                 <div className="d-table h-100 w-100">
//                   <div className="d-table-cell align-middle">
//                     <h5>
//                       {/* <a href="#" className="text-white">
//                         About {profile.about}
//                       </a> */}
//                     </h5>
//                     <p className="text-white mb-0">
//                       {/* Add description if needed */}
//                     </p>
//                     <a href="/myaccount" className="btn btn-primary mt-2">View Profile</a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }
import React, { useState } from 'react';


export default function PopularProfiles() {
  const [selectedGender, setSelectedGender] = useState('All');
  const profiles = [
    { id: 1, name: 'Smitha John', age: 23, image: '/images/000.jpg', gender: 'Female', about: 'I am a software engineer passionate about technology and innovation. I am looking for a partner who shares my enthusiasm for learning and growing together in both career and life.' },
    { id: 2, name: 'Karan', age: 28, image: '/images/man1.jpg', gender: 'Male', about: 'As a marketing professional, I am driven by creativity and results. I am seeking a like-minded partner who values ambition, stability, and enjoys exploring new experiences.' },
    { id: 3, name: 'Iram', age: 25, image: '/images/ii.jpg', gender: 'Female', about: 'I am a dedicated nurse who finds joy in helping others. I am looking for a compassionate and understanding partner who appreciates the importance of work-life balance and shared values.' },
    { id: 5, name: 'Robin', age: 39, image: '/images/olly.jpg', gender: 'Male', about: 'As a seasoned entrepreneur, I have built a successful career through hard work and perseverance. I am searching for a partner who is equally driven and committed to building a happy and fulfilling future together.' },
  ];


  const filteredProfiles = selectedGender === 'All' ? profiles : profiles.filter(profile => profile.gender === selectedGender);

  return (
    <div className="container">
      <div className="section-heading mb-3">
        <h2 className="h1 mb-1 text-center " style={{ marginTop: '20px' }}>Popular Profiles</h2>
      </div>
      <div className="text-center mb-3">
        <button
          className={`btn btn-outline-primary ${selectedGender === 'All' ? 'active' : ''}`}
          onClick={() => setSelectedGender('All')}
          style={{ margin: '0 10px', padding: '10px', fontSize: '16px', borderRadius: 'none' }}
        >
          All Profiles
        </button>
        <button
          className={`btn btn-outline-primary ${selectedGender === 'Male' ? 'active' : ''}`}
          onClick={() => setSelectedGender('Male')}
          style={{ margin: '0 10px', padding: '10px', fontSize: '16px', borderRadius: 'none' }}
        >
          Male
        </button>
        <button
          className={`btn btn-outline-primary ${selectedGender === 'Female' ? 'active' : ''}`}
          onClick={() => setSelectedGender('Female')}
          style={{ margin: '0 10px', padding: '10px', fontSize: '16px', borderRadius: 'none' }}
        >
          Female
        </button>
      </div>

      <div className="row" style={{ marginRight: '30px' }}>
        {filteredProfiles.map(profile => (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={profile.id}>
            <div className="card h-100 text-center">
              <img
                src={profile.image}
                className="card-img-top border-radius-5"
                alt={profile.name}
                style={{ width: '100%', height: '200px', objectFit: 'cover', marginBottom: '10px' }}
              />
              <div className="card-body">
                <h3 className="card-title h5" style={{ color: "#2C1154", fontWeight: "bold" }}>{profile.name}</h3>
                <p className="card-text" style={{ color: "gray", fontWeight: "bold" }}>{profile.age} Years Old</p>
                <p className="card-text text-truncate" style={{ color: "gray", fontWeight: "bold" }}>{profile.about}</p>
                <a href="/myaccount" className="btn btn-" style={{ background: '#2C1154' }}>View Profile</a>
              </div>

            </div>
          </div>
        ))}
      </div>

    </div>
  );
}
