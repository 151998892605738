import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import profileApi from '../profileApi';
import generaldata from '../../../Assets/GeneralData/generaldata';
import Select from 'react-select';

const EditCareer = ({ details }) => {
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [selectedWorkingIn, setSelectedWorkingIn] = useState(null);
  const [selectedIncomeRange, setSelectedIncomeRange] = useState(null);
  const [college, setCollege] = useState('');
  const [employer, setEmployer] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth?.token);

  useEffect(() => {
    if (details) {
      const { qualification, occupation, workingin, college, employer_name, income } = details;

      setSelectedEducation(qualification ? { value: qualification._id, label: qualification.name } : null);
      setSelectedOccupation(occupation ? { value: occupation._id, label: occupation.name } : null);
      setSelectedWorkingIn(workingin ? { value: workingin._id, label: workingin.name } : null);
      setCollege(college || '');
      setEmployer(employer_name || '');

      if (income) {
        if (income === '500000') {
          setSelectedIncomeRange({ value: income, label: '1 Lac to 5 Lacs' });
        } else if (income === '1000000') {
          setSelectedIncomeRange({ value: income, label: '5 Lacs to 10 Lacs' });
        } else if (income === '1500000') {
          setSelectedIncomeRange({ value: income, label: 'Above 10 Lacs' });
        } else {
          setSelectedIncomeRange(null); // handle unexpected values gracefully
        }
      } else {
        setSelectedIncomeRange(null); // handle undefined income
      }
    }
  }, [details]);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const saveDetails = async () => {
    setLoading(true); // set loading state before making API call
    try {
      const data = {
        qualification: selectedEducation ? selectedEducation.value : null,
        occupation: selectedOccupation ? selectedOccupation.value : null,
        workingin: selectedWorkingIn ? selectedWorkingIn.value : null,
        college,
        employer_name: employer,
        income: selectedIncomeRange ? selectedIncomeRange.value : null,
      };

      const response = await profileApi.updatecareer(token, data);
      console.log(response, "arrrrcccccccc")
      if (response) {
        alert('Updated');
        setLoading(false); // reset loading state
        setIsEditMode(false);
      }
    } catch (error) {
      console.error('Error saving details:', error);
      setLoading(false); // reset loading state on error
    }
  };

  const filterDataByType = (type) => {
    return generaldata.filter((item) => item.type === type).map((item) => ({
      value: item._id.$oid, // Adjust this if your IDs are not in this format
      label: item.name,
    }));
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      margin: '10px 0',
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #2C1154' : null,
      '&:hover': {
        borderColor: state.isFocused ? '#2C1154' : '#ccc',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2C1154' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#2C1154',
        color: 'white',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#333',
    }),
  };

  const incomeRanges = [
    { value: '500000', label: '1 Lac to 5 Lacs' },
    { value: '1000000', label: '5 Lacs to 10 Lacs' },
    { value: '1500000', label: 'Above 10 Lacs' },
  ];

  return (
    <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white' }}>
      <h2 style={{ color: 'white' }}>Career Details</h2>

      <button className='btn btn' style={{ marginBottom: 10, backgroundColor: 'orange', fontSize: '16px' }} onClick={isEditMode ? saveDetails : toggleEditMode}>
        {isEditMode ? 'Save' : 'Edit'}
      </button>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Qualification</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={filterDataByType('Qualification')}
            value={selectedEducation}
            onChange={(option) => setSelectedEducation(option)}
            placeholder="Select Qualification"
          />
        ) : (
          <p>{selectedEducation?.label}</p>
        )}
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Occupation</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={filterDataByType('Occupation')}
            value={selectedOccupation}
            onChange={(option) => setSelectedOccupation(option)}
            placeholder="Select Occupation"
          />
        ) : (
          <p>{selectedOccupation?.label}</p>
        )}
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Working In</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={filterDataByType('Workingin')}
            value={selectedWorkingIn}
            onChange={(option) => setSelectedWorkingIn(option)}
            placeholder="Select Working In"
          />
        ) : (
          <p>{selectedWorkingIn?.label}</p>
        )}
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Income Range</label>
        {isEditMode ? (
          <Select
            styles={customStyles}
            options={incomeRanges}
            value={selectedIncomeRange}
            onChange={(option) => setSelectedIncomeRange(option)}
            placeholder="Select Income Range"
          />
        ) : (
          <p>{selectedIncomeRange?.label}</p>
        )}
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Employer Name</label>
        {isEditMode ? (
          <input
            type="text"
            className="input"
            placeholder="Enter Employer Name"
            value={employer}
            onChange={(e) => setEmployer(e.target.value)}
          />
        ) : (
          <p>{employer}</p>
        )}
      </div>

      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>College Name</label>
        {isEditMode ? (
          <input
            type="text"
            className="input"
            placeholder="Enter College Name"
            value={college}
            onChange={(e) => setCollege(e.target.value)}
          />
        ) : (
          <p>{college}</p>
        )}
      </div>
    </div>
  );
};

export default EditCareer;
