import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import profileApi from '../profileApi';

const EditAbout = ({ details }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [displayText, setDisplayText] = useState(details ? details.about || '' : '');
  const [loading, setLoading] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const token = useSelector((state) => state.auth?.token);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (displayText) {
        const response = await profileApi.updateabout(token, displayText);
        console.log(response);
        setIsEditing(false);
        setLoading(false);
        alert("Updated");
      } else {
        setLoading(false);
        alert("About should not be empty");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Something went wrong");
    }
  };

  const handleReadMore = () => setShowFullText(true);
  const handleShowLess = () => setShowFullText(false);

  const truncatedText = displayText.split(' ').slice(0, 20).join(' '); // Limit to 20 words

  return (
    <div >
      <div className="card5 " style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white', border: 'none', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.25)', padding: '40px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h2 className="title" style={{ marginBottom: 16, textAlign: 'left', color: 'white', padding: '10px' }}>About Yourself</h2>
          {isEditing ? (
            <textarea
              className="input textarea"
              style={{ height: 100, border: '0.2px solid gray', marginBottom: 10, paddingLeft: 10, resize: 'vertical', overflow: 'auto', overflowX: 'hidden' }}
              placeholder="Type something..."
              value={displayText}
              onChange={(e) => setDisplayText(e.target.value)}
            />
          ) : (
            <>
              <p className="displayText" style={{ fontSize: 14, maxHeight: showFullText ? 'none' : '100px', overflowY: 'auto', overflowX: 'hidden', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                {showFullText ? displayText : truncatedText}
              </p>
              {displayText.split(' ').length > 20 && (
                <button
                  className="btn btn"
                  style={{ padding: 10, backgroundColor: showFullText ? 'orange' : '#2C1154', borderRadius: 10, width: 'auto', textAlign: 'center', color: 'white', marginTop: 10 }}
                  onClick={showFullText ? handleShowLess : handleReadMore}
                >
                  {showFullText ? 'Show Less' : 'Read More'}
                </button>
              )}
            </>
          )}
          {isEditing && (
            <button
              className="btn btn"
              style={{ marginTop: 10, padding: 10, backgroundColor: '#2C1154', borderRadius: 10, width: 70, textAlign: 'center', color: 'white', marginLeft: '10px' }}
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          )}
        </div>
        {!isEditing && (
          <button
            className="btn btn"
            style={{ marginTop: 10, padding: 10, backgroundColor: 'orange', borderRadius: 10, width: 70, textAlign: 'center', marginLeft: '10px' }}
            onClick={handleEdit}
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default EditAbout;
