import React, { useEffect, useState } from 'react';

import { FaQuestionCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../app/redux/authActions';
import profileApi from '../../app/Services/Api/profileApi';

export default function Header2() {


  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth?.token);
  const handleLogout = () => {
    console.log('User is logging out...');
    dispatch(logout());
    localStorage.removeItem('token'); // Clear the token from localStorage or any other storage
    navigate('/myaccount'); // Redirect to the login page
  };


  const [showAppSection, setShowAppSection] = useState(false);

  const toggleAppSection = () => {

    setShowAppSection(!showAppSection);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    try {
      setLoading(true);
      const response = await profileApi.getprofile(token);
      console.log(response); // Log the response to inspect its structure
      setDetails(response.profile);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  return (
    <header>
      <div id="loader"></div>
      <div className="container-fluid"  >
        <nav className="navbar navbar-expand-lg navbar-light bg-light" >
          <div className="container" style={{ background: '#f8f9fa' }}>
            <a className="navbar-brand" href="#" style={{ fontSize: '1.7rem', fontWeight: 'bold', color: '#2C1154', marginRight: "110px", marginBottom: "15px", }}>Samasti Matrimony</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">

              <svg fill="gray" height="25px" width="25px" viewBox="0 0 56 56">
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M28,0C12.561,0,0,12.561,0,28s12.561,28,28,28s28-12.561,28-28S43.439,0,28,0z M28,54C13.663,54,2,42.336,2,28 S13.663,2,28,2s26,11.664,26,26S42.337,54,28,54z"></path>
                    <path d="M40,16H16c-0.553,0-1,0.448-1,1s0.447,1,1,1h24c0.553,0,1-0.448,1-1S40.553,16,40,16z"></path>
                    <path d="M40,27H16c-0.553,0-1,0.448-1,1s0.447,1,1,1h24c0.553,0,1-0.448,1-1S40.553,27,40,27z"></path>
                    <path d="M40,38H16c-0.553,0-1,0.448-1,1s0.447,1,1,1h24c0.553,0,1-0.448,1-1S40.553,38,40,38z"></path>
                  </g>
                </g>
              </svg>
            </button>
            <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
              <ul className="navbar-nav" style={{ fontSize: '1.1rem', marginBottom: "15px", }}>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard"> DashBoard</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/mypreference"> Partner Preference</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/packages"> Premium </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/search"> Search</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/inbox"> Inbox</a>
                </li>
              </ul>
              <div className="col-auto d-flex align-items-center" style={{ marginBottom: "15px" }}>

                <div className="header-element message-dropdown me-3">
                  <a
                    aria-label="anchor"
                    href="/inbox"

                  >
                    <i className="fe fe-message-square header-link-icon" style={
                      { fontSize: "20px", color: "#2C1154" }
                    } />

                  </a>

                </div>
                <div className="header-element main-profile-user me-3">
                  <a
                    href="/myprofile"
                    id="mainHeaderProfile"

                  >
                    <div className="d-flex align-items-center">
                      <div className="me-xxl-2 ">
                        <i className="fas fa-user-circle fa-3x" style={{ color: '#2C1154' }}></i>
                        {/* <p style={{ fontSize: "11px", textAlign: 'center' }}>profile</p> */}
                      </div>
                      <div className="d-xxl-block d-none my-auto">

                      </div>
                    </div>
                  </a>

                </div>
                <div className="header-element main-profile-user me-3">
                  <a
                    href="#"
                    // className="header-link dropdown-toggle"
                    id="mainHeaderProfile"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center">
                      <div className="me-xxl-2 me-0">
                        <i className="ri-settings-3-line fs-18 me-3" style={
                          { fontSize: "25px", color: "#2C1154" }} />
                      </div>

                    </div>
                  </a>
                  <ul
                    className="main-header-dropdown dropdown-menu pt-0 header-profile-dropdown dropdown-menu-end "
                    aria-labelledby="mainHeaderProfile"
                  >
                    <li>
                      <a className="dropdown-item" href="/myprofile">
                        <i className="ri-account-circle-line fs-18 me-3" />Profile
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/inbox">
                        <i className="ri-mail-send-line fs-18 me-3" />Inbox
                        {/* <span className="badge bg-danger-transparent ms-auto">5</span> */}
                      </a>
                    </li>


                    <li>
                      <a className="dropdown-item" href="#" onClick={handleLogout}>
                        <i className="ri-logout-circle-r-line fs-18 me-3" />Logout
                      </a>
                    </li>
                  </ul>

                </div>
                <div className="nav-item dropdown" style={{ position: 'relative' }}>
                  <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" onClick={toggleAppSection} aria-haspopup="true" aria-expanded="false" style={{ textDecoration: 'none', color: '#2C1154', fontSize: '1.1rem', }}>
                    <FaQuestionCircle style={{ fontSize: "20px", }} />
                    Help
                  </a>
                  <div className={`dropdown-menu ${showAppSection ? 'show' : ''}`} aria-labelledby="navbarDropdown" style={{ backgroundColor: 'rgba(229, 232, 232, 0.8)', borderRadius: "1px" }}>
                    <div className="dropdown-item" >
                      <h5>Contact Us:</h5>
                      <p>+1234567890</p>
                      <h5>Our Team:</h5>
                      <p>Our dedicated team is here to assist you.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </nav>
      </div>
      <div className="scrollToTop">
        <span className="arrow"><i className="ri-arrow-up-s-fill fs-20"></i></span>
      </div>
      <div id="responsive-overlay"></div>
    </header>
  );
}
