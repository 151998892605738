import React, { useState } from 'react';
import { RiArrowUpSFill } from 'react-icons/ri';
import { FaQuestionCircle } from 'react-icons/fa';

export default function Header() {
  const [showAppSection, setShowAppSection] = useState(false);

  const toggleAppSection = () => {
    setShowAppSection(!showAppSection);
  };

  return (
    <header style={{ fontSize: '1.1rem' }}>
      <div id="loader"></div>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a className="navbar-brand" href="/" style={{ fontSize: '1.7rem', fontWeight: 'bold', color: '#2C1154' }}>Samasti Matrimony</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
              <ul className="navbar-nav" style={{ fontSize: '1.1rem' }}>
                <li className="nav-item">
                  <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/membership">Membership</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="nav-item" style={{}}>
              <a href="/myaccount" className="button" style={{ textDecoration: 'none', fontSize: '1.0rem', borderRadius: '8px', color: 'white', backgroundColor: "#2C1154", top: "10px" }}>Login</a>
            </div>

            <div className="nav-item dropdown" style={{ position: 'relative' }}>
              <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" onClick={toggleAppSection} aria-haspopup="true" aria-expanded="false" style={{ textDecoration: 'none', color: '#2C1154', fontSize: '1.1rem', }}>
                <FaQuestionCircle style={{ marginRight: '5px', fontSize: '20px' }} />
                Help
              </a>
              <div className={`dropdown-menu ${showAppSection ? 'show' : ''}`} aria-labelledby="navbarDropdown" style={{ backgroundColor: 'rgba(229, 232, 232, 0.8)', borderRadius: "1px", }}>
                <div className="dropdown-item" >
                  <h5>Contact Us:</h5>
                  <p>+1234567890</p>
                  <h5>Our Team:</h5>
                  <p>Our dedicated team is here to assist you.</p>
                </div>
              </div>
            </div>

          </div>
        </nav>
      </div>
      <div className="scrollToTop">
        <span className="arrow"><RiArrowUpSFill className="fs-20" /></span>
      </div>
      <div id="responsive-overlay"></div>
    </header>
  );
}
