
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header2 from './Header2';
import Footer2 from './Footer2';
import profileApi from '../../app/Services/Api/profileApi';


export default function MyProfile() {
  const [details, setDetails] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth?.token);
  const longText = "Your long text goes here..."; // Replace with actual long text
  const maxLength = 100; // Define your desired max length

  useEffect(() => {
    getProfileData();
  }, []);

  const toggleText = () => {
    if (showAll) {
      setDisplayText(longText.substring(0, maxLength));
    } else {
      setDisplayText(longText);
    }
    setShowAll(!showAll);
  };

  const getProfileData = async () => {
    try {
      setLoading(true);
      const response = await profileApi.getprofile(token);
      console.log(response); // Log the response to inspect its structure
      setDetails(response.profile);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    return age;
  };
  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <p>Loading...</p>
      </div>
    );
  }

  // Log the details to inspect its structure
  console.log(details, "from me");

  return (
    <div>
      <Header2 />
      <div style={{ background: 'lightgray', minHeight: '100vh', padding: '20px' }}>
        <div className="container-fluid" >
          <div className="row" >
            <div className="col-lg-4 border-end border-white border-1">
              <div className="d-flex flex-column align-items-center">
                <h5 className='me-2 fw-semibold' style={{ color: '#2C1154', fontSize: '1.7rem', fontWeight: 'bold' }}>My Profile</h5>
                <div>
                  <img
                    src={!details.imageprivacy ? (details?.profile_pich || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png') : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                    alt="Profile"
                    className="rounded-circle"
                    style={{ height: '300px', width: '300px', objectFit: 'cover' }}
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <h5 className="fw-semibold mb-1" style={{ color: '#2C1154', textAlign: "center", marginTop: "20px", fontSize: "1.5rem" }}>{details.fullName}</h5>
                    {details.dateOfBirth && <p className="mb-1 text-muted" style={{ color: '#2C1154', textAlign: "center" }}>{calculateAge(details.dateOfBirth)}years old</p>}
                    {details.maritalStatus && <p className="fs-12 op-7 mb-0" style={{ color: '#2C1154', textAlign: "center" }}>{details.maritalStatus}| {details.gender}</p>}

                  </div>
                </div>
                <div className="col-md-12" >
                  <ul className="list-group border-1" style={{ background: 'lightgray' }}>
                    <li className="list-group-item border-1" style={{ background: 'lightgray' }}>
                      <div className="d-flex align-items-start" style={{ background: 'lightgray' }}>
                        <span className="fs-15" style={{ color: "#2C1154", wordBreak: 'break-word', maxWidth: '100%' }}>
                          {details.about}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mx-auto">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h5 className='me-2 fw-semibold' style={{ color: '#2C1154', fontSize: '1.5rem', borderBottom: '1px solid #2C1154', paddingBottom: '5px' }}>Basic Info</h5>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Country:</strong> {details.country}
                  </p>
                  {details.residencystatus && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Religion:</strong> {details.residencystatus}
                  </p>}

                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>State:</strong> {details.state}
                  </p>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>City:</strong> {details.city}
                  </p>
                  {details.religion && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Religion:</strong> {details.religion.name}
                  </p>}

                  {details.community && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Community:</strong> {details.community.name}
                  </p>}
                  {details.mothertongue && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Mother Tongue:</strong> {details.mothertongue.name}
                  </p>}
                  {details.blood_group && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Blood Group:</strong> {details.blood_group.name}
                  </p>}
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Height:</strong> {details.height}
                  </p>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Place of Birth:</strong> {details.placeOfbirth}
                  </p>
                  {details.grewupcountry && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Grew Up In:</strong> {details.grewupcountry.name}
                  </p>}
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Native Place:</strong> {details.nativeplace}
                  </p>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Diet:</strong> {details.diet}
                  </p>
                </div>
                <div className="col-md-6">
                  <h5 style={{ color: '#2C1154', fontSize: '1.5rem', borderBottom: '1px solid #2C1154', paddingBottom: '5px' }}>Family Details</h5>
                  {details.familyaffluence && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Family Status:</strong> {details.familyaffluence.name}
                  </p>}
                  {details.familytype && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Family Type:</strong> {details.familytype.name}
                  </p>}
                  {details.familyvalues && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Family Values:</strong> {details.familyvalues.name}
                  </p>}
                  {details.fatherstatus && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Fathers Occupation:</strong> {details.fatherstatus.name}
                  </p>}
                  {details.motherstatus && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Mothers Occupation:</strong> {details.motherstatus.name}
                  </p>}
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Number of Brothers:</strong> {details.noofbro}
                  </p>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Number of Sisters:</strong> {details.noofsis}
                  </p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6">
                  <h5 style={{ color: '#2C1154', fontSize: '1.5rem', borderBottom: '1px solid #2C1154', paddingBottom: '5px' }}>Horoscope Details</h5>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Place of Birth:</strong> {details.placeOfbirth}
                  </p>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Time of Birth:</strong> {details.timeOfBirth}
                  </p>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Rashi:</strong> {details.rashi}
                  </p>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Nakshatra:</strong> {details.nakshatra}
                  </p>
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Gothra:</strong> {details.gothra}
                  </p>
                </div>
                <div className="col-md-6">
                  <h5 style={{ color: '#2C1154', fontSize: '1.5rem', borderBottom: '1px solid #2C1154', paddingBottom: '5px' }}>Career, Education Details</h5>
                  {details.occupation && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Occupation:</strong> {details.occupation.name}
                  </p>}
                  {details.workingin && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Working in:</strong> {details.workingin.name}
                  </p>}
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Income:</strong> {details.income}
                  </p>
                  {details.qualification && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Qualification:</strong> {details.qualification.name}
                  </p>}
                  <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>College:</strong> {details.college}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <h5 style={{ color: '#2C1154', fontSize: '1.5rem', borderBottom: '1px solid #2C1154', paddingBottom: '5px' }}>Contact Details</h5>
                  {details.user.email && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Email:</strong> {details.user.email}
                  </p>}
                  {details.user && <p className="mb-1" style={{ color: '#2C1154', marginTop: "10px", borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>
                    <strong>Phone:</strong> {details.user.phone}
                  </p>}
                </div>
              </div>
              <div className="row justify-content-end mt-5">
                <div className="col-md-6" style={{ marginTop: "-50px" }}>
                  <a href="/edit" className="btn btn- me-3" style={{ background: 'orange', fontSize: '14px' }}>Edit</a>
                  <a href="/download" className="btn btn-" style={{ background: 'orange', fontSize: '14px' }}>Download</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}
