// import React from 'react';
// import { Linking } from 'react-router-dom';
// import PhoneIcon from '@mui/icons-material/Phone';
// import EmailIcon from '@mui/icons-material/Email';

// export default function UpdateContact({ nav, onPress, details }) {
//     const phoneNumber = '1234567890';
//     const emailAddress = 'example@email.com';

//     const handlePhonePress = () => {
//         window.location.href = `tel:${phoneNumber}`;
//     };

//     const handleEmailPress = () => {
//         const emailAddress = details.user.email;
//         window.location.href = `mailto:${emailAddress}`;
//     };


//     return (
//         <div className="col-xxl-6" style={{ padding: '40px', backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white' }}>
//             <div style={{ opacity: 1 }}>
//                 {/* <div
//                     style={{
//                         backgroundColor: '#ffffff',
//                         borderRadius: '10px',
//                         boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
//                         margin: '16px',
//                         padding: '16px',
//                     }}
//                     onClick={onPress}
//                 > */}
//                 <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                     <span style={{
//                         fontSize: '24px',
//                         fontWeight: 'bold',
//                         marginBottom: '16px'
//                     }}>Contact Details</span>
//                     <div>
//                         <button style={{ display: 'flex', alignItems: 'center' }}>

//                         </button>
//                     </div>
//                 </div>

//                 {details && details.user ? (
//                     <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }} onClick={handlePhonePress}>
//                         <div style={{ position: 'relative' }}>
//                             <PhoneIcon style={{ fontSize: 30, color: 'white' }} />
//                         </div>
//                         <div style={{ flex: 1, marginLeft: '12px' }}>
//                             <span style={{ color: '#777' }}>Phone</span>
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.user.phone}</span>
//                             </div>
//                         </div>
//                     </div>
//                 ) : (
//                     <div style={{ display: 'flex', alignItems: 'center' }}></div>
//                 )}

//                 {details && details.user.email ? (
//                     <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }} onClick={handleEmailPress}>
//                         <div style={{ position: 'relative' }}>
//                             <EmailIcon style={{ fontSize: 30, color: 'white' }} />
//                         </div>
//                         <div style={{ flex: 1, marginLeft: '12px' }}>
//                             <span style={{ color: '#777' }}>Email</span>
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.user.email}</span>
//                             </div>
//                         </div>
//                     </div>
//                 ) : (
//                     <div style={{ display: 'flex', alignItems: 'center' }}></div>
//                 )}
//             </div>
//         </div>
//         // </div>
//     );
// }
import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export default function UpdateContact({ details }) {
    const handlePhonePress = () => {
        if (details.user.phone) {
            window.location.href = `tel:${details.user.phone}`;
        }
    };

    const handleEmailPress = () => {
        if (details.user.email) {
            window.location.href = `mailto:${details.user.email}`;
        }
    };

    return (
        <div
            className="card5"
            style={{

                backgroundColor: 'rgba(44, 17, 84, 0.4)',
                backdropFilter: 'blur(10px)',
                color: 'white',
            }}
        >
            <div style={{ opacity: 1 }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            marginBottom: '16px',
                        }}
                    >
                        Contact Details
                    </span>
                </div>

                {details.user.phone ? (
                    <div
                        style={{ display: 'flex', alignItems: 'center', padding: '16px' }}
                        onClick={handlePhonePress}
                    >
                        <PhoneIcon style={{ fontSize: 30, color: 'white' }} />
                        <div style={{ flex: 1, marginLeft: '12px' }}>
                            <span style={{ color: '#777' }}>Phone</span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.user.phone}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}></div>
                )}

                {details.user.email ? (
                    <div
                        style={{ display: 'flex', alignItems: 'center', padding: '16px' }}
                        onClick={handleEmailPress}
                    >
                        <EmailIcon style={{ fontSize: 30, color: 'white' }} />
                        <div style={{ flex: 1, marginLeft: '12px' }}>
                            <span style={{ color: '#777' }}>Email</span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.user.email}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}></div>
                )}
            </div>
        </div>
    );
}
