import axios from 'axios';
const BASE_URL = 'https://samasti.up.railway.app/api'; // Remove the port number and '/3000'
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export default axiosInstance;
// import axios from 'axios';

// const BASE_URL = 'http://192.168.26.204:4000/api'; // Set the base URL for API requests

// const axiosInstance = axios.create({
//   baseURL: BASE_URL,
//   // You can add additional configurations here if needed
//   // For example, timeout, headers, etc.
//   timeout: 10000, // 10 seconds timeout
//   headers: {
//     'Content-Type': 'application/json',
//     // 'Authorization': 'Bearer ' + token, // Add auth token if needed
//   },
// });

// export default axiosInstance;
