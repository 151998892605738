import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, ACTIVE_PLAN, ADD_USER } from './Types';

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  loading: true,
  error: null,
  userId: '',
  activeplan: false,
  plan: "",
  userdetails: {
    Name: null,
    profileCreatedFor: null,
    dob: null

  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
        loading: false,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        loading: false,
        error: null,
        userId: '',
        activeplan: false,
        plan: ""
      };

    case ADD_USER: {
      const user = action.payload
      return {
        ...state,
        user: user
      };
    }

    case ACTIVE_PLAN: {
      const { activeplan, endDate } = action.payload
      return {
        ...state,
        activeplan: activeplan,
        plan: endDate
      };
    }
    default:
      return state;
  }
};

export default authReducer;
