// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import profileApi from './../../app/Services/Api/profileApi';
// import Footer2 from './Footer2';
// import Header2 from './Header2';
// import './Search.css'
// const Search = () => {
//   const navigate = useNavigate();
//   const [searchQuery, setSearchQuery] = useState('');
//   const [searchResults, setSearchResults] = useState([]);
//   const [isSearched, setIsSearched] = useState(false);
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//   const token = useSelector((state) => state.auth?.token);
//   const user = useSelector((state) => state.auth?.user);
//   const userId = user; // Ensure we correctly extract userId

//   // Function to calculate age based on date of birth
//   const calculateAge = (dateOfBirth) => {
//     const birthDate = new Date(dateOfBirth);
//     const currentDate = new Date();
//     let age = currentDate.getFullYear() - birthDate.getFullYear();
//     if (currentDate.getMonth() < birthDate.getMonth() ||
//       (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
//       age--;
//     }
//     return age;
//   };

//   // Function to handle search
//   const handleSearch = async () => {
//     try {
//       setIsSearched(true);
//       setLoading(true);
//       setError('');
//       setSearchResults([]);
//       const response = await profileApi.searchUsername(token, searchQuery);
//       if (response && response.users) {
//         setSearchResults(response.users);
//       } else {
//         setSearchResults([]);
//       }
//     } catch (error) {
//       setIsSearched(true);
//       setLoading(false);
//       setError('Failed to fetch search results');
//       console.error('Error searching:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Function to navigate to profile details page
//   const navigateToProfile = (profileId) => {
//     navigate(`/viewprofile/${profileId}`);
//   };

//   // Function to render profile card
//   const renderProfileCard = (profile) => {
//     if (profile.userId === userId) return null;
//     return (
//       <div
//         key={profile.userId}
//         className="profile"
//         onClick={() => navigateToProfile(profile.userId)}
//       >
//         <img
//           src={profile.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
//           alt={`${profile.fullName}'s profile`}
//           className="profile-image"
//         />
//         <div className="profile-info">
//           <h4 className="profile-name">{profile.fullName}</h4>
//           <p className="profile-details">{calculateAge(profile.dateOfBirth)} years old | {profile.city}</p>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header2 />
//       <div className="our_pricing12"  >
//         <h2 className="section-title-">
//           Search Profiles
//         </h2>
//         <div className="main-content">

//           <div className="search__container">

//             <input
//               className="search__input"
//               type="text"
//               placeholder="Enter user's Full Name"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//             <button onClick={handleSearch} className="search-button">
//               Search
//             </button>

//             <div className="results-container">
//               {loading ? (
//                 <p>Loading...</p>
//               ) : error ? (
//                 <p className="error-message">{error}</p>
//               ) : searchResults.length === 0 ? (
//                 isSearched ? (
//                   <p className="no-results">We are sorry. We cannot find any users who match your search criteria.</p>
//                 ) : null
//               ) : (
//                 <div className="profile-list">
//                   {searchResults.map((profile) => renderProfileCard(profile))}
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer2 />
//     </>
//   );
// };

// export default Search;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import profileApi from './../../app/Services/Api/profileApi';
import Footer2 from './Footer2';
import Header2 from './Header2';
import './Search.css';

const Search = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth?.token);
  const user = useSelector((state) => state.auth?.user);
  const userId = user?._id; // Ensure we correctly extract userId

  // Function to calculate age based on date of birth
  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    if (currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  // Function to handle search
  const handleSearch = async () => {
    try {
      setIsSearched(true);
      setLoading(true);
      setError('');
      setSearchResults([]);
      const response = await profileApi.searchUsername(token, searchQuery);
      console.log(response); // Log the entire response to inspect

      if (response && response.users) {
        setSearchResults(response.users);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      setIsSearched(true);
      setLoading(false);
      setError('Failed to fetch search results');
      console.error('Error searching:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to navigate to profile details page
  const navigateToProfile = (userId) => {
    if (!userId) {
      console.error('User ID is undefined or null');
      return;
    }
    console.log('Navigating to user ID:', userId);
    navigate(`/viewprofile/${userId}`);
  };

  // Function to render profile card
  const renderProfileCard = (profile) => {
    if (!profile || !profile._id) return null; // Ensure profile and profile._id exist
    if (profile.user === userId) return null; // Skip the current user

    return (
      <div
        key={profile._id}
        className="profile"
        onClick={() => navigateToProfile(profile.user)} // Use profile.user as userId
      >
        <img
          src={profile.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
          alt={`${profile.fullName}'s profile`}
          className="profile-image"
        />
        <div className="profile-info">
          <h4 className="profile-name">{profile.fullName}</h4>
          <p className="profile-details">{calculateAge(profile.dateOfBirth)} years old | {profile.city}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header2 />
      <div className="our_pricing12">
        <h2 className="bile-view-title">Search Profiles</h2>
        <div className="main-content">
          <div className="search__container" style={{
            backgroundColor: 'rgba(44, 17, 84, 0.3)',
            backdropFilter: 'blur(10px)',

          }}>
            <input
              className="search__input"
              type="text"
              placeholder="Enter user's Full Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button onClick={handleSearch} className="search-button">
              Search
            </button>
            <div className="results-container">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p className="error-message">{error}</p>
              ) : searchResults.length === 0 ? (
                isSearched ? (
                  <p className="no-results">We are sorry. We cannot find any users who match your search criteria.</p>
                ) : null
              ) : (
                <div className="profile-list">
                  {searchResults.map((profile) => renderProfileCard(profile))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
};

export default Search;
