import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import profileApi from '../../app/Services/Api/profileApi';
import EditAbout from '../../app/Services/Api/UpdateMyProfile/EditAbout';
import EditBasic from '../../app/Services/Api/UpdateMyProfile/EditBasic';
import EditCareer from '../../app/Services/Api/UpdateMyProfile/EditCareer';
import EditFamily from '../../app/Services/Api/UpdateMyProfile/EditFamily';
import EditHoroscope from '../../app/Services/Api/UpdateMyProfile/EditHoroscope';
import PrivacyInside from './PrivacyInside';
import { useNavigate } from 'react-router-dom';
import Header2 from './Header2';
import Footer2 from './Footer2';

export default function MyEdit({ name, count, onPress }) {
  const dispatch = useDispatch();
  const [details, setDetails] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth?.token);
  const longText = "Your long text goes here..."; // Replace with actual long text
  const maxLength = 100; // Define your desired max length
  useEffect(() => {
    getProfileData();
  }, []);

  const toggleText = () => {
    if (showAll) {
      setDisplayText(longText.substring(0, maxLength));
    } else {
      setDisplayText(longText);
    }
    setShowAll(!showAll);
  };

  const getProfileData = async () => {
    try {
      setLoading(true);
      const response = await profileApi.getprofile(token);
      setDetails(response.profile);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        {/* Loading spinner or message */}
      </div>
    );
  }

  return (
    <>
      <Header2 />
      <section style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '130vh',
        backgroundImage: 'linear-gradient(to bottom, rgba(44, 17, 84, 0.3), rgba(44, 17, 84, 0.45)), url("/images/couple.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className='MyPref' style={{
          padding: '40px',
          background: 'white',
          borderRadius: '1px',
          color: 'white',
          height: "auto",
          maxWidth: '75vh',
          boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.0)',
          backgroundColor: 'rgba(44, 17, 84, 0.0)',
        }}>
          <h1 className="bile-view-title" style={{ color: 'white', fontSize: "2.5em", }}>Edit Your Profile</h1>
          {details && (
            <>
              <div className="col-md-6 mb-4">
                <EditBasic details={details} />
              </div>
              <div className="col-md-6 mb-4">
                <EditAbout details={details} />
              </div>
              <div className="col-md-6 mb-4">
                <EditCareer details={details} />
              </div>
              <div className="col-md-6 mb-4">
                <EditFamily details={details} />
              </div>
              <div className="col-md-6 mb-4">
                <EditHoroscope details={details} />
              </div>
              <div className="col-md-6 mb-4">
                <PrivacyInside details={details} />
              </div>
              {/* <div style={{ padding: '100px' }}>
              <button className="preferencesButton" onClick={() => navigate("/mypreference")}>
                <span className="btn btn">Partner Preferences</span>
              </button>
            </div> */}
            </>
          )}
        </div>
      </section>
      <Footer2 />
    </>
  );
}
