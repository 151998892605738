// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import Footer2 from './Footer2';
// import Header2 from './Header2';
// import chatApi from '../../app/Services/Api/chatApi';

// export default function Inbox() {
//   const navigate = useNavigate();
//   const [conversations, setConversations] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [refreshing, setRefreshing] = useState(false);
//   const token = useSelector((state) => state.auth?.token);
//   const userId = useSelector((state) => state.auth?.user?.userId);

//   useEffect(() => {
//     getConversations();
//   }, []);

//   const getConversations = async () => {
//     try {
//       const response = await chatApi.getAllconversations(token);
//       setConversations(response.conversations);
//     } catch (error) {
//       console.error('Error fetching conversations:', error);
//     } finally {
//       setLoading(false);
//       setRefreshing(false);
//     }
//   };

//   const onRefresh = () => {
//     setRefreshing(true);
//     getConversations();
//   };

//   const handleNavigate = (item) => {
//     const otherParticipant = item.participants.find(participant => participant._id !== userId);
//     navigate(`/chat/${item._id}`, { state: { profile_pic: otherParticipant?.user_profile?.profile_pic } });
//   };

//   return (
//     <>
//       <Header2 />
//       <div className="container4" style={{ borderRadius: '1px', color: '#FFFFFF', minHeight: "130vh" }}>
//         <div className="messaging">
//           <div className="inbox_msg">
//             <div className="inbox_people">
//               <div className="headind_srch">
//                 <div className="recent_heading">
//                   <h4>Recent</h4>
//                 </div>
//                 <div className="srch_bar">
//                   <div className="stylish-input-group">
//                     <input type="text" className="search-bar" placeholder="Search" />
//                     <span className="input-group-addon">
//                       <button type="button">
//                         <i className="bi bi-search" aria-hidden="true" />
//                       </button>
//                     </span>
//                   </div>
//                 </div>
//               </div>
//               <div className="inbox_chat">
//                 {loading ? (
//                   <div>Loading...</div>
//                 ) : (
//                   conversations.length > 0 ? (
//                     conversations.map((item) => {
//                       const otherParticipant = item.participants.find(participant => participant._id !== userId);
//                       return (
//                         <div key={item._id} className="chat_list" onClick={() => handleNavigate(item)}>
//                           <div className="chat_people">
//                             <div className="chat_img">
//                               <img src={otherParticipant?.user_profile?.profile_pic || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt="profile" />
//                             </div>
//                             <div className="chat_ib">
//                               <h5>{otherParticipant?.user_profile?.fullName} <span className="chat_date">{item.time}</span></h5>
//                               <p>{item.lastMessage?.content}</p>
//                             </div>
//                           </div>
//                         </div>
//                       );
//                     })
//                   ) : (
//                     <div>No Conversations</div>
//                   )
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer2 />
//     </>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import Header2 from './Header2';
// import Footer2 from './Footer2';
// import chatApi from '../../app/Services/Api/chatApi';

// export default function Inbox() {
//   const navigate = useNavigate();
//   const [conversations, setConversations] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [refreshing, setRefreshing] = useState(false);
//   const token = useSelector((state) => state.auth?.token);
//   const userId = useSelector((state) => state.auth?.user?.userId);

//   useEffect(() => {
//     getConversations();
//   }, []);

//   const getConversations = async () => {
//     try {
//       const response = await chatApi.getAllconversations(token);
//       setConversations(response.conversations);
//     } catch (error) {
//       console.error('Error fetching conversations:', error);
//     } finally {
//       setLoading(false);
//       setRefreshing(false);
//     }
//   };

//   const handleNavigate = (item) => {
//     const otherParticipant = item.participants.find(participant => participant._id !== userId);
//     navigate(`/chat/${item._id}`, { state: { profile_pic: otherParticipant?.user_profile?.profile_pic } });
//   };

//   return (
//     <>
//       <Header2 />
//       <div className="mypref" style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         minHeight: '150vh',
//         backgroundImage: 'linear-gradient(to bottom, rgba(44, 17, 84, 0.7), rgba(44, 17, 84, 0.45)), url("/images/white.jpg")',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',

//       }}>
//         <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
//           <div className="inbox-container" style={{ maxWidth: '600px', width: '100%', padding: '20px', borderRadius: '10px', backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', }}>
//             <div className="headind_srch" style={{ marginBottom: '20px' }}>
//               {/* <div className="recent_heading"> */}
//               <h4 style={{ textAlign: 'center', fontSize: '2.5em', color: 'white' }}>Recent Conversations</h4>

//             </div>
//             <div className="inbox_chat">
//               {loading ? (
//                 <div>Loading...</div>
//               ) : conversations.length > 0 ? (
//                 conversations.map((item) => {
//                   const otherParticipant = item.participants.find(participant => participant._id !== userId);
//                   return (
//                     <div key={item._id} className="chat_list" onClick={() => handleNavigate(item)} style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #eee' }}>
//                       <div className="chat_people" style={{ display: 'flex', alignItems: 'center' }}>
//                         <div className="chat_img" >
//                           <img
//                             src={otherParticipant?.user_profile?.profile_pic || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}
//                             alt="profile"

//                           />
//                         </div>

//                         <div className="chat_ib" style={{ marginLeft: '10px' }}>
//                           <h4 style={{ color: 'white' }}>{otherParticipant?.user_profile?.fullName}</h4>
//                           <p>{item.lastMessage?.content}</p>
//                         </div>
//                       </div>
//                     </div>
//                   );
//                 })
//               ) : (
//                 <div>No Conversations</div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer2 />
//     </>
//   );
// }

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import chatApi from '../../app/Services/Api/chatApi';
import Header2 from './Header2';
import Footer2 from './Footer2';

export default function Inbox() {
  const userId = useSelector((state) => state.auth?.user?.userId?._id);
  const token = useSelector((state) => state.auth?.token);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    try {
      setLoading(true);
      const response = await chatApi.getAllconversations(token);
      setConversations(response.conversations);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = (item) => {
    const otherParticipant = item.participants.find(participant => participant._id !== userId);
    const fullName = otherParticipant?.user_profile?.fullName || 'Unknown';
    const profilePic = otherParticipant?.user_profile?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';

    navigate(`/chat/${item._id}`, {
      state: {
        conversationID: item._id,
        profile_pic: profilePic,
      }
    });
  };

  return (
    <>
      <Header2 />
      <div className="mypref" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '85vh',
        backgroundImage: 'linear-gradient(to bottom, rgba(44, 17, 84, 0.7), rgba(44, 17, 84, 0.45)), url("/images/white.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="container"
        >
          <div className="inbox-container " style={{
            maxWidth: '600px',
            width: '100%',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: 'rgba(44, 17, 84, 0.4)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            position: 'absolute',
            maxHeight: '85vh',
            overflowX: 'auto',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
            <div className="headind_srch" style={{ marginBottom: '20px' }}>
              <h4 className='bile-view-title' style={{ textAlign: 'center', fontSize: '2.0em', color: 'white' }}>Recent Conversations</h4>
            </div>
            <div className="inbox_chat">
              {loading ? (
                <div>Loading...</div>
              ) : conversations.length > 0 ? (
                conversations.map((item) => {
                  const otherParticipant = item.participants.find(participant => participant._id !== userId);
                  const fullName = otherParticipant?.user_profile?.fullName || 'Unknown';
                  const profilePic = otherParticipant?.user_profile?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';

                  return (
                    <div key={item._id} className="chat_list" onClick={() => handleNavigate(item)} style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #eee' }}>
                      <div className="chat_people" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="rounded-circle" style={{ width: '55px', height: '55px', overflow: 'hidden', borderRadius: '50%', flexShrink: 0 }}>
                          <img src={profilePic} alt="profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                        <div className="chat_ib" style={{ marginLeft: '10px', flex: 1 }}>
                          <h4 style={{ color: 'white' }}>{fullName}</h4>
                          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.lastMessage?.content}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>No Conversations</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}