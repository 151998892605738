// import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import paymentAPI from '../../app/Services/Api/paymentAPI';
// import './Razorpay.css';

// export default function Razorpay({ amount, subscriptionId }) {
//     const token = useSelector((state) => state.auth?.token);
//     const [loading, setLoading] = useState(false);

//     const handlePayment = (order_id) => {
//         const options = {
//             key: 'rzp_test_VppoL1vLJQhcAl', // Replace with your actual Razorpay API key
//             amount: amount * 100, // Amount is in currency subunits. Default currency is INR.
//             currency: 'INR',
//             name: 'Samasti Matrimony',
//             description: 'Credits towards consultation',
//             image: 'https://www.freepik.com/free-photos-vectors/s-logo-png',
//             order_id: order_id,
//             handler: function (response) {
//                 alert('Payment successful');
//             },
//             prefill: {
//                 name: 'Razorpay Software',
//                 email: 'void@razorpay.com',
//                 contact: '9191919191',
//             },
//             theme: {
//                 color: '#F37254',
//             },
//         };

//         const rzp1 = new window.Razorpay(options);
//         rzp1.open();
//     };

//     const createOrder = async () => {
//         try {
//             setLoading(true);
//             const results = await paymentAPI.payment(token, subscriptionId);
//             console.log(results);
//             if (results.order_id) {
//                 handlePayment(results.order_id);
//             }
//             setLoading(false);
//         } catch (error) {
//             console.log(error);
//             setLoading(false);
//         }
//     };

//     return (
//         <div>
//             {loading ? (
//                 <button className="btn btn-primary" disabled>
//                     <span className="spinner"></span> Loading...
//                 </button>
//             ) : (
//                 <button className="btn-price-bg" onClick={createOrder}>
//                     Take Membership
//                 </button>
//             )}
//         </div>
//     );
// }

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Razorpay.css';
import paymentAPI from '../../app/Services/Api/paymentAPI';
import { Active_Plan } from '../../app/redux/authActions';
import subscriptionApi from '../../app/Services/Api/subscriptionApi';

const Razorpay = ({ amount, subscriptionId }) => {
    const token = useSelector((state) => state.auth?.token);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
    useEffect(() => {
        getallSubscriptions();
    }, []);

    const getallSubscriptions = async () => {
        try {
            setLoading(true);
            const response = await subscriptionApi.getSubscriptions(token);
            console.log(response); // Verify response structure

            if (response?.exsit.length > 0) {
                const activeSubscription = response.exsit.find((subscription) => {
                    const endDate = new Date(subscription.endDate);
                    const today = new Date();
                    return endDate > today;
                });

                const endDate = activeSubscription ? new Date(activeSubscription.endDate) : null;
                const remainingDays = endDate ? Math.ceil((endDate - new Date()) / (1000 * 60 * 60 * 24)) : null;

                const data = {
                    activeplan: !!activeSubscription,
                    endDate: remainingDays,
                };

                console.log(data); // Log data to verify

                dispatch(Active_Plan(data)); // Update Redux state with active plan data
                setHasActiveSubscription(!!activeSubscription); // Set state based on active subscription
            } else {
                dispatch(Active_Plan(false)); // No subscriptions found, set active plan to false
                setHasActiveSubscription(false); // No active subscription found
            }

            // Assuming response.subscriptions contains all subscriptions

        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        } finally {
            setLoading(false);
        }
    };
    const handlePayment = (order_id) => {
        const options = {
            key: 'rzp_test_VppoL1vLJQhcAl', // Replace with your actual Razorpay API key
            amount: amount * 100, // Amount is in currency subunits. Default currency is INR.
            currency: 'INR',
            name: 'Samasti Matrimony',
            description: 'Credits towards consultation',
            image: 'https://www.freepik.com/free-photos-vectors/s-logo-png',
            order_id: order_id,
            handler: function (response) {
                alert('Payment successful');
            },
            prefill: {
                name: 'Razorpay Software',
                email: 'void@razorpay.com',
                contact: '9191919191',
            },
            theme: {
                color: '#2C1154',
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    const createOrder = async () => {
        try {
            setLoading(true);
            const results = await paymentAPI.payment(token, subscriptionId);
            console.log(results, "hiii paymment done")
            if (results.order_id) {
                handlePayment(results.order_id);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error creating order:', error);
        }
    };

    return (
        <div>
            {!loading && !hasActiveSubscription && (
                <button className="btn-price-bg" onClick={createOrder}>
                    Take Membership
                </button>
            )}
        </div>
    );
};


export default Razorpay;
