// import React, { useState, useEffect } from 'react';
// import axiosInstance from '../../app/Services/Api/axios';
// import { useSelector } from 'react-redux';

// const ImageUpload = () => {
//     const [image, setImage] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [uploadPercentage, setUploadPercentage] = useState(0);
//     const [mediaPermissionGranted, setMediaPermissionGranted] = useState(true); // Simulate media permission granted for web

//     const token = useSelector((state) => state.auth?.token);

//     const image_upload = async (token, imageFile) => {
//         try {
//             setLoading(true);

//             const formData = new FormData();
//             formData.append('image', imageFile); // Append the image file to FormData

//             const config = {
//                 onUploadProgress: (progressEvent) => {
//                     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//                     setUploadPercentage(percentCompleted);
//                     console.log(`Upload Progress: ${percentCompleted}%`);
//                 },
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                     Authorization: `Bearer ${token}`,
//                 },
//             };

//             console.log('FormData before sending:', formData);

//             const response = await axiosInstance.put(`/upload`, formData, config);
//             console.log('Upload Response:', response.data);

//             setLoading(false);
//             setUploadPercentage(0);

//             alert('Uploaded successfully');
//         } catch (error) {
//             setLoading(false);
//             setUploadPercentage(0);
//             console.error('Error uploading image:', error);
//             alert('Something Went Wrong');
//         }
//     };

//     useEffect(() => {
//         // Simulate checking media library permission for web
//         // In web, we don't need to request permissions like in React Native
//         setMediaPermissionGranted(true);
//     }, []);

//     const pickImage = () => {
//         // Simulate image selection for web
//         const fileInput = document.createElement('input');
//         fileInput.type = 'file';
//         fileInput.accept = 'image/*';
//         fileInput.click();

//         fileInput.onchange = (e) => {
//             const file = e.target.files[0];
//             console.log('Selected Image File:', file);
//             setImage(file);
//             image_upload(token, file); // Call image_upload function with the selected file
//         };
//     };

//     return (
//         <div style={styles.container}>
//             {!loading && (
//                 <>
//                     <h1 style={styles.title}>Image Upload</h1>
//                     <button style={styles.button} onClick={pickImage}>
//                         Select Image
//                     </button>
//                     <p style={{ color: 'red' }}>Note: Please Provide Media library permission before selecting an Image</p>
//                 </>
//             )}

//             {image && (
//                 <img
//                     src={URL.createObjectURL(image)}
//                     alt="Selected"
//                     style={styles.previewImage}
//                 />
//             )}

//             {loading && (
//                 <div style={styles.loadingContainer}>
//                     <div className="spinner-border text-primary" role="status">
//                         <span className="sr-only">Uploading...</span>
//                     </div>
//                     <p>Uploading... {uploadPercentage}%</p>
//                 </div>
//             )}
//         </div>
//     );
// };

// const styles = {
//     container: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         flexDirection: 'column',
//         minHeight: '100vh',
//     },
//     title: {
//         fontSize: 24,
//         fontWeight: 'bold',
//         marginBottom: 20,
//     },
//     button: {
//         backgroundColor: '#3498db',
//         padding: '10px 20px',
//         borderRadius: 5,
//         margin: '20px 0',
//         color: '#fff',
//         cursor: 'pointer',
//         border: 'none',
//     },
//     previewImage: {
//         width: 200,
//         height: 200,
//         borderRadius: 10,
//         marginTop: 20,
//     },
//     loadingContainer: {
//         marginTop: 20,
//         display: 'flex',
//         alignItems: 'center',
//     },
// };

// export default ImageUpload;

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../app/Services/Api/axios';
import { useSelector } from 'react-redux';

const ImageUpload = () => {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [mediaPermissionGranted, setMediaPermissionGranted] = useState(true); // Simulate media permission granted for web
    const [imageUrl, setImageUrl] = useState(null); // State to store the image URL

    const token = useSelector((state) => state.auth?.token);

    const image_upload = async (token, imageFile) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('image', imageFile); // Append the image file to FormData

            const config = {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadPercentage(percentCompleted);
                    console.log(`Upload Progress: ${percentCompleted}%`);
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axiosInstance.put(`/upload`, formData, config);
            console.log('Upload Response:', response.data);

            // Assuming your backend returns an image URL
            setImageUrl(response.data.imageUrl); // Store the image URL in state

            setLoading(false);
            setUploadPercentage(0);

            alert('Uploaded successfully');
        } catch (error) {
            setLoading(false);
            setUploadPercentage(0);
            console.error('Error uploading image:', error);
            alert('Something Went Wrong');
        }
    };

    useEffect(() => {
        // Simulate checking media library permission for web
        // In web, we don't need to request permissions like in React Native
        setMediaPermissionGranted(true);
    }, []);

    const pickImage = () => {
        // Simulate image selection for web
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.click();

        fileInput.onchange = (e) => {
            const file = e.target.files[0];
            console.log('Selected Image File:', file);
            setImage(file);
            image_upload(token, file); // Call image_upload function with the selected file
        };
    };

    return (
        <div style={styles.container}>
            {!loading && (
                <>
                    <h1 style={styles.title}>Image Upload</h1>
                    <button style={styles.button} onClick={pickImage}>
                        Select Image
                    </button>
                    <p style={{ color: 'red' }}>Note: Please Provide Media library permission before selecting an Image</p>
                </>
            )}

            {imageUrl && ( // Display image using imageUrl if available
                <img
                    src={imageUrl}
                    alt="Uploaded"
                    style={styles.previewImage}
                />
            )}

            {loading && (
                <div style={styles.loadingContainer}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Uploading...</span>
                    </div>
                    <p>Uploading... {uploadPercentage}%</p>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    button: {
        backgroundColor: '#3498db',
        padding: '10px 20px',
        borderRadius: 5,
        margin: '20px 0',
        color: '#fff',
        cursor: 'pointer',
        border: 'none',
    },
    previewImage: {
        width: 200,
        height: 200,
        borderRadius: 10,
        marginTop: 20,
    },
    loadingContainer: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
    },
};

export default ImageUpload;
