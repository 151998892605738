// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import DatePicker from 'react-datetime';
// import { useNavigate } from 'react-router-dom';

// export default function HomeSection() {
//   const [name, setName] = useState('');
//   const [gender, setGender] = useState('');
//   const [maritalStatus, setMaritalStatus] = useState('');
//   const [lookingFor, setLookingFor] = useState('');
//   const [dateOfBirth, setDateOfBirth] = useState(new Date());
//   const [isGenderModalOpen, setIsGenderModalOpen] = useState(false);
//   const [isMaritalStatusModalOpen, setIsMaritalStatusModalOpen] = useState(false);
//   const [showDatePicker, setShowDatePicker] = useState(false);
//   const navigate = useNavigate();

//   const maritalStatusOptions = ['Single', 'Married', 'Divorced', 'Widowed', 'Divorcing'];


//   const handleDateChange = (event, selectedDate) => {
//     setShowDatePicker(false);
//     if (selectedDate) {
//       setDateOfBirth(selectedDate.toISOString()); // Convert Date to ISO string
//     }
//   };

//   const showDatepicker = () => {
//     setShowDatePicker(true);
//   };

//   const validateAndSubmit = (e) => {
//     e.preventDefault();

//     // Validation
//     if (name.trim() === '') {
//       alert('Please enter your name');
//       return;
//     }

//     if (gender.trim() === '') {
//       alert('Please select your gender');
//       return;
//     }

//     if (!dateOfBirth) {
//       alert('Please select your date of birth');
//       return;
//     }

//     // Check age requirement based on gender
//     const today = new Date();
//     const age = today.getFullYear() - dateOfBirth.getFullYear();

//     if ((gender === 'Male' && age <= 21) || (gender === 'Female' && age < 18)) {
//       alert('Age requirement not met.');
//       return;
//     }

//     if (!maritalStatus) {
//       alert('Marital Status is Required ');
//       return;
//     }

//     // Navigate to ContactReg with the form data
//     navigate('/contactreg', {
//       state: {
//         name,
//         gender,
//         maritalStatus,
//         lookingFor,
//         dateOfBirth: dateOfBirth.toISOString(), // Convert Date to ISO string
//       }
//     });
//   };


//   return (
//     <div>
//       <section
//         className="page-header-section ptb-50"
//         style={{
//           backgroundImage: `linear-gradient(to bottom, rgba(44, 17, 84, 0.8), rgba(44, 17, 84, 0.8)), url("/images/cour1.jpg")`,
//           backgroundPosition: 'center center',
//           backgroundSize: 'cover',
//           backgroundRepeat: 'no-repeat'
//         }}
//       >
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-md-8">
//               <div className="page-header-content text-center">
//                 <h1
//                   className="mb-3"
//                   style={{
//                     color: 'white',
//                     fontSize: '3rem',
//                     fontWeight: 'bold',
//                     marginTop: '60px'
//                   }}
//                 >
//                   Welcome to Samasti Matrimony
//                 </h1>
//                 <p className="lead text-white" style={{ fontSize: '17px' }}>
//                   Join the Samasti Matrimony today and begin your journey together.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="contact-us-section">
//         <div className="full-screen-container">
//           <img
//             className="full-screen-image"
//             src="/images/home.jpg"
//             alt="Samasti Matrimony"
//           />

//           {/* reg */}
//           <div className="registration-form-overlay">
//             <h3 style={{ color: 'white' }}>Register</h3>
//             <form onSubmit={validateAndSubmit}>
//               <div className="form-group mb-2">
//                 <input
//                   type="text"
//                   className="form-control small-input"
//                   placeholder="Name"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                 />
//               </div>

//               <div className="form-group mb-2">
//                 <input
//                   type="text"
//                   className="form-control small-input"
//                   placeholder="Gender"
//                   value={gender}
//                   onClick={() => setIsGenderModalOpen(true)}
//                   readOnly
//                 />
//               </div>

//               <div className="form-group mb-2">
//                 <input
//                   type="text"
//                   className="form-control small-input"
//                   placeholder="Looking for"
//                   value={lookingFor}
//                   readOnly
//                 />
//               </div>
//               <div className="form-group mb-2">
//                 <input
//                   onClick={() => showDatepicker(true)}
//                 />
//                 <p>{dateOfBirth.substring(0, 10) || 'Pick Date of Birth'} </p>
//               </div>

//               <div className="form-group mb-2">
//                 <input
//                   type="text"
//                   className="form-control small-input"
//                   placeholder="Marital Status"
//                   value={maritalStatus}
//                   onClick={() => setIsMaritalStatusModalOpen(true)}
//                   readOnly
//                 />
//               </div>
//               {showDatePicker && (
//                 <div className="form-group mb-2">
//                   <DatePicker
//                     value={new Date(dateOfBirth)} // Pass a Date object
//                     onChange={handleDateChange}
//                     placeholder="Date and Time of Birth"
//                     mode='date'
//                     display='default'
//                   />
//                 </div>
//               )}

//               <button type="submit" className="btn-2">
//                 Create Your Profile
//               </button>
//             </form>

//             <Modal
//               isOpen={isGenderModalOpen}
//               onRequestClose={() => setIsGenderModalOpen(false)}
//               className="modal"
//               overlayClassName="overlay"
//             >
//               <button onClick={() => { setGender('Male'); setLookingFor('Female'); setIsGenderModalOpen(false); }}>
//                 Male
//               </button>
//               <button onClick={() => { setGender('Female'); setLookingFor('Male'); setIsGenderModalOpen(false); }}>
//                 Female
//               </button>
//             </Modal>

//             <Modal
//               isOpen={isMaritalStatusModalOpen}
//               onRequestClose={() => setIsMaritalStatusModalOpen(false)}
//               className="modal"
//               overlayClassName="overlay"
//             >
//               {maritalStatusOptions.map((status) => (
//                 <button key={status} onClick={() => { setMaritalStatus(status); setIsMaritalStatusModalOpen(false); }}>
//                   {status}
//                 </button>
//               ))}
//             </Modal>
//           </div>

//         </div>
//       </section>

//       {/* <Modal
//         isOpen={isGenderModalOpen}
//         onRequestClose={() => setIsGenderModalOpen(false)}
//         className="modal"
//         overlayClassName="overlay"
//       >

//         <button onClick={() => { setGender('Male'); setLookingFor('Female'); setIsGenderModalOpen(false); }}>
//           Male
//         </button>
//         <button onClick={() => { setGender('Female'); setLookingFor('Male'); setIsGenderModalOpen(false); }}>
//           Female
//         </button>
//       </Modal> */}

//       {/* <Modal
//         isOpen={isMaritalStatusModalOpen}
//         onRequestClose={() => setIsMaritalStatusModalOpen(false)}
//         className="modal"
//         overlayClassName="overlay"
//       >

//         {maritalStatusOptions.map((status) => (
//           <button key={status} onClick={() => { setMaritalStatus(status); setIsMaritalStatusModalOpen(false); }}>{status}</button>
//         ))}
//       </Modal> */}
//     </div>
//   );
// }

import React, { useState } from 'react';
import Modal from 'react-modal';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Calendar from 'react-calendar';
import { format, parseISO } from 'date-fns';

export default function HomeSection() {
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [lookingFor, setLookingFor] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null); // Initialize as string
  const [isGenderModalOpen, setIsGenderModalOpen] = useState(false);
  const [isMaritalStatusModalOpen, setIsMaritalStatusModalOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const navigate = useNavigate();

  const maritalStatusOptions = ['Single', 'Married', 'Divorced', 'Widowed', 'Divorcing'];

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      setDateOfBirth(selectedDate);
      setShowCalendar(false);
    }
  };

  const showDatepicker = () => {
    setShowCalendar(true);
  };

  const validateAndSubmit = (e) => {
    e.preventDefault();

    // Validation
    if (name.trim() === '') {
      alert('Please enter your name');
      return;
    }

    if (gender.trim() === '') {
      alert('Please select your gender');
      return;
    }

    if (!dateOfBirth) {
      alert('Please select your date of birth');
      return;
    }

    // Check age requirement based on gender
    const today = new Date();
    const age = today.getFullYear() - new Date(dateOfBirth).getFullYear();

    if ((gender === 'Male' && age <= 21) || (gender === 'Female' && age < 18)) {
      alert('Age requirement not met.');
      return;
    }

    if (!maritalStatus) {
      alert('Marital Status is Required');
      return;
    }

    // Navigate to ContactReg with the form data
    navigate('/contactreg', {
      state: {
        name, gender, maritalStatus, lookingFor, dateOfBirth, // Already in ISO string format
      },
    });
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      height: '38px',
      borderWidth: 1,
      borderColor: '#ccc', // Adjust border color as needed
      borderRadius: '5px',
      boxShadow: 'none',
      margin: '8px 0'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '0.805rem',
      color: '#9a9a9a' // Adjust placeholder font size as needed
    }),
  };

  return (
    <div>
      <section
        className="page-header-section1 ptb-50"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(44, 17, 84, 0.8), rgba(44, 17, 84, 0.8)), url("/images/cour1.jpg")`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="page-header-content1 text-center">
                <h1
                  className="mb-3 responsive-heading"

                >
                  Welcome to Samasti Matrimony
                </h1>
                <p className="lead text-white responsive-text" >
                  Join the Samasti Matrimony today and begin your journey together.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="contact-us-section">
        <div className="full-screen-container">
          <img
            className="full-screen-image"
            src="/images/home.jpg"
            alt="Samasti Matrimony"
          />

          {/* Registration Form */}
          <div className="registration-form-overlay">
            <h3 style={{ color: 'white' }}>Register</h3>
            <form onSubmit={validateAndSubmit}>
              {/* Name */}
              <div className="inputContainer mb-2">
                <input
                  type="text"
                  className="form-control small-input"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              {/* Gender */}
              <div className="inputContainer mb-2">
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Gender"
                  value={gender ? { label: gender, value: gender } : null}
                  options={[
                    { label: 'Male', value: 'Male' },
                    { label: 'Female', value: 'Female' },
                  ]}
                  onChange={(selectedOption) => {
                    setGender(selectedOption.value);
                    setLookingFor(selectedOption.value === 'Male' ? 'Female' : 'Male');
                  }}
                  styles={customStyles}
                />
              </div>

              {/* Looking For */}
              <div className="inputContainer mb-2">
                <input
                  type="text"
                  className="form-control small-input"
                  placeholder="Looking for"
                  value={lookingFor}
                  readOnly
                />
              </div>

              {/* Marital Status */}
              <div className="inputContainer mb-2">
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Marital Status"
                  value={maritalStatus ? { label: maritalStatus, value: maritalStatus } : null}
                  options={maritalStatusOptions.map((status) => ({ label: status, value: status }))}
                  onChange={(selectedOption) => setMaritalStatus(selectedOption.value)}
                  styles={customStyles}
                />
              </div>

              {/* Date of Birth */}
              <div className="inputContainer mb-2">
                <input
                  type="text"
                  className="form-control small-input"
                  placeholder="Date of Birth"
                  value={dateOfBirth ? format(dateOfBirth, 'yyyy-MM-dd') : ''}
                  onClick={showDatepicker}
                  readOnly
                />
              </div>

              {showCalendar && (
                <div className="inputContainer mb-2">
                  <Calendar
                    onChange={handleDateChange}
                    value={dateOfBirth}
                    maxDate={new Date()}
                  />
                </div>
              )}

              {/* Submit Button */}
              <button type="submit" className="btn-2" style={{ color: 'white', borderRadius: '12px' }}>
                Search Partner
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
