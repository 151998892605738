// import React, { useState, useEffect } from 'react';
// import './Package.css';  // Import the CSS file
// import subscriptionApi from './../../app/Services/Api/subscriptionApi';
// import { useSelector } from 'react-redux';
// import Footer2 from './Footer2';
// import Header2 from './Header2';

// const Packages = () => {
//   const [subscriptions, setSubscriptions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [refreshing, setRefreshing] = useState(false);
//   const [exists, setExists] = useState([]);
//   const token = useSelector((state) => state.auth?.token);

//   useEffect(() => {
//     getallSubscriptions();
//   }, [refreshing]);

//   const getallSubscriptions = async () => {
//     try {
//       setLoading(true);
//       const response = await subscriptionApi.getSubscriptions(token);
//       setExists(response.existingSubscriptions || []);
//       setSubscriptions(response.subscriptions || []);
//     } catch (error) {
//       console.error('Error fetching subscriptions:', error);
//     } finally {
//       setLoading(false);
//       setRefreshing(false);
//     }
//   };

//   const handleRefresh = () => {
//     setRefreshing(true);
//     getallSubscriptions();
//   };

//   const renderPackageItem = (item) => {
//     const existingSubscription = exists.find(existing =>
//       existing.subscription && existing.subscription._id === item._id
//     );

//     return (
//       <div className="col-md-4" key={item._id}>
//         <div className="pricingTable">
//           <div className={`price-item text-center ${item.plan} pricingTabletop`}></div>
//           <h4 className="title">{item.name}</h4>
//           <div className="price-month">Monthly Plan</div>
//           <div className="price-value">
//             ₹{item.price}
//             <span className="value-bg"> ₹{item.price % 1 === 0 ? item.price : item.price.toFixed(2)}</span>
//           </div>
//           <ul className="pricing-content pricing-content-border">
//             <li className="description">
//               <svg width="24" height="24" fill="none" className="text-green-500 mr-1" viewBox="0 0 24 24">
//                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
//               </svg>
//               {item.description}
//             </li>
//             {item.benefits && item.benefits.map((benefit, index) => (
//               <li className="benefit" key={index}>
//                 <svg width="24" height="24" fill="none" className="text-green-500 mr-1" viewBox="0 0 24 24">
//                   <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
//                 </svg>
//                 {benefit}
//               </li>
//             ))}
//           </ul>
//           <button className="btn-price-bg">
//             Take Membership
//             <svg className="ml-auto" width="24" height="24" fill="none" viewBox="0 0 24 24">
//               <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M13.75 6.75L19.25 12L13.75 17.25"></path>
//               <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 12H4.75"></path>
//             </svg>
//           </button>
//           {/* <div className="info">Literally you probably have not heard of them jean shorts.</div> */}
//         </div>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Header2 />
//       <div className="our_pricing">
//         <div className="section-title">
//           <h1 className="section-title-white">Membership plans</h1>
//           <p className="section-title-white">
//             Discover the ideal plan tailored to your journey towards a lifetime of happiness with Samasti Matrimony. Whether you seek personalized matchmaking or exclusive features, we have a plan designed just for you. Join today and embark on your journey to find love that lasts a lifetime.
//           </p>
//         </div>
//         <div className="container">
//           {loading && !refreshing && <div className="loading-indicator">Loading...</div>}
//           <div className="row">
//             {!loading && subscriptions.length > 0 && subscriptions.map(item => (
//               renderPackageItem(item)
//             ))}
//             {!loading && subscriptions.length === 0 && !refreshing && (
//               <p className="no-packages-text">No packages available</p>
//             )}
//           </div>
//         </div>
//       </div>
//       <Footer2 />
//     </>
//   );
// };

// export default Packages;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Razorpay from './Razorpay'; // Ensure the Razorpay component is in the same folder or adjust the path
import './Package.css';  // Import the CSS file
import subscriptionApi from '../../app/Services/Api/subscriptionApi';
import Footer2 from './Footer2';
import Header2 from './Header2';

const Packages = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [exists, setExists] = useState([]);
  const token = useSelector((state) => state.auth?.token);

  useEffect(() => {
    getAllSubscriptions();
  }, [refreshing]);

  const getAllSubscriptions = async () => {
    try {
      setLoading(true);
      const response = await subscriptionApi.getSubscriptions(token);
      console.log('Subscription API Response:', response);
      setExists(response.exsit);
      setSubscriptions(response.subscriptions);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleRefresh = () => {
    setRefreshing(true);
    getAllSubscriptions();
  };

  const renderPackageItem = (item) => {
    const existingSubscription = exists.find(existing =>
      existing.subscription && existing.subscription._id === item._id
    );
    console.log('Item:', item);
    console.log('Existing Subscription:', existingSubscription);

    return (
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={item._id}>
        <div className="pricingTable">
          <div className={`price-item text-center ${item.plan} pricingTabletop`}></div>
          <h4 className="title">{item.name}</h4>
          <div className="price-value">
            ₹{item.price}
            <span className="value-bg"> ₹{item.price % 1 === 0 ? item.price : item.price.toFixed(2)}</span>
          </div>
          <ul className="pricing-content pricing-content-border">
            <li className="description">
              <svg width="24" height="24" fill="none" className="text-green-500 mr-1" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
              </svg>
              {item.description}
            </li>
            {item.benefits && item.benefits.map((benefit, index) => (
              <li className="benefit" key={index}>
                <svg width="24" height="24" fill="none" className="text-green-500 mr-1" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75"></path>
                </svg>
                {benefit}
              </li>
            ))}
          </ul>
          {existingSubscription && (
            <p className="benefit" style={{ color: 'orange' }}>
              You are subscribed to this package until {new Date(existingSubscription.endDate).toLocaleDateString()}
            </p>
          )}

          <Razorpay amount={item.price} subscriptionId={item._id} />
        </div>
      </div>
    );
  };

  return (
    <>
      <Header2 />
      <div className="our_pricing">
        <div className="section-title">
          <h1 className="bile-view-title">Membership plans</h1>
          <p className="section-title-white">
            Discover the ideal plan tailored to your journey towards a lifetime of happiness with Samasti Matrimony. Whether you seek personalized matchmaking or exclusive features, we have a plan designed just for you. Join today and embark on your journey to find love that lasts a lifetime.
          </p>
        </div>
        <div className="container">
          {loading && !refreshing && <div className="loading-indicator">Loading...</div>}
          <div className="row">
            {!loading && subscriptions.length > 0 && subscriptions.map(item => (
              renderPackageItem(item)
            ))}
            {!loading && subscriptions.length === 0 && !refreshing && (
              <p className="no-packages-text">No packages available</p>
            )}
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
};

export default Packages;
