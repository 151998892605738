import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import profileApi from '../profileApi';

const EditHoroscope = ({ details }) => {
  const [nakshatra, setNakshatra] = useState('');
  const [placeOfBirth, setPlaceOfBirth] = useState('');
  const [rashi, setRashi] = useState('');
  const [timeOfBirth, setTimeOfBirth] = useState('');
  const [gothra, setGothra] = useState('');

  const [isEditMode, setIsEditMode] = useState(false);
  const token = useSelector((state) => state.auth?.token);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const savedetails = async () => {
    try {
      const data = {
        nakshatra,
        placeOfbirth: placeOfBirth,
        rashi,
        timeOfBirth,
        gothra,
      };
      const response = await profileApi.updateHoroscope(token, data);
      if (response) {
        alert('Updated');
        toggleEditMode();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { nakshatra, placeOfbirth, rashi, timeOfBirth, gothra } = details;

    setNakshatra(nakshatra);
    setPlaceOfBirth(placeOfbirth);
    setRashi(rashi);
    setTimeOfBirth(timeOfBirth);
    setGothra(gothra);
  }, [details]);

  return (
    <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white' }}>
      <h2 style={{ color: 'white' }}>Horoscope Details</h2>
      {/* {isEditMode ? (
        <button className='btn btn' style={{ marginBottom: 10, backgroundColor: 'orange', fontSize: '16px' }} onClick={savedetails}>
          Update
        </button>
      ) : (
        <button style={{}} onClick={toggleEditMode}>
          Edit
        </button>
      )} */}

      <button className='btn btn' style={{ marginBottom: 10, backgroundColor: 'orange', fontSize: '16px' }} onClick={isEditMode ? savedetails : toggleEditMode}>
        {isEditMode ? 'Save' : 'Edit'}
      </button>
      {/* Nakshatra */}
      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Nakshatra</label>
        <input
          type="text"
          placeholder="Enter Nakshatra"
          value={nakshatra}
          onChange={(e) => setNakshatra(e.target.value)}
          disabled={!isEditMode}
          style={{ color: 'black' }} />
      </div>

      {/* Place of Birth */}
      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Place of Birth</label>
        <input
          type="text"
          placeholder="Enter Place of Birth"
          value={placeOfBirth}
          onChange={(e) => setPlaceOfBirth(e.target.value)}
          disabled={!isEditMode}
          style={{ color: 'black' }} />
      </div>

      {/* Rashi */}
      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Rashi</label>
        <input
          type="text"
          placeholder="Enter Rashi"
          value={rashi}
          onChange={(e) => setRashi(e.target.value)}
          disabled={!isEditMode}
          style={{ color: 'black' }} />
      </div>

      {/* Time of Birth */}
      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Time of Birth</label>
        <input
          type="text"
          placeholder="Enter Time of Birth"
          value={timeOfBirth}
          onChange={(e) => setTimeOfBirth(e.target.value)}
          disabled={!isEditMode}
          style={{ color: 'black' }} />
      </div>

      {/* Gothra */}
      <div className="form-group" style={{ marginBottom: 20 }}>
        <label style={{ fontWeight: 'bold' }}>Gothra</label>
        <input
          type="text"
          placeholder="Enter Gothra"
          value={gothra}
          onChange={(e) => setGothra(e.target.value)}
          disabled={!isEditMode}
          style={{ color: 'black' }} />
      </div>
    </div>
  );
};

export default EditHoroscope;
