import React, { useState, useEffect } from 'react';
import Header2 from './Header2';
import Footer2 from './Footer2';
import Modal from 'react-modal';
import { Country, State, City } from 'country-state-city';
import { useSelector } from 'react-redux';
import profileApi from '../../app/Services/Api/profileApi';
import generaldata from '../../app/Assets/GeneralData/generaldata';


Modal.setAppElement('#root');

const CustomModalSelector = ({ data, initValue, selectedValue, onChange, disabled, isMulti = false }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedValue || initValue);

  const openModal = () => {
    if (!disabled) {
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    onChange(option);
    closeModal();
  };

  return (
    <div>
      <input
        type="text"
        placeholder={initValue}
        value={selectedOption}
        onClick={openModal}
        readOnly
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Select Option"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
          },
          content: {
            width: '400px',
            margin: 'auto',
            background: '#fff',
            borderRadius: '4px',
            padding: '20px'
          }
        }}
      >
        <h2>Select Option</h2>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {data.map((option) => (
            <li key={option.key} onClick={() => handleOptionClick(option)} style={{ cursor: 'pointer', marginBottom: '10px' }}>
              {option.label}

            </li>
          ))}
        </ul>
        <button className="btn btn-secondary" onClick={closeModal} style={{ marginTop: '10px' }}>Close</button>
      </Modal>
    </div>
  );
};

const MyPref = () => {
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const [minHeight, setMinHeight] = useState('');
  const [maxHeight, setMaxHeight] = useState('');
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectedReligion, setSelectedReligion] = useState(null);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [selectedMotherTongue, setSelectedMotherTongue] = useState(null);
  const [selectedSubCaste, setSelectedSubCaste] = useState('');
  const [selectedGothra, setSelectedGothra] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectgrewUpCountry, setselectgrewUpCountry] = useState(null);
  const [selectedWorkingIn, setSelectedWorkingIn] = useState(null);


  // const [selectprofessionArea, setSelectProfessionArea] = useState([]);
  const [minIncome, setMinIncome] = useState('');
  const [maxIncome, setMaxIncome] = useState('');
  const [selectedProfileCreatedBy, setSelectedProfileCreatedBy] = useState('');
  const [selectedResidencyStatus, setSelectedResidencyStatus] = useState('');
  const [selectedDiet, setSelectedDiet] = useState('');

  const [loading, setLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  const Countries = Country.getAllCountries();

  const countriesArray = Object.entries(Country.getAllCountries()).map(([isoCode, country]) => ({
    key: country.isoCode, // Access the ISO code property of the country object
    label: country.name,
  }));


  const stateArray = Object.entries(State.getStatesOfCountry(selectedCountry?.key)).map(([isoCode, state]) => ({
    key: state.isoCode,
    label: state.name,
  }));

  // Assuming you have already imported the necessary functions or classes, such as City.getCitiesOfState

  const cityArray = selectedCountry && selectedState
    ? Object.entries(City.getCitiesOfState(selectedCountry?.key, selectedState?.key))
      .map(([isoCode, city]) => ({
        key: city.name,
        label: city.name,
      }))
    : [];




  const token = useSelector((state) => state.auth?.token);
  const filterDataByType = (type) => {
    return generaldata.filter((item) => item.type === type);
  };

  useEffect(() => {
    handleSavePreferences();
  }, []);


  const handleSavePreferences = async () => {
    try {
      const response = await profileApi.getpartnerpref(token);

      if (response) {
        const {
          minAge = 18,
          maxAge = 60,
          minHeight = '',
          maxHeight = '',
          religion = null,
          community = null,
          education = null,
          occupation = null,
          mothertongue = null,
          subcaste = '',
          gothra = '',
          country = null,
          state = null,
          city = null,
          grewupcountry = null,
          workingIn,
          incomeRange = { lower: '', upper: '' },
          profilecreatedby = '',
          residencystatus = '',
          diet = ''
        } = response;
        // const { workingIn = [] } = response;

        console.log(
          minAge, maxAge, minHeight, maxHeight, religion, community, education, occupation,
          mothertongue, subcaste, gothra, country, state, city, grewupcountry, workingIn,
          incomeRange, profilecreatedby, residencystatus, diet
        );

        // Ensure workingIn is an array before mapping over it

        setMinAge(minAge.toString());
        setMaxAge(maxAge.toString());
        setMinHeight(minHeight.toString());
        setMaxHeight(maxHeight.toString());
        setSelectedReligion(religion ? { key: religion._id, label: religion.name } : null);
        setSelectedCommunity(community ? { key: community._id, label: community.name } : null);
        setSelectedEducation(education ? { key: education._id, label: education.name } : null);
        setSelectedOccupation(occupation ? { key: occupation._id, label: occupation.name } : null);
        setSelectedMotherTongue(mothertongue ? { key: mothertongue._id, label: mothertongue.name } : null);
        setSelectedSubCaste(subcaste);
        setSelectedGothra(gothra);
        setSelectedCountry(country ? { value: country, label: country } : null);
        setSelectedState(state ? { value: state, label: state } : null);
        setSelectedCity(city ? { value: city, label: city } : null);
        if (grewupcountry) setSelectedCountry({ key: grewupcountry._id, label: grewupcountry.name });
        setSelectedWorkingIn(workingIn ? { value: workingIn._id, label: workingIn.name } : null);
        setMinIncome(incomeRange.lower.toString());
        setMaxIncome(incomeRange.upper.toString());
        setSelectedProfileCreatedBy(profilecreatedby);
        setSelectedResidencyStatus(residencystatus);
        setSelectedDiet(diet);

        setLoading(false);
        console.log('Partner preferences fetched successfully');
      } else {
        console.error('Error fetching partner preferences');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching partner preferences:', error.message);
      setLoading(false);
    }
  };

  const savePreferences = async () => {
    try {
      const data = {
        minAge: parseInt(minAge),
        maxAge: parseInt(maxAge),
        minHeight: parseInt(minHeight),
        maxHeight: parseInt(maxHeight),
        religion: selectedReligion?.key,
        community: selectedCommunity?.key,
        education: selectedEducation?.key,
        occupation: selectedOccupation?.key,
        mothertongue: selectedMotherTongue?.key,
        subcaste: selectedSubCaste,
        gothra: selectedGothra,
        country: selectedCountry?.label, // Assuming selectedCountry is an object with label
        state: selectedState?.label, // Assuming selectedState is an object with label
        city: selectedCity?.label,
        grewupcountry: selectgrewUpCountry?.key,
        workingIn: selectedWorkingIn ? selectedWorkingIn.value : null,
        // professionarea: selectprofessionArea ? selectprofessionArea.map(item => ({ key: item.key, label: item.label })) : [],
        incomeRange: {
          lower: minIncome,
          upper: maxIncome,
        },
        profilecreatedby: selectedProfileCreatedBy,
        residencystatus: selectedResidencyStatus,
        diet: selectedDiet,
      };
      console.log(data, "savesave")


      const emptyFields = Object.keys(data).filter(key => {
        const value = data[key];
        return (
          value === undefined ||
          value === '' ||
          (typeof value === 'object' && Object.values(value).some(v => v === ''))
        );
      });

      if (emptyFields.length > 0) {
        alert('Please fill in all fields');
        return;
      }

      setLoading(true);
      const response = await profileApi.newupdatedPartnerPreference(token, data);

      if (response) {
        alert('Updated');
        setLoading(false);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  // Predefined options

  const resetForm = () => {
    setMinAge('');
    setMaxAge('');
    setMinHeight('');
    setMaxHeight('');
    setSelectedReligion(null);
    setSelectedCommunity(null);
    setSelectedEducation(null);
    setSelectedOccupation(null);
    setSelectedMotherTongue(null);
    setSelectedSubCaste('');
    setSelectedGothra('');
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCity(null);
    setselectgrewUpCountry(null);
    setSelectedWorkingIn(null);
    setMinIncome('');
    setMaxIncome('');
    setSelectedProfileCreatedBy('');
    setSelectedResidencyStatus('');
    setSelectedDiet('');
  };

  const profileCreatedByOptions = [
    { key: 'self', label: 'Self' },
    { key: 'family', label: 'Family' },
    { key: 'parents', label: 'Parents' }
  ];

  const residencyStatusOptions = [
    { key: 'citizen', label: 'Citizen' },
    { key: 'permanent_resident', label: 'Permanent Resident' },
    { key: 'student_visa', label: 'Student Visa' },
    { key: 'work_permit', label: 'Work Permit' }
  ];

  const dietOptions = [
    { key: 'veg', label: 'Veg' },
    { key: 'non_veg', label: 'Non-Veg' },
    { key: 'eggetarian', label: 'Eggetarian' },
    { key: 'occasionally_non_veg', label: 'Occasionally Non-Veg' },
    { key: 'jain', label: 'Jain' },
    { key: 'vegan', label: 'Vegan' }
  ];

  return (
    <>
      <Header2 />

      <div className="mypref" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '150vh',
        backgroundImage: 'linear-gradient(to bottom, rgba(44, 17, 84, 0.7), rgba(44, 17, 84, 0.45)), url("/images/standing.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>

        <div>
          <h1 className="bile-view-title" style={{ color: 'white', fontSize: "3.0em" }}>Partner preference</h1>
          <div className="card5">

            <div className="form-group">

              <label className='responsive-label'>Minimum Age</label>
              <input
                type="number"
                className="form-control"
                value={minAge}
                disabled={!isEditMode}
                onChange={(e) => setMinAge(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className='responsive-label'>Maximum Age</label>
              <input
                type="number"
                className="form-control"
                value={maxAge}
                disabled={!isEditMode}
                onChange={(e) => setMaxAge(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className='responsive-label'>Minimum Height</label>
              <input
                type="number"
                className="form-control"
                value={minHeight}
                disabled={!isEditMode}
                onChange={(e) => setMinHeight(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className='responsive-label'>Maximum Height</label>
              <input
                type="number"
                className="form-control"
                value={maxHeight}
                disabled={!isEditMode}
                onChange={(e) => setMaxHeight(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className='responsive-label'>Income Range</label>
              <input
                className="form-control" type="number" name="minIncome" value={minIncome} onChange={(e) => setMinIncome(e.target.value)} disabled={!isEditMode} />
              <span> to </span>
              <input className="form-control" type="number" name="maxIncome" value={maxIncome} onChange={(e) => setMaxIncome(e.target.value)} disabled={!isEditMode} />
            </div>

            <div className="form-group">
              <label className='responsive-label'>Religion:</label>
              <CustomModalSelector
                data={filterDataByType('Religion').map((item) => ({
                  key: item._id.$oid,
                  label: item.name,
                }))}
                initValue="Select Religion"
                selectedValue={selectedReligion}
                onChange={(option) => setSelectedReligion(option)}
                disabled={!isEditMode}
                className="form-control"

              />
            </div>


            <div className="form-group">
              <label className='responsive-label'>Community</label>
              <CustomModalSelector
                data={filterDataByType('Community').map((item) => ({
                  key: item._id.$oid,
                  label: item.name,
                }))}
                initValue="Select Community"
                selectedValue={selectedCommunity}
                onChange={(option) => setSelectedCommunity(option)}
                disabled={!isEditMode}
                className="form-control"
              />
            </div>


            <div className="form-group">
              <label className='responsive-label'>Mother Tongue</label>
              <CustomModalSelector
                data={filterDataByType('Mothertounge').map((item) => ({
                  key: item._id.$oid,
                  label: item.name,
                }))}
                initValue="Select Mothertongue"
                selectedValue={selectedMotherTongue}
                onChange={(option) => setSelectedMotherTongue(option)}
                disabled={!isEditMode}
                className="form-control"
              />
            </div>


            <div className="form-group">
              <label className='responsive-label'>Occupation</label>
              <CustomModalSelector
                data={filterDataByType('Occupation').map((item) => ({
                  key: item._id.$oid,
                  label: item.name,
                }))}
                initValue="Select Occupation"
                selectedValue={selectedOccupation}
                onChange={(option) => setSelectedOccupation(option)}
                disabled={!isEditMode}
                className="form-control"
              />
            </div>


            <div className="form-group">
              <label className='responsive-label'>Education</label>
              <CustomModalSelector
                data={filterDataByType('Qualification').map((item) => ({
                  key: item._id.$oid,
                  label: item.name,
                }))}
                initValue="Select Qualification"
                selectedValue={selectedEducation}
                onChange={(option) => setSelectedEducation(option)}
                disabled={!isEditMode}
                className="form-control"
              />
            </div>



            {/* <div className="form-group">
              <label>Working In:</label>
              <CustomModalSelector
                data={filterDataByType('Workingin').map((item) => ({
                  key: item._id.$oid,
                  label: item.name,
                }))}
                initValue="Select Working IN"
                selectedValue={selectedWorkingIn}
                onChange={(option) => setSelectedWorkingIn(option)}
                disabled={!isEditMode}
                className="form-control"
              />
            </div> */}


            <div className="form-group">
              <label className='responsive-label'>Country</label>

              <CustomModalSelector
                data={Country.getAllCountries().map(country => ({
                  value: country.isoCode,
                  label: country.name
                }))}
                selectedValue={selectedCountry}
                onChange={(option) => setSelectedCountry(option)}
                disabled={!isEditMode}
                initValue="Select Country"
              />


            </div>

            <div className="form-group">
              <label className='responsive-label'>State</label>

              <CustomModalSelector
                data={selectedCountry ? State.getStatesOfCountry(selectedCountry.value).map(state => ({
                  value: state.isoCode,
                  label: state.name
                })) : []}
                selectedValue={selectedState}
                onChange={(option) => setSelectedState(option)}
                disabled={!isEditMode}
                initValue="Select State"
              />


            </div>

            <div className="form-group">
              <label className='responsive-label'>City</label>

              <CustomModalSelector
                data={selectedCountry && selectedState ? City.getCitiesOfState(selectedCountry.value, selectedState.value).map(city => ({
                  value: city.name,
                  label: city.name
                })) : []}
                selectedValue={selectedCity}
                onChange={(option) => setSelectedCity(option)}
                disabled={!isEditMode}
                initValue="Select City"

              />

            </div>


            <div className="form-group">
              <label className='responsive-label'>Sub Caste</label>
              <input
                type="text"
                className="form-control"
                value={selectedSubCaste}
                disabled={!isEditMode}
                onChange={(e) => setSelectedSubCaste(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className='responsive-label'>Gothra</label>
              <input
                type="text"
                className="form-control"
                value={selectedGothra}
                disabled={!isEditMode}
                onChange={(e) => setSelectedGothra(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className='responsive-label'>Profile Created By</label>
              <select
                value={selectedProfileCreatedBy}
                onChange={(e) => setSelectedProfileCreatedBy(e.target.value)}
                disabled={!isEditMode}
                className="form-control"
              >
                <option value="">Select Profile Created By</option>
                {profileCreatedByOptions.map((option) => (
                  <option key={option.key} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label className='responsive-label'>Residency Status</label>
              <select
                value={selectedResidencyStatus}
                onChange={(e) => setSelectedResidencyStatus(e.target.value)}
                disabled={!isEditMode}
                className="form-control"
              >
                <option value="">Select Residency Status</option>
                {residencyStatusOptions.map((option) => (
                  <option key={option.key} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>


            <div className="form-group">
              <label className='responsive-label'>Diet</label>
              <select
                value={selectedDiet}
                onChange={(e) => setSelectedDiet(e.target.value)}
                disabled={!isEditMode}
                className="form-control"
              >
                <option value="">Select Diet</option>
                {dietOptions.map((option) => (
                  <option key={option.key} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className="form-group">
              <label>Working In</label>
              <CustomModalSelector
                data={filterDataByType('Workingin').map((item) => ({ key: item._id.$oid, label: item.name }))}
                initValue="Select Working In"
                selectedValue={selectWorkingIn}
                onChange={(option) => setSelectWorkingIn(option)}
                disabled={!isEditMode}
                isMulti
              />
            </div> */}



            <div className="form-group">
              <label className='responsive-label'>Grew Up Country</label>
              <CustomModalSelector
                data={filterDataByType('Country').map((item) => ({
                  key: item._id.$oid,
                  label: item.name,
                }))}
                initValue="Select Grew Up Country"
                selectedValue={selectgrewUpCountry}
                onChange={(option) => setselectgrewUpCountry(option)}
                disabled={!isEditMode}
                className="form-group" />
            </div>


            {/* <div className="form-group">
              <label>Profession Area</label>
              <CustomModalSelector
                data={filterDataByType('Workingin').map((item) => ({
                  key: item._id.$oid,
                  label: item.name,
                }))}
                initValue="Select Profession Area"
                selectedValue={selectprofessionArea}
                onChange={(option) => setSelectProfessionArea(option)}
                disabled={!isEditMode}
                isMulti
              />
            </div> */}

            {/*  */}

            <div className="button-group">
              {isEditMode ? (
                <button onClick={savePreferences} className="btn btn-" style={{ backgroundColor: '#2C1154', fontSize: '16px' }}>Save Preferences</button>
              ) : (
                <button onClick={toggleEditMode} className="btn btn-" style={{ backgroundColor: 'orange', fontSize: '16px' }}>Edit Preferences</button>
              )}
              <button onClick={resetForm} className="btn btn-" style={{ backgroundColor: 'orange', fontSize: '16px' }}>Clear</button>
            </div>
          </div>
        </div>



      </div >
      <div className="info-container" style={{ backgroundColor: 'white' }}>
        <h2>The Value of Personalized Partner Selection</h2>
        <p style={{ fontSize: "16px", color: "#2C1154" }}> Partner preference plays a crucial role in finding a compatible life partner and building a fulfilling relationship. Here are some key benefits and advantages:</p>
        <ul>
          <li><strong style={{ fontSize: "18px", color: "#2C1154" }} >Enhanced Compatibility:</strong>Specifying partner preferences helps individuals find matches who share similar values, beliefs, and life goals. This alignment is crucial for fostering compatibility in a relationship. When partners have common ground, they are more likely to understand each other, reducing the potential for conflict and misunderstandings.</li>
          {/* <img src="/images/pexels-mukesh-mohanty-336201-936554 (3).jpg" alt="Couple" className="info-image"style={{width:"200px",height:"140%"}}/> */}

          <li><strong style={{ fontSize: "18px", color: "#2C1154" }}>Effective Communication:</strong> Partners who share similar backgrounds, interests, and values are likely to communicate more effectively. Good communication is the foundation of any strong relationship. It helps in resolving conflicts, making joint decisions, and maintaining a healthy emotional connection.</li>
          {/* <img src="/images/pexels-mukesh-mohanty-336201-936554 (3).jpg" alt="Wedding" className="info-image" style={{width:"200px",height:"140%"}}/> */}
          <li><strong style={{ fontSize: "18px", color: "#2C1154" }}>Personal Growth:</strong> Being with the right partner can significantly contribute to personal growth and development. A supportive and understanding partner can encourage you to pursue your goals and ambitions. They can also provide emotional support during challenging times, helping you grow as an individual.</li>
          {/* <img src="/images/pexels-mukesh-mohanty-336201-936554 (3).jpg" alt="Happy Family" className="info-image"style={{width:"200px",height:"140%"}}/> */}
          <li><strong style={{ fontSize: "18px", color: "#2C1154" }}>Cultural and Social Harmony</strong> In many cultures, marriage is not just the union of two individuals but also of their families and social circles. Having clear preferences regarding community, religion, and cultural background can help ensure that the marriage is accepted and supported by both families, leading to social harmony and a smoother integration.</li>
          {/* <img src="/images/pexels-mukesh-mohanty-336201-936554 (3).jpg" alt="Happy Family" className="info-image"style={{width:"200px",height:"140%"}}/> */}

          <li><strong style={{ fontSize: "18px", color: "#2C1154" }}>Lifestyle Alignment:</strong> Being with the right partner can significantly contribute to personal growth and development. A supportive and understanding partner can encourage you to pursue your goals and ambitions. They can also provide emotional support during challenging times, helping you grow as an individual.</li>
          {/* <img src="/images/pexels-mukesh-mohanty-336201-936554 (3).jpg" alt="Happy Family" className="info-image"style={{width:"200px",height:"140%"}}/> */}
          <li><strong style={{ fontSize: "18px", color: "#2C1154" }}>Long-Term Goals</strong> In many cultures, marriage is not just the union of two individuals but also of their families and social circles. Having clear preferences regarding community, religion, and cultural background can help ensure that the marriage is accepted and supported by both families, leading to social harmony and a smoother integration.</li>
          <li><strong style={{ fontSize: "18px", color: "#2C1154" }}>Shared Values and Beliefs:</strong> Being with the right partner can significantly contribute to personal growth and development. A supportive and understanding partner can encourage you to pursue your goals and ambitions. They can also provide emotional support during challenging times, helping you grow as an individual.</li>
          {/* <img src="/images/pexels-mukesh-mohanty-336201-936554 (3).jpg" alt="Happy Family" className="info-image"style={{width:"500px",height:"20%", textAlign:"center"}}/> */}
        </ul>
      </div>
      <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
        <div className="carousel-item active" style={{ backgroundImage: `url("/images/.jpg")` }}></div>
        <div className="carousel-item active" style={{ backgroundImage: `url("/images/reingro.jpg")` }}></div>
        <div className="carousel-item active" style={{ backgroundImage: 'url("/images/flowers.jpg")' }}></div>
        {/* <div className="carousel-item" style={{ backgroundImage: 'url("../assets/img/hero-carousel/hero-carousel-4.jpg")' }}></div> */}


      </div>
      <Footer2 />
    </>
  );
};

export default MyPref;

