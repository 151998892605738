import React from 'react';
import { Phone, Email, Business, School, Work, AccountBalance } from '@mui/icons-material'; // MUI icons

export default function UpdateCareer({ onPress, details, nav }) {
    return (
        <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white' }}>
            <div style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Career Details</span>
                    {nav === 'update' && (
                        <button onClick={onPress} style={{ color: '#1976d2', fontWeight: 'bold', fontSize: 16, border: 'none', background: 'none', cursor: 'pointer' }}>Edit</button>
                    )}
                </div>

                {details.occupation ? (
                    <DetailRow icon={<Work style={{ color: 'white', fontSize: 32 }} />} label="Occupation" value={details.occupation.name} />
                ) : (
                    <PlaceholderRow />
                )}

                {details.workingin ? (
                    <DetailRow icon={<Business style={{ color: 'white', fontSize: 32 }} />} label="Working In" value={details.workingin.name} />
                ) : (
                    <PlaceholderRow />
                )}

                {details.income ? (
                    <DetailRow icon={<AccountBalance style={{ color: 'white', fontSize: 32 }} />} label="Income" value={details.income} />
                ) : (
                    <PlaceholderRow />
                )}

                {details.qualification ? (
                    <DetailRow icon={<School style={{ color: 'white', fontSize: 32 }} />} label="Qualification" value={details.qualification.name} />
                ) : (
                    <PlaceholderRow />
                )}

                {details.college ? (
                    <DetailRow icon={<AccountBalance style={{ color: 'white', fontSize: 32 }} />} label="University" value={details.college[0]} />
                ) : (
                    <PlaceholderRow />
                )}
            </div>
        </div>
    );
}

const DetailRow = ({ icon, label, value }) => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }}>
        <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
            {icon}
        </div>
        <div style={{ flex: 1, marginLeft: '20px' }}>
            <span style={{ color: '#777' }}>{label}</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{value}</span>
            </div>
        </div>
    </div>
);

const PlaceholderRow = () => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }}>
        <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }} />
        <div style={{ flex: 1, marginLeft: '20px' }}>
            <span style={{ color: '#777' }}>Add details</span>
        </div>
    </div>
);
