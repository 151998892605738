import React, { useState, useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useDispatch } from 'react-redux';
import authApi from '../../app/Services/Api/authApi';
import { login } from '../../app/redux/authActions';
import { useLocation, useNavigate } from 'react-router-dom';


export default function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { phone, country_code, Prefix } = location.state || {};
  const [otp, setOtp] = useState(['', '', '', '']);
  const [resendTimeout, setResendTimeout] = useState(null);
  const [countdown, setCountdown] = useState(60);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const inputRefs = useRef([0, 1, 2, 3].map(() => React.createRef()));


  const handleVerify = async () => {
    const enteredOtp = otp.join('');
    if (!enteredOtp) {
      alert('Invalid OTP');
      return;
    }

    setLoading(true);
    console.log("Logging in with phone:", phone);
    console.log("otp:", enteredOtp);
    dispatch(login(phone, enteredOtp));


  };


  const handleTextChange = (text, index) => {
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = text;
      return newOtp;
    });

    if (text && index < 3) {
      inputRefs.current[index + 1].current.focus();
    } else if (!text && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }
  };

  const startResendTimeout = () => {
    setCountdown(60);
    setResendTimeout(
      setTimeout(() => {
        setResendTimeout(null);
      }, 60000)
    );
  };

  const Resend = async () => {
    try {
      const response = await authApi.generatehotp(country_code, phone);
      if (response) {
        console.log(response.data);
        startResendTimeout();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {
      if (resendTimeout) {
        clearTimeout(resendTimeout);
      }
    };
  }, [resendTimeout]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdown]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '130vh',
          background:
            'linear-gradient(to bottom, rgba(44, 17, 84, 0.5), rgba(44, 17, 84, 0.45)), url("/images/mala.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div
          className="loginForm"
          style={{
            width: '90%',
            maxWidth: '500px',
            backgroundColor: 'rgba(44, 17, 84, 0.3)',
            backdropFilter: 'blur(10px)',
            borderRadius: '10px',
            padding: '40px',
            color: '#FFFFFF',
            position: 'relative',

          }}
        >
          {isOpen && (
            <>
              <a
                href="/"
                onClick={handleClose}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  color: '#FFFFFF',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '25px',
                }}
              >
                ×
              </a>
              <h2 style={{ textAlign: 'center', marginBottom: '30px', color: 'white' }}>Enter OTP</h2>
              <div style={{ textAlign: "center", marginBottom: '20px' }}> <strong style={{ color: 'orange' }}>We have sent otp to  {Prefix} {phone}</strong>  </div>
              <div className="inputContainer" style={{ marginBottom: '20px' }}>
                <label className="inputTitle" style={{ marginBottom: '10px', color: 'white' }}>OTP</label>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleTextChange(e.target.value, index)}
                      ref={inputRefs.current[index]}
                      style={{ width: '50px', height: '50px', textAlign: 'center', fontSize: '24px', marginRight: '10px', backgroundColor: '#FFFFFF', borderRadius: '5px' }}
                    />
                  ))}
                </div>
              </div>
              <div className="checkboxContainer" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                <input type="checkbox" id="keepLogged" style={{ marginRight: '10px' }} />
                <label htmlFor="keepLogged" style={{ color: '#FFFFFF' }}>Keep me logged in</label>
              </div>
              <div className="buttonContainer" style={{ marginBottom: '20px' }}>
                <button onClick={handleVerify} className="loginButton" style={{ width: '100%', backgroundColor: '#FFFFFF', color: '#2C1154', border: 'none', borderRadius: '5px', padding: '10px', cursor: 'pointer', textAlign: 'center' }}>
                  Verify
                </button>
                <button className='buttonContainer' onClick={Resend} disabled={resendTimeout !== null} style={{ backgroundColor: '#FFFFFF', color: '#2C1154', border: 'none', borderRadius: '5px', padding: '10px 20px', cursor: 'pointer', flex: '1' }}>
                  {resendTimeout !== null ? (
                    <span>Resend in {countdown}s</span>
                  ) : (
                    <span style={{ fontWeight: 'bolder' }}>Resend</span>
                  )}
                </button>
              </div>
              <div className="bottomLinks" style={{ textAlign: 'center' }}>
                <a href="/contact" className="linkText" style={{ color: '#FFFFFF', marginRight: '20px', textDecoration: 'none' }}>Trouble logging in?</a>
                <a href="/myaccount" className="linkText" style={{ color: '#FFFFFF', textDecoration: 'none' }}> <span style={{ textDecoration: 'underline', color: 'orange' }}>  Go back?</span></a>
              </div>
            </>
          )}
        </div>
        {/* <div className="informationContainer" style={{ width: '300px', backgroundColor: 'rgba(229, 232, 232, 0.5)', borderRadius: '10px', padding: '20px', color: '#000000', textAlign: 'center' }}>
          <h2>Download Our App</h2>
          <div className="mt-4 mb-4" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <a href="#">
              <img
                src="/images/ios2.png"
                alt="App Store"
                style={{ maxWidth: '100px', maxHeight: '60px' }}
              />
            </a>
            <a href="#">
              <img
                src="/images/play.png"
                alt="Google Play"
                style={{ maxWidth: '100px', maxHeight: '60px' }}
              />
            </a>
          </div>
          <p>Experience our platform on the go!</p>
        </div> */}

      </div>
      <Footer />
    </>
  );
}
