import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './Privy.css'
import profileApi from '../../app/Services/Api/profileApi';

const Privacy = ({ details }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [hideContactDetails, setHideContactDetails] = useState(false);
    const [hideImages, setHideImages] = useState(false);
    const token = useSelector((state) => state.auth?.token);

    useEffect(() => {
        const { contactprivacy, imageprivacy } = details;
        setHideContactDetails(contactprivacy);
        setHideImages(imageprivacy);
    }, [details]);

    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    const saveDetails = async () => {
        // Save the details logic here if needed
        toggleEditMode();
    };

    const ContactPrivacy = async () => {
        try {
            setHideContactDetails(!hideContactDetails);
            const response = await profileApi.updatecontactPrivacy(token, !hideContactDetails);
            if (response) {
                console.log(response);
            }
        } catch (error) {
            setHideContactDetails(!hideContactDetails);
            console.error('Error updating contact privacy:', error);
        }
    };

    const ImagePrivacy = async () => {
        try {
            setHideImages(!hideImages);
            const response = await profileApi.updateImagePrivacy(token, !hideImages);
            if (response) {
                console.log(response);
            }
        } catch (error) {
            setHideImages(!hideImages);
            console.error('Error updating image privacy:', error);
        }
    };
    // col-xxl-6
    return (
        <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white' }}>
            <h1 style={{ color: 'white' }}>Privacy</h1>
            {isEditMode && (
                <button className="edit-button" onClick={saveDetails}>
                    Update
                </button>
            )}

            <div className="preference-item">
                <span className="preference-title" >Hide Contact Details</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={hideContactDetails}
                        onChange={ContactPrivacy}
                    />
                    <span className="slider box" ></span>
                </label>
            </div>

            <div className="preference-item">
                <span className="preference-title">Hide or Visible Images</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={hideImages}
                        onChange={ImagePrivacy}
                    />
                    <span className="slider box"></span>
                </label>
            </div>
        </div>
    );
};

export default Privacy;
