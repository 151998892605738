import React, { useCallback, useEffect, useState } from 'react';
import profileApi from '../../app/Services/Api/profileApi';
import { useSelector } from 'react-redux';
import ProfileCard from './ProfileCrad';
import { useNavigate } from 'react-router-dom';
import connectionApi from '../../app/Services/Api/connectionApi';


export default function Moreblock({ onRefresh }) {
  const [profiles, setProfiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [moreUsers, setMoreUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [connections, setConnections] = useState([]);
  const navigate = useNavigate();



  const token = useSelector((state) => state.auth?.token);

  const user = useSelector((state) => state.auth?.user);
  const { userId } = user
  console.log(user.userId, "more");


  // Function to fetch nearby profiles
  const getNearbyProfiles = async () => {
    try {
      setLoading(true);
      const response = await profileApi.get_nearby(token);
      setProfiles(response?.profiles || []);
    } catch (error) {
      console.error('Error fetching nearby profiles:', error);
    } finally {
      setLoading(false);
    }
  };
  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    return age;
  };
  // Fetch nearby profiles on initial load or refresh
  useEffect(() => {
    getNearbyProfiles();
  }, [token, onRefresh]);

  // Function to fetch match profiles
  const fetchData = async () => {
    setRefreshing(true);
    try {
      const response = await profileApi.get_matchprofiles(token);
      setUsers(response.users);
    } catch (error) {
      console.error('Error fetching match profiles:', error);
    } finally {
      setRefreshing(false);
    }
  };

  // Fetch match profiles on initial load
  useEffect(() => {
    fetchData();
  }, [token]);

  // Function to fetch more profiles
  const fetchMoreProfiles = async (pageNumber) => {
    try {
      setLoadingMore(true);
      const response = await profileApi.getprofiles(token, pageNumber);
      const newUsers = response.profiles || [];

      if (pageNumber === 1) {
        setMoreUsers(newUsers);
      } else {
        setMoreUsers((prevUsers) => [...prevUsers, ...newUsers]);
      }

      setPage(response.currentPage);
      setHasMore(response.currentPage < response.totalPages);
    } catch (error) {
      console.error('Error fetching more profiles:', error);
    } finally {
      setLoadingMore(false);
    }
  };

  // // Handle refresh for more profiles
  // const handleRefreshMoreProfiles = useCallback(() => {
  //   setRefreshing(true);
  //   setMoreUsers([]);
  //   setPage(1);
  //   fetchMoreProfiles(1);
  // }, [token]);
  const handleRefreshMoreProfiles = async () => {
    try {
      setRefreshing(true);
      await fetchMoreProfiles(1); // Fetch the first page of profiles
    } catch (error) {
      console.error('Error refreshing profiles:', error);
    } finally {
      setRefreshing(false);
    }
  };
  // Load more profiles for the "More" tab
  const loadMore = async () => {
    try {
      setLoadingMore(true);
      await fetchMoreProfiles(page + 1); // Fetch the next page of profiles
    } catch (error) {
      console.error('Error loading more profiles:', error);
    } finally {
      setLoadingMore(false);
    }
  };
  useEffect(() => {
    handleRefreshMoreProfiles(); // Initial fetch when component mounts
  }, []);

  // // Render match profile card
  // const renderMatchProfile = ({ item }) => (
  //   <ProfileCard
  //     key={`match-${item._id}`}
  //     profile={item}
  //   />
  // );

  // // Render more profile card
  // const renderMoreProfile = ({ item }) => (
  //   <ProfileCard
  //     key={`more-${item._id}`}
  //     profile={item}
  //   />
  // );


  useEffect(() => {
    getConnections();
  }, []);

  const getConnections = async () => {
    try {
      const response = await connectionApi.getconnectionsRequests(token);
      console.log(response.connectionRequests, "connections");
      setConnections(response.connectionRequests);
    } catch (error) {
      console.error('Error fetching connections:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const handleAccept = async (id) => {
    try {
      console.log(`Accepted connection request with ID ${id}`);
      const accepted = "accepted";

      const response = await connectionApi.connectionstatus_update(id, accepted, token);
      if (response) {
        alert("Request Accepted");
        getConnections(); // Refresh the list after accepting
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async (id) => {
    try {
      console.log(`Rejected connection request with ID ${id}`);
      const rejected = "rejected";

      const response = await connectionApi.connectionstatus_update(id, rejected, token);
      if (response) {
        alert("Request Rejected");
        getConnections(); // Refresh the list after rejecting
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const navigateToProfile = (profileId) => {
    navigate(`/viewprofile/${profileId}`);
  };

  const renderRequestItem = ({ item }) => {
    const isSender = userId?._id === item?.sender?._id;

    return (
      <div
        onClick={() => {
          if (isSender) {
            navigateToProfile(item?.receiver?._id);
            console.log('Sender is the logged-in user');
          } else {
            navigateToProfile(item?.sender?._id);
          }
        }}
        className="rounded-circle d-flex cursor-pointer"
        style={{ display: 'flex' }}
      >
        <img
          src={isSender ? item.receiver?.user_profile?.profile_pic : item.sender?.user_profile?.profile_pic}
          alt="Profile Pic"
          className="rounded-circle"
          style={{
            width: '100px',
            height: '100px',
            objectFit: 'cover',
            borderRadius: '50%'
          }}
        />
        <div className="text-section d-flex flex-column justify-content-center px-3">
          <p className="connection-info" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
            {isSender
              ? `You sent a connection request to ${item?.receiver?.user_profile?.fullName}`
              : `${item?.sender?.user_profile?.fullName} sent you a connection request`}
          </p>
          <p className="status" style={{ textTransform: 'uppercase', color: '#888', marginBottom: '5px', fontWeight: 'bold' }}>
            {item.status.toUpperCase()}
          </p>
          {item.status === 'pending' && !isSender && (
            <div className="d-flex flex-wrap">
              <button
                onClick={() => handleAccept(item._id)}
                className="btn btn-primary me-2 mb-2"
                style={{ fontWeight: 'bold' }}
              >
                Accept
              </button>
              <button
                onClick={() => handleReject(item._id)}
                className="btn btn-dark me-2 mb-2"
                style={{ fontWeight: 'bold' }}
              >
                Reject
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleRefresh = () => {
    setRefreshing(true);
    getConnections();
  };



  return (
    <div className="col-xxl-8" style={{ marginTop: '-2px', }} >
      <div className="row" style={{ borderRadius: 'none' }} >
        <div className="col-xl-12 " >
          <div className="custom-card overflow-hidden"  >
            <div className="card-body p-3">
              <div className="border-block-end-dashed bg-white rounded-2 p-2">
                <ul
                  className="nav nav-pills nav-justified gx-3 tab-style-6 d-sm-flex d-block"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item rounded" role="presentation">
                    <button
                      className="nav-link active"
                      id="activity-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#activity-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="activity-tab-pane"
                      aria-selected="true"
                      style={{ backgroundColor: '#2C1154', color: 'white' }}
                    >
                      <i className="ri-map-pin-line me-1 align-middle d-inline-block fs-18" />
                      Near By
                    </button>
                  </li>
                  <li className="nav-item rounded" role="presentation">
                    <button
                      className="nav-link"
                      id="gallery-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#gallery-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="gallery-tab-pane"
                      aria-selected="false"
                      style={{ backgroundColor: '#2C1154', color: 'white' }}
                    >
                      <i className="ri-user-line me-1 align-middle d-inline-block fs-18" />
                      Matches
                    </button>
                  </li>
                  <li className="nav-item rounded" role="presentation">
                    <button
                      className="nav-link"
                      id="posts-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#posts-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="posts-tab-pane"
                      aria-selected="false"
                      style={{ backgroundColor: '#2C1154', color: 'white' }}
                    >
                      <i className="ri-group-line me-1 align-middle d-inline-block fs-18" />
                      More
                    </button>
                  </li>
                  <li className="nav-item rounded" role="presentation">
                    <button
                      className="nav-link"
                      id="followers-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#followers-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="followers-tab-pane"
                      aria-selected="false"
                      style={{ backgroundColor: '#2C1154', color: 'white' }}
                    >
                      <i className="ri-add-circle-line me-1 align-middle d-inline-block fs-18" />

                      Requests
                    </button>
                  </li>
                </ul>
                {/* maxHeight: "1230px",  */}
                <div className="scrollable-content" style={{ maxHeight: "1350px", overflowY: "scroll" }}>
                  <div className="py-5">
                    <div className="tab-content" id="myTabContent">
                      {/* Near By Tab Content */}
                      <div className="tab-pane show active fade p-0 border-0 bg-white p-4 rounded-3" id="activity-tab-pane" role="tabpanel" aria-labelledby="activity-tab" tabIndex={0}>
                        {loading ? (
                          <p className="loading-text">Loading...</p>
                        ) : (
                          <>
                            {profiles.length > 0 ? (
                              <div className="row" >
                                {profiles.map((profile) => (
                                  <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12" key={profile._id} style={{ marginBottom: '20px' }}>
                                    <div className="card shadow border0" style={{ maxWidth: '300px', margin: 'auto' }}>
                                      <div className="card-body p-2">
                                        <div className="text-center">
                                          <span className="avatar avatar-xxl avatar-box" style={{ width: "75%", height: "140px", marginTop: "-15px", overflow: 'hidden' }}>
                                            <img
                                              src={profile.profile_pic || "/images/default.jpg"}
                                              alt={profile.fullName}
                                              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            />
                                          </span>
                                          <div className="mt-2">
                                            <p className="mb-0 fw-semibold">{profile.fullName}</p>
                                            <p className="fs-12 op-10 mb-1 text-muted">{calculateAge(profile.dateOfBirth)} Years old | {profile.maritalStatus}</p>
                                            {/* <p className="fs-12 op-10 mb-1 text-muted">{profile.state || 'Unknown '}</p> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center">
                                        <div className="btn-list">
                                          <a href={`/viewprofile/${profile.user}`} className="btn btn-sm btn- btn-wave" style={{ backgroundColor: '#2C1154', color: 'white' }}>
                                            View Profile
                                          </a>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="no-profiles-container">
                                <p className="no-profiles-text" style={{ fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center' }}>No nearby profiles </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      {/* Matches Tab Content */}
                      <div className="tab-pane fade p-0 border-0" id="gallery-tab-pane" role="tabpanel" aria-labelledby="gallery-tab" tabIndex={0} style={{ overflowX: "hidden" }}>
                        <div className="row">
                          {users.map((user) => (
                            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12" key={user._id} style={{ marginBottom: '20px' }}>
                              <div className="card shadow border" style={{ maxWidth: '300px', margin: 'auto' }}>
                                <div className="card-body p-2">
                                  <div className="text-center">
                                    <span className="avatar avatar-xxl avatar-box" style={{ width: "75%", height: "140px", marginTop: "-15px", overflow: 'hidden' }}>
                                      <img
                                        src={user.profile_pic || "/images/default.jpg"}
                                        alt={user.fullName}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                      />
                                    </span>
                                    <div className="mt-2">
                                      <p className="mb-0 fw-semibold">{user.fullName}</p>
                                      <p className="fs-12 op-10 mb-1 text-muted">{calculateAge(user.dateOfBirth)} Years old | {user.maritalStatus}</p>
                                      {/* <p className="fs-12 op-10 mb-1 text-muted">{user.height || 'Unknown '}cm</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-center">
                                  <div className="btn-list">
                                    <a href={`/viewprofile/${user.user}`} className="btn btn-sm btn- btn-wave" style={{ backgroundColor: '#2C1154', color: 'white' }}>
                                      View Profile
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="row justify-content-center mt-4">
                          <div className="col-md-6">
                            <div onClick={fetchData} disabled={loading}>
                              {loading ? 'Loading...' : ''}
                            </div>
                          </div>
                        </div>
                        {!loading && users.length === 0 && (
                          <div className="text-center mt-4">
                            <p style={{ fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center' }}>No Matches found.</p>
                          </div>
                        )}
                      </div>




                      {/* More Tab Content */}
                      <div className="tab-pane fade p-0 border-0" id="posts-tab-pane" role="tabpanel" aria-labelledby="posts-tab" tabIndex={0} style={{ overflowX: "hidden" }}>
                        <div className="row" >
                          {moreUsers.map((user) => (
                            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12" key={user._id} style={{ marginBottom: '20px' }}>
                              <div className="card shadow border-0" style={{ maxWidth: '300px', margin: 'auto' }}>
                                <div className="card-body p-2">
                                  <div className="text-center">
                                    <span className="avatar avatar-xxl avatar-box" style={{ width: "75%", height: "140px", marginTop: "-15px", overflow: 'hidden' }}>
                                      <img
                                        src={user.profile_pic || "/images/default.jpg"}
                                        alt={user.fullName}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                      />
                                    </span>
                                    <div className="mt-2">
                                      <p className="mb-0 fw-semibold">{user.fullName}</p>
                                      <p className="fs-12 op-10 mb-1 text-muted">{calculateAge(user.dateOfBirth)} Years old | {user.maritalStatus}</p>
                                      {/* <p className="fs-12 op-10 mb-1 text-muted">{user.height || 'Unknown '}cm</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-center">
                                  <div className="btn-list">
                                    <a href={`/viewprofile/${user.user}`} className="btn btn-sm btn- btn-wave" style={{ backgroundColor: '#2C1154', color: 'white' }}>
                                      View Profile
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {loadingMore && !refreshing && (
                          <div className="text-center mt-4">
                            <p>Loading...</p>
                          </div>
                        )}
                        {!loadingMore && moreUsers.length === 0 && !refreshing && (
                          <div className="text-center mt-4">
                            <p>No users found.</p>
                          </div>
                        )}
                        {!loadingMore && !refreshing && !hasMore && (
                          <div className="text-center mt-4">
                            <p style={{ fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center' }}>No more users to load.</p>
                          </div>
                        )}
                        {!loadingMore && hasMore && (
                          <div className="text-center mt-4">
                            <button className="btn btn-" style={{ backgroundColor: '#2C1154', color: 'white' }} onClick={loadMore}>
                              Load More
                            </button>
                            {/* <button className="btn btn-" onClick={handleRefreshMoreProfiles} disabled={refreshing}>
                              {refreshing ? <i className="fas fa-spinner fa-spin" style={{ color: "#2C1154" }}></i> : <i className="fas fa-sync-alt"></i>}
                            </button> */}
                          </div>
                        )}
                      </div>

                      {/* req */}
                      <div className="scrollable-content" style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                        <div className="tab-pane fade p-0 border-0"
                          id="followers-tab-pane"
                          role="tabpanel"
                          aria-labelledby="followers-tab"
                          tabIndex={0} style={{ overflowX: 'hidden' }}>
                          <div className="container">
                            {connections.map((connection, index) => (
                              <div key={index} className="item" style={{

                                padding: '10px',
                                margin: '10px',
                                borderRadius: '5px',
                                boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                              }}>
                                {renderRequestItem({ item: connection })}
                              </div>
                            ))}
                            {loading && !refreshing && <div>Loading...</div>}
                            {!loading && connections.length === 0 && !refreshing && (
                              <div style={{ fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center' }}>No Connection Requests</div>
                            )}
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}
