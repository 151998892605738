import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

export default function Privacy() {
  const [showAnswer, setShowAnswer] = useState(null);

  const toggleAnswer = (index) => {
    setShowAnswer(showAnswer === index ? null : index);
  };

  const questionsAndAnswers = [
    {
      question: "1: What information does Samasti Matrimony collect from you?",
      answer: "Samasti Matrimony collects information that you voluntarily provide when creating an account, such as your name, email address, contact details, and preferences. We may also collect information about your usage of our website or mobile app."
    },
    {
      question: "2: How does Samasti Matrimony use the information collected?",
      answer: "Samasti Matrimony uses the information collected to provide matchmaking services, personalize your experience, improve our services, communicate with you, and comply with legal obligations."
    },
    {
      question: "3: Does Samasti Matrimony share your information with third parties?",
      answer: "Samasti Matrimony may share your information with third-party service providers who assist us in delivering our services. We may also share information with law enforcement or regulatory authorities when required by law."
    },
    {
      question: "4: How can you access or update your information?",
      answer: "You can access and update your personal information by logging into your Samasti Matrimony account. If you need assistance, you can contact our customer support team."
    },
    {
      question: "5: How does Samasti Matrimony protect your information?",
      answer: "Samasti Matrimony implements security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. We use encryption, firewalls, and other industry-standard security practices."
    },
    {
      question: "6: How long does Samasti Matrimony retain your information?",
      answer: "Samasti Matrimony retains your information for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law."
    },
    {
      question: "7: Does Samasti Matrimony use cookies or similar technologies?",
      answer: "Samasti Matrimony uses cookies and similar technologies to improve your browsing experience, analyze website traffic, and personalize content. You can manage cookie preferences through your browser settings."
    },
    {
      question: "8: Can you opt out of receiving communications from Samasti Matrimony?",
      answer: "Yes, you can opt out of receiving promotional communications from Samasti Matrimony by following the unsubscribe instructions provided in the communication or by contacting us directly."
    },
    {
      question: "9: How does Samasti Matrimony handle changes to the privacy policy?",
      answer: "Samasti Matrimony may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or app."
    }
  ];

  return (
    <div>
      <Header />
      <Container >
        <Row>
          <Col className="text-left">
            <h2 className="text-center mb-5" style={{ color: "#2C1154", fontSize: '2rem', fontWeight: 'bold', marginTop: "20px" }}>
              Privacy Policy
            </h2>
            <p className="lead text-center" style={{ color: "#2C1154", fontSize: '0.9rem', fontWeight: 'normal', marginTop: "-30px", marginBottom: "50px" }}>
              Your privacy is important to us, and so is being transparent about how we collect, use, and share information about you. This policy is intended to help you understand.
            </p>

            {questionsAndAnswers.map((item, index) => (
              <QuestionAndAnswer
                key={index}
                index={index}
                question={item.question}
                answer={item.answer}
                showAnswer={showAnswer}
                toggleAnswer={toggleAnswer}
              />
            ))}
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );

  function QuestionAndAnswer({ index, question, answer, showAnswer, toggleAnswer }) {
    return (
      <div style={{ marginBottom: '50px', textAlign: 'left' }}>
        <h3 onClick={() => toggleAnswer(index)} style={{ cursor: 'pointer', fontSize: '1.1rem' }}>
          {question}
        </h3>
        {showAnswer === index && <p style={{ fontSize: '1rem' }}>{answer}</p>}
      </div>
    );
  }
}
