import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from './Header';
import Footer from './Footer';

export default function Report() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');

  const handleFormSubmit = () => {
    // Handle form submission here
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image', 'video'],
      ['clean']
    ]
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  const typeOfIssues = [
    "Profile Misrepresentation",
    "Inappropriate Content",
    "Harassment or Abuse",
    "Fake Profiles",
    "Scamming or Fraud",
    "Privacy Violation",
    "Spam or Advertisements",
    "Unsolicited Contact",
    "Technical Issues",
    "Duplicate Profiles"
  ];

  return (
    <div>
      <Header />
      <section
        className="contact-us-section pt-5 pb-100"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
          backgroundColor: "lightgray",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container" style={{ padding: "40px" }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6">
              <div
                className="contact-us-form rounded p-4"
                style={{
                  backgroundColor: "rgba(44, 17, 84, 0.5)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "10px",
                  color: "#FFFFFF",
                  position: "relative",
                }}
              >
                <h2 className="text-center mb-4" style={{ color: "#2C1154", fontSize: "1.8rem" }}>
                  Write to us
                </h2>
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-3">
                    <select className="form-control" defaultValue="" required>
                      <option value="" disabled>
                        Select Type of Issue
                      </option>
                      {typeOfIssues.map((issue, index) => (
                        <option key={index} value={issue}>
                          {issue}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <ReactQuill
                      value={description}
                      onChange={setDescription}
                      modules={modules}
                      formats={formats}
                      placeholder="Description"
                      style={{ minHeight: "100px" }}
                    />
                  </div>
                  <div className="text-center mb-3">
                    <button
                      type="submit"
                      className="btn btn-lg mr-3"
                      style={{
                        padding: "10px 30px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: "10px",
                        backgroundColor: "#2C1154",
                        color: "white",
                        marginBottom: "10px",
                      }}
                    >
                      Send Message
                    </button>
                    <button
                      type="button"
                      className="btn btn-lg"
                      style={{
                        padding: "10px 30px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        backgroundColor: "#2C1154",
                        color: "white",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>

  );
}
