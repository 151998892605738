// ProfilePage.js

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import profileApi from '../../app/Services/Api/profileApi'; // Adjust path based on your project structure
import { Active_Plan, addUser } from '../../app/redux/authActions'; // Adjust path based on your project structure
import subscriptionApi from '../../app/Services/Api/subscriptionApi';
import MembershipUi from './MembershipUi';

const ProfilePage = ({ onRefresh }) => {

  const navigate = useNavigate();
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [smsNotifications, setSMSNotifications] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  const token = useSelector((state) => state.auth?.token);
  const user = useSelector((state) => state.auth?.user);
  const { userId } = user
  console.log(user.profile_pic, user.profilePercentage, "pppppppppppppppppppp")
  console.log(user.userId, "lsklsskssksksk");
  const activeplan = useSelector((state) => state.auth?.activeplan);
  console.log(activeplan, "polilpo")
  const plan = useSelector((state) => state.auth?.plan);

  // sub
  const [isRefreshing, setRefreshing] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  console.log(subscriptions, "hjks");
  const [exists, setExists] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  console.log(user?.profile_pic, "kkk")
  const onRefreshing = () => {
    // Your refresh logic goes here
    setRefreshing(true);

    // Simulating an asynchronous refresh action
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  };


  useEffect(() => {
    if (!isRefreshing) {
      // Call API only when not triggered by manual refresh
      getallSubscriptions();
    }
  }, [isRefreshing]);

  const getallSubscriptions = async () => {
    try {
      setLoading(true);
      const response = await subscriptionApi.getSubscriptions(token);
      setExists(response.exsit);

      if (response?.exsit.length > 0) {
        const activeSubscription = response.exsit.find((subscription) => {
          const endDate = new Date(subscription.endDate);
          const today = new Date();
          return endDate > today;
        });

        const endDate = activeSubscription ? new Date(activeSubscription.endDate) : null;
        const remainingDays = endDate ? Math.ceil((endDate - new Date()) / (1000 * 60 * 60 * 24)) : null;

        const data = {
          activeplan: !!activeSubscription,
          endDate: remainingDays,
        };

        console.log(data, "daaaaatatatooooooooooo")
        dispatch(Active_Plan(data));
      } else {
        // Handle the case when there are no subscriptions
        dispatch(Active_Plan(false));
      }

      setSubscriptions(response.subscriptions);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };








  useEffect(() => {
    if (!isComponentMounted) {
      getAllNotifications();
      setIsComponentMounted(true);
    }
  }, [isComponentMounted]);

  const handleEmailNotificationToggle = async () => {
    try {
      setEmailNotifications(!emailNotifications)
      console.log('Before API call - emailNotifications:', emailNotifications);
      const response = await profileApi.emailNotification(token);
      console.log('After API call - emailNotifications:', response.email_Notification);

    } catch (error) {
      setEmailNotifications(!emailNotifications);
      console.log(error);
    }
  };


  const handlePushNotificationToggle = async () => {
    try {
      setPushNotifications(!pushNotifications);
      const response = await profileApi.pushNotification(token);

      // updateEnableNotifications();
    } catch (error) {
      setPushNotifications(!pushNotifications)
      console.log(error);
    }
  };

  const handleSmsNotificationToggle = async () => {
    try {
      setSMSNotifications(!smsNotifications);
      const response = await profileApi.smsNotification(token);

      // updateEnableNotifications();
    } catch (error) {
      setSMSNotifications(!smsNotifications)
      console.log(error);
    }
  };



  useEffect(() => {
    getAllNotifications();
  }, []);

  const getAllNotifications = async () => {
    try {
      const response = await profileApi.AllNotifications(token);
      setEmailNotifications(response.email_Notification);
      setSMSNotifications(response.Sms_Notification);
      setPushNotifications(response.push_Notification);

      console.log(response, "here");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (onRefresh) {
      getProfile();
    }
  }, [onRefresh]);

  const getProfile = async () => {
    try {
      const response = await profileApi.getminprofile(token);

      dispatch(addUser(response)); // Dispatch action to update user profile data
    } catch (error) {
      console.log('Error fetching profile:', error);
    }
  };

  const handleEditClick = () => {
    // Navigate to "/edit" with state
    navigate("/edit",);
  };
  // { state: { userId: user._id } }
  const handleNavigate = () => {
    navigate('/upload'); // Navigate to '/upload' route when clicked
  };
  return (
    <div className="col-xxl-3 " style={{ marginBottom: "40px" }}>
      <div className="card-body py-3 overflow-hidden" style={{ right: '20px', background: "white" }}>
        <div className="card-body">
          <div className="border p-4 rounded-3 mx-auto" style={{ maxWidth: '300px' }}>
            {onRefresh ? (
              <div className="d-flex justify-content-center align-items-center">
                <p className="fw-bold text-center">Loading...</p>
              </div>
            ) : (
              <div className="text-center">
                <svg height="140" width="140" className="mx-auto mb-3">
                  <clipPath id="circleClip">
                    <circle cx="70" cy="70" r="64" />
                  </clipPath>
                  <circle
                    cx="70"
                    cy="70"
                    r="64"
                    stroke="#2C1154"
                    strokeWidth="9"
                    strokeDasharray={`${(2 * Math.PI * 64 * (user?.profilePercentage || 0)) / 100}, 339`}
                    fill="transparent"
                  />
                  <image
                    onClick={handleNavigate}
                    href={user?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'}
                    width="140"
                    height="140"
                    clipPath="url(#circleClip)"
                    preserveAspectRatio="xMidYMid slice"
                    style={{ cursor: 'pointer' }}
                  />
                </svg>

                <p className="fw-bold fs-20" style={{ margin: '10px 0', color: '#2C1154' }}>
                  {user?.fullName ? user?.fullName.charAt(0).toUpperCase() + user?.fullName.slice(1) : ''}
                </p>


                {user?.profilePercentage < 98 ? (
                  <p style={{ color: '#f00' }}>Please Complete Profile 100%.</p>
                ) : (
                  <div />
                )}

                <p className="fs-14 text-muted">
                  {user?.age} years old | {user?.maritalStatus}
                </p>


                {user?.profilePercentage !== undefined && (
                  <>
                    {user?.profilePercentage < 98 ? (
                      <>
                        <p className="fs-14" style={{ margin: '10px 0' }}>Progress: {Math.round(user?.profilePercentage)}%</p>
                        <button
                          style={{
                            padding: '10px 20px',
                            backgroundColor: '#2C1154',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                          }}
                          onClick={handleEditClick}
                        >
                          Continue
                        </button>
                      </>
                    ) : (
                      <button
                        style={{
                          padding: '10px 20px',
                          backgroundColor: '#2C1154',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '5px',
                          cursor: 'pointer'
                        }}
                        onClick={handleEditClick}
                      >
                        Update
                      </button>
                    )}
                  </>
                )}

              </div>
            )}

            {activeplan && (
              <div className="btn btn- mt-3" style={{ marginLeft: '35px', background: 'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)', }}>
                <span className="text-white fs-14">Premium</span>
                {plan ? (
                  <span className="text-white fs-14 ms-2">
                    {parseInt(plan) > 0 ? `${plan} Days left` : `${plan} Day left`}
                  </span>
                ) : null}
              </div>
            )}

            {!activeplan && (
              subscriptions ? (
                <MembershipUi onRefresh={isRefreshing} subscriptions={subscriptions} exists={exists} loading={loading} />
              ) : null
            )}
          </div>
        </div>
      </div>

      <div className="card-body p-0 main-profile-info" style={{ background: "white", }}>
        <div className="d-flex justify-content-between">
          <div className="text-center py-3 border-end" style={{ padding: '40px' }}>
            <p className="fw-bold fs-20 mb-0" >0</p>
            <p className="fs-12 text-muted mb-0">No Pending Invitations</p>
          </div>
          <div className="text-center py-3 border-end">
            <p className="fw-bold fs-20 mb-0">12</p>
            <p className="fs-12 text-muted mb-0">Accepted Invitations</p>
          </div>
          <div className="text-center py-3 border-end" style={{ padding: '40px' }}>
            <p className="fw-bold fs-20 mb-0">128</p>
            <p className="fs-12 text-muted mb-0">Recent Visitors</p>
          </div>
        </div>
      </div>

      <div className="card-body py-3 border-1" style={{ background: "white" }}>
        <h4 className="text-center fw-bold mb-3">Notification Preference</h4>
        <div className="d-flex flex-column gap-3" style={{ padding: '40px' }}>
          <div className="d-flex justify-content-between align-items-center" style={{ color: '#2C1154' }}>
            <label className="fs-14 mb-0">SMS Notification</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={smsNotifications}
                onChange={handleSmsNotificationToggle}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center" style={{ color: '#2C1154' }}>
            <label className="fs-14 mb-0">Push Notification</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={pushNotifications}
                onChange={handlePushNotificationToggle}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center" style={{ color: '#2C1154' }}>
            <label className="fs-14 mb-0">Email Notification</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={emailNotifications}
                onChange={handleEmailNotificationToggle}
              />
            </div>
          </div>
        </div>
      </div>


      <div className="card-body py-3 border-1" style={{ background: "white", }}>
        <h4 className="text-center fw-bold mb-3">Verification Status</h4>
        <div className="d-flex flex-column gap-3" style={{ padding: '40px' }}>
          <div className="d-flex justify-content-between align-items-center">
            <label className="fs-14 mb-0" style={{ color: '#2C1154' }}>Mobile :</label>
            <span className={`fs-14 fw-bold ${userId?.phoneVerification ? 'text' : 'text-orange'}`} style={{ color: '#2C1154' }}>
              {userId?.phoneVerification ? 'Verified' : 'Not Verified'}
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <label className="fs-14 mb-0" style={{ color: '#2C1154' }}>Email :</label>
            <span className={`fs-14 fw-bold ${userId?.emailVerification ? 'text' : 'text-orange'}`} style={{ color: '#2C1154' }}>
              {userId?.emailVerification ? 'Verified' : 'Not Verified'}
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <label className="fs-14 mb-0" style={{ color: '#2C1154' }}>Profile :</label>
            <span className={`fs-14 fw-bold ${userId?.profileVerify ? 'text' : 'text-orange'}`} style={{ color: '#2C1154' }}>
              {userId?.profileVerify ? 'Verified' : 'Not Verified'}
            </span>
          </div>
        </div>
      </div>

      <div className="card-body py-3 border-1" style={{ background: "white", padding: '40px' }}>
        <h4 className="text-center fw-bold mb-3">Customer Support</h4>
        <p className="text-center mb-3" style={{ color: '#2C1154' }}>Our support team is available 24/7 to assist you. Feel free to contact us for any assistance or inquiries.</p>
        <p className="text-center mb-3">Contact us: <a href="mailto:support@samastimatrimony.com" className="text-decoration-none">support@samastimatrimony.com</a></p>
        <div className="d-grid">
          <button className="btn btn-" style={{ background: '#2C1154' }}>Contact Support</button>
        </div>
        <div className="text-center mt-3">
          <p><a href="/terms" className="text-decoration-none">Terms and Conditions</a></p>
          <p><a href="/privacy" className="text-decoration-none">Privacy Policy</a></p>
        </div>
      </div>

    </div>

  );
};

export default ProfilePage;
