import React from 'react'
import Header from './components/Header'
import HomeSection from './components/HomeSection'
import Features from './components/Features'
import Howdoesitworks from './components/Howdoesitworks'
import PopularProfiles from './components/popularprofiles'
import DownloadApp from './components/DownloadApp'
import Membership from './Membership'
import Footer from './components/Footer'

export default function Home() {
  return (
    <>
      <Header />
     
      <HomeSection />
      <Features />
      
      
      <DownloadApp />
      <Membership/>
    
      {/* <Howdoesitworks /> */}
       <PopularProfiles />
      <Footer/>
    </>
   
  )
}