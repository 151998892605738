import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    Edit as EditIcon,
    CalendarToday as CalendarIcon,
    Group as GroupIcon,
    Book as BookIcon,
    LocationOn as LocationIcon,
    Home as HomeIcon,
    Height as HeightIcon,
    Restaurant as RestaurantIcon,
} from '@mui/icons-material';

export default function UpdateBasic({ details, nav, onPress }) {



    const calculateAge = (dateOfBirth) => {
        const birthDate = new Date(dateOfBirth);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - birthDate.getFullYear();
        return age;
    };


    // col-xxl-6
    return (
        <div className="card5" style={{
            backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white',
        }}>

            < div style={{
                flexGrow: 1, justifyContent: 'flex-start'
            }}>
                {/* <div style={{
                        backgroundColor: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
                        margin: '16px',
                        padding: '16px'
                    }}> */}
                < div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <span style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginBottom: '16px'
                    }}>Basic Details</span>

                </div >

                {details && details.maritalStatus ? (
                    <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                        <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                            <GroupIcon />
                        </div>
                        <div style={{ flex: 1, marginLeft: '20px' }}>
                            <span style={{ color: '#777' }}>Marital Status</span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.maritalStatus}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Show add details button when no data is available */}
                    </div>
                )}

                {
                    details && details.religion ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <BookIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Religion</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.religion.name}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }

                {
                    details.community ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <GroupIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Community</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.community.name}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }

                {
                    details && details.mothertongue ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <BookIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Mother Tongue</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.mothertongue.name}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }

                {
                    details.country ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <LocationIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Lives In</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.country}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }



                {
                    details && details.blood_group ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <HomeIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Blood group</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.blood_group.name}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }
                {
                    details.dateOfBirth ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <HomeIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Date of birth</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.dateOfBirth}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }

                {
                    details.city ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <HomeIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>City</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.city}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }
                {
                    details.state ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <HomeIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>State</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.state}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }

                {details.grewupcountry ? (
                    <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                        <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                            <HomeIcon />
                        </div>
                        <div style={{ flex: 1, marginLeft: '20px' }}>
                            <span style={{ color: '#777' }}>Grew Up In</span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.grewupcountry}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Show add details button when no data is available */}
                    </div>
                )
                }

                {
                    details.diet ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <RestaurantIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Diet Preference</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.diet}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }

                {
                    details.height ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <HeightIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Height</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.height}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }

                {
                    details.nativeplace ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <HomeIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Native Place</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.nativeplace}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }

                {
                    details.residencystatus ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }}>
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <HomeIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Residency Status</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{details.residencystatus}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }


                {
                    details.dateOfBirth ? (
                        <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }} >
                            <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
                                <HomeIcon />
                            </div>
                            <div style={{ flex: 1, marginLeft: '20px' }}>
                                <span style={{ color: '#777' }}>Age</span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{calculateAge(details.dateOfBirth)}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* Show add details button when no data is available */}
                        </div>
                    )
                }


            </div >
        </div >

        // </div>
    );
}
