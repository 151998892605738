import React, { useState } from 'react';
import Modal from 'react-modal'; // Import Modal from 'react-modal' for web
import { Country, State, City } from 'country-state-city';


import { useLocation } from 'react-router-dom';
import authApi from '../../app/Services/Api/authApi';
import generaldata from '../../app/Assets/GeneralData/generaldata';
import Header from './Header';
import Footer from './Footer';

export default function Contact() {
  const location = useLocation();
  const { name, gender, maritalStatus, lookingFor, dateOfBirth, } = location.state || {};

  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);



  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  // // Modal state for Country
  const [isCountryModalVisible, setCountryModalVisible] = useState(false);
  const [searchCountry, setSearchCountry] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  // Modal state for State
  const [isStateModalVisible, setStateModalVisible] = useState(false);
  const [searchState, setSearchState] = useState('');
  const [selectedState, setSelectedState] = useState('');

  // Modal state for City
  const [isCityModalVisible, setCityModalVisible] = useState(false);
  const [searchCity, setSearchCity] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  // Modal state for Community
  const [isCommunityModalVisible, setCommunityModalVisible] = useState(false);
  const [searchCommunity, setSearchCommunity] = useState('');
  const [selectedCommunity, setSelectedCommunity] = useState('');

  // Modal state for Religion
  const [isReligionModalVisible, setReligionModalVisible] = useState(false);
  const [searchReligion, setSearchReligion] = useState('');
  const [selectedReligion, setSelectedReligion] = useState('');

  // Modal state for Mother Tongue
  const [isMotherTongueModalVisible, setMotherTongueModalVisible] = useState(
    false
  );
  const [searchMotherTongue, setSearchMotherTongue] = useState('');
  const [selectedMotherTongue, setSelectedMotherTongue] = useState('');

  const handleNext = async () => {
    try {
      // Validate and proceed to the next step
      if (!selectedCountry) {
        alert('Please select a Country');
        return;
      }

      if (!selectedState) {
        alert('Please select a State');
        return;
      }

      // if (!selectedCity) {
      //   alert('Please select a City');
      //   return;
      // }

      if (!selectedCommunity) {
        alert('Please select a Community');
        return;
      }

      if (!selectedMotherTongue) {
        alert('Please select a Mother Tongue');
        return;
      }

      if (!isValidPhoneNumber(phoneNumber)) {
        alert('Invalid Phone Number', 'Please enter a 10-digit phone number.');
        return;
      }

      if (!email) {
        alert('Invalid Email', 'Please enter a valid email address.');
        return;
      }

      setLoading(true);

      const formData = {
        Name: name,

        religion: selectedReligion?._id?.$oid || '',
        mothertongue: selectedMotherTongue?._id?.$oid || '',
        community: selectedCommunity?._id?.$oid || '',
        country: selectedCountry.name || null,
        state: selectedState.name,
        city: selectedCity.name,
        phone: phoneNumber,
        dob: dateOfBirth,
        email: email,
        gender: gender,
        preferedgender: lookingFor,
        maritalStatus: maritalStatus,
        country_code: selectedCountry.phonecode || '',
      };

      console.log('Form data before API call:', formData);

      // Assuming authApi.register returns a promise
      const response = await authApi.register(formData);

      console.log('API response:', response.data);

      if (response) {
        alert('Successfully Registered');
        // Reset form and navigate on success
        setLoading(false);
        setCountry('');
        setState('');
        setCity('');
        setPhoneNumber('');
        setEmail('');
        setSelectedCommunity('');
        setSelectedReligion('');
        setSelectedMotherTongue('');
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert('Something Went Wrong. Try again Later');
    }
  };

  const isValidPhoneNumber = (number) => {
    // Check if the phone number has exactly 10 digits
    return /^\d{10}$/.test(number);
  };

  // Data from generaldata
  const uniqueKeyFilter = (type) => {
    return Array.from(
      new Set(
        generaldata
          .filter((item) => item.type === type)
          .map((item) => item?._id?.$oid)
      )
    ).map((id) => generaldata.find((item) => item?._id?.$oid === id));
  };

  const countryData = uniqueKeyFilter('Country');
  console.log(selectedCountry?.isoCode, "gggggg")
  console.log(State.getStatesOfCountry("IN"))

  // Filtered states based on the selected Country
  const filteredStates = () => {
    if (selectedCountry?.isoCode) {
      const states = State.getStatesOfCountry(selectedCountry?.isoCode);
      return states || [];
    }
    return [];
  };

  // Filtered cities based on the selected State
  const filteredCities = () => {
    if (selectedCountry?.isoCode && selectedState?.isoCode) {
      const cities = City.getCitiesOfState(
        selectedCountry?.isoCode,
        selectedState?.isoCode
      );
      return cities || [];
    }
    return [];
  };

  const Countries = Country.getAllCountries();
  const communityData = uniqueKeyFilter('Community');
  const religionData = uniqueKeyFilter('Religion');
  const motherTongueData = uniqueKeyFilter('Mothertounge');

  // Function to toggle the modal for each dropdown
  const toggleCountryModal = () =>
    setCountryModalVisible(!isCountryModalVisible);
  const toggleStateModal = () => setStateModalVisible(!isStateModalVisible);
  const toggleCityModal = () => setCityModalVisible(!isCityModalVisible);
  const toggleCommunityModal = () =>
    setCommunityModalVisible(!isCommunityModalVisible);
  const toggleReligionModal = () =>
    setReligionModalVisible(!isReligionModalVisible);
  const toggleMotherTongueModal = () =>
    setMotherTongueModalVisible(!isMotherTongueModalVisible);

  // Function to handle selection for each dropdown
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    toggleCountryModal();
  };
  const handleStateSelect = (state) => {
    setSelectedState(state);
    setSelectedCity('');
    toggleStateModal();
  };
  const handleCitySelect = (city) => {
    setSelectedCity(city);
    toggleCityModal();
  };
  const handleCommunitySelect = (community) => {
    setSelectedCommunity(community);
    toggleCommunityModal();
  };
  const handleReligionSelect = (religion) => {
    setSelectedReligion(religion);
    toggleReligionModal();
  };
  const handleMotherTongueSelect = (motherTongue) => {
    setSelectedMotherTongue(motherTongue);
    toggleMotherTongueModal();
  };

  // Function to filter items based on search query for each dropdown
  const filterItems = (data, searchQuery, setSelectedItem, toggleModal) => {
    const filteredItems = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div >
        {filteredItems.map((item) => (
          <div
            key={item.name}

            onClick={() => {
              setSelectedItem(item);
              toggleModal();
            }}
          >
            <span >{item.name}</span>
          </div>
        ))}
      </div>
    );
  };
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      margin: '10px 0',
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #2C1154' : null,
      '&:hover': {
        borderColor: state.isFocused ? '#2C1154' : '#ccc',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2C1154' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#2C1154',
        color: 'white',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#333',
    }),
  };
  return (
    <>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '150vh',
          background:
            'linear-gradient(to bottom, rgba(44, 17, 84, 0.5), rgba(44, 17, 84, 0.45)), url("/images/reingro.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',

        }}
      >
        <div className="col-xxl-6"
          style={{
            width: '90%',
            maxWidth: '450px',
            backgroundColor: 'rgba(44, 17, 84, 0.3)',
            backdropFilter: 'blur(10px)',
            borderRadius: '10px',
            padding: '40px',
            color: '#FFFFFF',
            position: 'relative',
            minHeight: "70vh"
          }}>
          {/* minheh100 */}
          <div style={styles.scrollContainer}>
            <h2 style={{ color: 'white', textAlign: "center" }}>Create Your Profile</h2>
            <div style={styles.inputContainer}>
              <label style={styles.inputTitle}>Country</label>
              <div>
                <button onClick={toggleCountryModal} style={styles.input}>
                  {selectedCountry?.name || 'Select Country'}
                </button>
              </div>
            </div>
            <Modal
              isOpen={isCountryModalVisible}
              onRequestClose={toggleCountryModal}
              styles={customStyles}
            >
              <div style={styles.modalContent}>
                <input
                  style={styles.searchInput}
                  placeholder="Search Country"
                  value={searchCountry}
                  onChange={(e) => setSearchCountry(e.target.value)}
                />
                {filterItems(
                  Countries,
                  searchCountry,
                  handleCountrySelect,
                  toggleCountryModal
                )}
              </div>
            </Modal>


            {/* State Dropdown */}
            <div style={styles.inputContainer}>
              <label style={styles.inputTitle}>State</label>
              <button onClick={toggleStateModal} style={styles.input}>
                {selectedState?.name || 'Select State'}
              </button>
            </div>
            <Modal
              isOpen={isStateModalVisible}
              onRequestClose={toggleStateModal}
              styles={customStyles}
            >
              <div style={styles.modalContent}>
                <input
                  style={styles.searchInput}
                  placeholder="Search State"
                  value={searchState}
                  onChange={(e) => setSearchState(e.target.value)}
                />
                {filterItems(
                  filteredStates(),
                  searchState,
                  handleStateSelect,
                  toggleStateModal
                )}
              </div>
            </Modal>

            {/* City Dropdown */}
            <div style={styles.inputContainer}>
              <label style={styles.inputTitle}>City</label>
              <button onClick={toggleCityModal} style={styles.input}>
                {selectedCity?.name || 'Select City'}
              </button>
            </div>
            <Modal
              isOpen={isCityModalVisible}
              onRequestClose={toggleCityModal}
              styles={customStyles}
            >
              <div style={styles.modalContent}>
                <input
                  style={styles.searchInput}
                  placeholder="Search City"
                  value={searchCity}
                  onChange={(e) => setSearchCity(e.target.value)}
                />
                {filterItems(
                  filteredCities(),
                  searchCity,
                  handleCitySelect,
                  toggleCityModal
                )}
              </div>
            </Modal>

            {/* Community Dropdown */}
            <div style={styles.inputContainer}>
              <label style={styles.inputTitle}>Community</label>
              <div>
                <button onClick={toggleCommunityModal} style={styles.input}>
                  {selectedCommunity.name || 'Select Community'}
                </button>
              </div>
            </div>
            <Modal
              isOpen={isCommunityModalVisible}
              onRequestClose={toggleCommunityModal}
              styles={customStyles}
            >
              <div style={styles.modalContent}>
                <input
                  style={styles.searchInput}
                  placeholder="Search Community"
                  value={searchCommunity}
                  onChange={(e) => setSearchCommunity(e.target.value)}
                />
                {filterItems(
                  communityData,
                  searchCommunity,
                  handleCommunitySelect,
                  toggleCommunityModal
                )}
              </div>
            </Modal>

            {/* Religion Dropdown */}
            <div style={styles.inputContainer}>
              <label style={styles.inputTitle}>Religion</label>
              <div>
                <button onClick={toggleReligionModal} style={styles.input}>
                  {selectedReligion.name || 'Select Religion'}
                </button>
              </div>
            </div>
            <Modal
              isOpen={isReligionModalVisible}
              onRequestClose={toggleReligionModal}
              styles={customStyles}
            >
              <div style={styles.modalContent}>
                <input
                  style={styles.searchInput}
                  placeholder="Search Religion"
                  value={searchReligion}
                  onChange={(e) => setSearchReligion(e.target.value)}
                />
                {filterItems(
                  religionData,
                  searchReligion,
                  handleReligionSelect,
                  toggleReligionModal
                )}
              </div>
            </Modal>

            {/* MotherTongue Dropdown */}
            <div style={styles.inputContainer}>
              <label style={styles.inputTitle}>MotherTongue</label>
              <div>
                <button onClick={toggleMotherTongueModal} style={styles.input}>
                  {selectedMotherTongue.name || 'Select MotherTongue'}
                </button>
              </div>
            </div>
            <Modal
              isOpen={isMotherTongueModalVisible}
              onRequestClose={toggleMotherTongueModal}
              styles={customStyles}
            >
              <div style={styles.modalContent}>
                <input
                  style={styles.searchInput}
                  placeholder="Search MotherTongue"
                  value={searchMotherTongue}
                  onChange={(e) => setSearchMotherTongue(e.target.value)}
                />
                {filterItems(
                  motherTongueData,
                  searchMotherTongue,
                  handleMotherTongueSelect,
                  toggleMotherTongueModal
                )}
              </div>
            </Modal>

            {/* Phone Number Input */}
            <div style={styles.inputContainer}>
              <label style={styles.inputTitle}>Phone Number</label>
              <input
                style={styles.input}
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            {/* Email Input */}
            <div style={styles.inputContainer}>
              <label style={styles.inputTitle}>Email</label>
              <input
                style={styles.input}
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={{ textAlign: 'center' }}>
              <a href="/myaccount" style={{ color: '#FFFFFF' }}>Already Registered ? <span style={{ textDecorationLine: 'underline', color: "orange" }}>Login</span> </a>
            </div>
            {/* Register Button */}
            <button
              className='responsive-button'
              onClick={handleNext}
              disabled={loading}
            >
              {loading ? (
                <div>Loading...</div>
              ) : (
                <span style={styles.buttonText}>Register</span>

              )}
            </button>

          </div>
        </div>
        {/* <div className="informationContainer" style={{ width: '300px', backgroundColor: 'rgba(229, 232, 232, 0.3)', borderRadius: '10px', padding: '20px', color: '#000000', textAlign: 'center', margin: '40px' }}>
          <h2 style={{ color: "white" }}>Download Our App</h2>
          <p>Discover love and companionship on the go with the Samasti Matrimony app!</p>
          <div className="mt-4 mb-4" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <a href="#">
              <img
                src="/images/ios2.png"
                alt="App Store"
                style={{ maxWidth: '100px', maxHeight: '60px' }}
              />
            </a>
            <a href="#">
              <img
                src="/images/play.png"
                alt="Google Play"
                style={{ maxWidth: '100px', maxHeight: '60px' }}
              />

            </a>

          </div>
          <img src="/images/reingro.jpg" />
        </div> */}

      </div>
      <Footer />
    </>
  );
}

const styles = {
  container: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    backgroundColor: '#642B73',
    backgroundImage:
      'linear-gradient(to bottom, #642B73, #040004)',
    color: '#fff',
  },
  scrollContainer: {
    flexGrow: 1,
  },
  inputContainer: {
    width: '100%',
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#fff',
    borderRadius: 8,
    overflow: 'hidden',
  },
  inputTitle: {
    color: 'lightgray',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    marginLeft: 10,
  },
  input: {
    width: '100%',
    height: 40,
    backgroundColor: '#fff',
    padding: '0 10px',
    border: 'none',
    cursor: 'pointer',
  },

  searchInput: {
    height: '40px',
    borderColor: 'gray',
    borderWidth: '1px',
    marginBottom: '10px',
    paddingLeft: '10px',
    borderRadius: '5px',
    width: '100%',
    fontSize: '16px', // Larger font size for better visibility
  },
  countryItem: {
    padding: '15px', // Increased padding for better touch area
    borderBottom: '1px solid #ccc',
    cursor: 'pointer',
  },

  countryText: {
    fontSize: 16,
  },
  registerButton: {
    backgroundColor: '#fff',


  },
  buttonText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },

};
