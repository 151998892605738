import React from 'react'
import ProfilePage from './components/ProfilePage'
import Moreblock from './components/Moreblock'
// import Connect from './components/Connect'
import Header2 from './components/Header2'
import Footer2 from './components/Footer2'

export default function DashBoard() {
  return (
    <>
      <Header2 />
      <div >
        <div className="main-content app-content" style={{
          margin: "-35px", background: 'lightgray', justifyContent: 'center',
          alignItems: 'center',
          minHeight: '110vh',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',

        }}>
          <div className="container-fluid "  >
            <div className="row"  >

              <ProfilePage />
              <Moreblock />

              {/* <Connect /> */}
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>

  )
}