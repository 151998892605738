// import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import connectionApi from '../../app/Services/Api/connectionApi';

// const ConnectNow = ({ details, connection, getProfiles }) => {
//     const [connectionuser, setConnection] = useState(connection);
//     const [loading, setLoading] = useState(false);
//     const user = useSelector((state) => state.auth?.user);
//     const { userId } = user;
//     const token = useSelector((state) => state.auth?.token);
//     const activeplan = useSelector((state) => state.auth?.activeplan);
//     console.log(connection, details.imageprivacy, "hghsghsgsg")
//     const calculateAge = (dob) => {
//         const today = new Date();
//         const birthDate = new Date(dob);
//         let age = today.getFullYear() - birthDate.getFullYear();
//         const monthDiff = today.getMonth() - birthDate.getMonth();

//         if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
//             age--;
//         }

//         return age;
//     };

//     const sendRequest = async () => {
//         try {
//             setLoading(true);

//             const { user } = details;
//             let receiverId = user._id;
//             console.log(receiverId, details, "sssssssss")
//             await connectionApi.createconnection(receiverId, token);

//             setConnection('Pending');
//             getProfiles();

//             alert('Connection Sent', 'Your connection request has been sent successfully.');
//         } catch (error) {
//             setConnection(false);
//             console.log('Error creating connection:', error);
//             alert('Unable to send connection request. Please try again.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="card" style={{ padding: '40px', backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white', marginBottom: '20px', border: 'none' }}>

//             <div style={{ position: 'relative', overflow: 'hidden', borderRadius: 10, }}>
//                 <img
//                     src={!details.imageprivacy ? (details?.profile_pich || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png') : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
//                     alt="Profile"
//                     style={{
//                         width: '100%', // Ensures the image spans the full width of its container
//                         height: 'auto', // Maintains aspect ratio
//                         display: 'block', // Removes bottom spacing
//                         borderRadius: '10px 10px 0 0', // Rounded corners only on top
//                     }}
//                 />
//             </div>

//             <div style={{ padding: '20px', alignItems: 'center', textAlign: 'center' }}>
//                 <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px', color: 'white' }}>{details.fullName}</h2>
//                 <p style={{ fontSize: '16px', marginBottom: '20px', color: 'white' }}>
//                     {details.dateOfBirth ? `${calculateAge(details.dateOfBirth)} years old | ${details.gender}` : ''}
//                 </p>

//                 {userId === details?.user._id ? (
//                     <div></div>
//                 ) : (
//                     <div style={{ alignItems: 'center' }}>
//                         {connection === 'Connected' ? (
//                             <div style={{ backgroundColor: 'orange', padding: '15px', borderRadius: '30px', borderWidth: '1px', borderColor: 'grey', cursor: 'pointer' }}>
//                                 <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Connected</span>
//                             </div>
//                         ) : connection === 'Pending' ? (
//                             <button
//                                 style={{
//                                     backgroundColor: '#E0E0E0',
//                                     padding: '15px',
//                                     borderRadius: '30px',
//                                     borderWidth: '1px',
//                                     borderColor: 'grey',
//                                     cursor: 'not-allowed',
//                                 }}
//                             >
//                                 <span style={{ color: '#000', fontWeight: 'bold', fontSize: '16px' }}>Request in Pending</span>
//                             </button>
//                         ) : connection === 'Connect Now' && activeplan ? (
//                             <button
//                                 onClick={sendRequest}
//                                 disabled={loading}
//                                 style={{
//                                     backgroundColor: '#007bff',
//                                     padding: '15px',
//                                     borderRadius: '30px',
//                                     borderWidth: '1px',
//                                     borderColor: 'grey',
//                                     cursor: 'pointer',
//                                 }}
//                             >
//                                 {loading ? <span>Loading...</span> : <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Connect Now</span>}
//                             </button>
//                         ) : (
//                             <span style={{ color: '#555', fontWeight: 'bold', fontSize: '16px' }}>Send Requests after Subscription</span>
//                         )}
//                     </div>
//                 )}
//             </div>
//         </div>
//         // </div>
//     );
// };

// export default ConnectNow;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import connectionApi from '../../app/Services/Api/connectionApi';
import { Active_Plan } from '../../app/redux/authActions';
import subscriptionApi from '../../app/Services/Api/subscriptionApi';

const ConnectNow = ({ details, connection, getProfiles }) => {
    const [connectionStatus, setConnectionStatus] = useState(connection);
    const [loading, setLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const user = useSelector((state) => state.auth?.user);
    const { userId } = user;
    const token = useSelector((state) => state.auth?.token);
    const activeplan = useSelector((state) => state.auth?.activeplan);
    const dispatch = useDispatch();

    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const getallSubscriptions = async () => {
        try {
            setLoading(true);
            const response = await subscriptionApi.getSubscriptions(token);
            console.log(response); // Verify response structure

            if (response?.exsit.length > 0) {
                const activeSubscription = response.exsit.find((subscription) => {
                    const endDate = new Date(subscription.endDate);
                    const today = new Date();
                    return endDate > today;
                });

                const endDate = activeSubscription ? new Date(activeSubscription.endDate) : null;
                const remainingDays = endDate ? Math.ceil((endDate - new Date()) / (1000 * 60 * 60 * 24)) : null;

                const data = {
                    activeplan: !!activeSubscription,
                    endDate: remainingDays,
                };

                console.log(data); // Log data to verify

                dispatch(Active_Plan(data)); // Update Redux state with active plan data
            } else {
                dispatch(Active_Plan(false)); // No subscriptions found, set active plan to false
            }

            setSubscriptions(response.subscriptions);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getallSubscriptions(); // Fetch subscriptions on component mount
    }, []);

    const sendRequest = async () => {
        try {
            setLoading(true);

            const { user: receiverUser } = details;
            const receiverId = receiverUser._id;

            await connectionApi.createconnection(receiverId, token);

            setConnectionStatus('Pending');
            getProfiles();

            console.log('Connection Sent successfully.');
            alert('Connection Sent', 'Your connection request has been sent successfully.');
        } catch (error) {
            setConnectionStatus(false);
            console.error('Error creating connection:', error);
            alert('Unable to send connection request. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const canConnectNow = () => {
        if (connectionStatus === 'Connected') {
            return false; // Already connected
        } else if (connectionStatus === 'Pending') {
            return false; // Request already pending
        } else if (!activeplan) {
            return false; // No active premium plan
        } else {
            return true; // Can connect now
        }
    };

    console.log('Component Rendered:', details, connectionStatus, activeplan);

    return (
        <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white', marginBottom: '20px', border: 'none' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="rounded-circle" style={{ width: '150px', height: '150px', overflow: 'hidden', borderRadius: '50%' }}>
                    <img
                        src={!details.imageprivacy ? (details?.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png') : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
                        alt="Profile"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
            </div>

            <div style={{ padding: '20px', alignItems: 'center', textAlign: 'center' }}>
                <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px', color: 'white', top: '10px' }}>{details.fullName}</h2>
                <p style={{ fontSize: '16px', marginBottom: '20px', color: 'white' }}>
                    {details.dateOfBirth ? `${calculateAge(details.dateOfBirth)} years old | ${details.gender}` : ''}
                </p>

                {userId === details?.user._id ? (
                    <div></div>
                ) : (
                    <div style={{ alignItems: 'center' }}>
                        {connectionStatus === 'Connected' ? (
                            <div style={{ background: 'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(130,45,253,0.984) 100%)', padding: '15px', borderRadius: '30px', borderWidth: '1px', borderColor: 'grey', cursor: 'pointer' }}>
                                <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '16px' }}>Connected</span>
                            </div>
                        ) : connectionStatus === 'Pending' ? (
                            <button
                                style={{
                                    backgroundColor: '#c5c5cc',
                                    padding: '15px',
                                    borderRadius: '30px',
                                    borderWidth: '1px',
                                    borderColor: 'grey',
                                    cursor: 'not-allowed',
                                }}
                            >
                                <span style={{ color: '#000', fontWeight: 'bold', fontSize: '16px' }}>Request in Pending</span>
                            </button>
                        ) : canConnectNow() ? (
                            <button
                                onClick={sendRequest}
                                disabled={loading}
                                style={{
                                    backgroundColor: '#007bff',
                                    padding: '15px',
                                    borderRadius: '30px',
                                    borderWidth: '1px',
                                    borderColor: 'grey',
                                    cursor: 'pointer',
                                }}
                            >
                                {loading ? <span>Loading...</span> : <span style={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>Connect Now</span>}
                            </button>
                        ) : (
                            <span style={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }}>Send Requests after Subscription</span>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConnectNow;
