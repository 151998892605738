import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

export default function Terms() {
  return (
    <div>
      <Header />
      <Container >
        <Row>
          <Col>
            <h2 className="text-center mb-5" style={{ color: "#2C1154", fontSize: '2rem', fontWeight: 'bold', marginTop: "20px" }}>
              Terms of Use / Service Agreement
            </h2>
            <h4>1. Acceptance of Terms of Use Agreement.</h4>
            <p>
              In order to use the Samasti Matrimony Service, you must register as a member on the Website/community sites/mobile applications (Site). Samasti Matrimony is an intermediary as defined under sub-clause (w) of Section 2 of the Information Technology Act, 2000. This Agreement is an electronic contract that establishes the legally binding terms you must accept to use the Site and to become a Member.
            </p>

            <h4>2. Eligibility.</h4>
            <p>
              To register as a Member of Samasti Matrimony or use this Site, you must be legally competent and of legal marriageable age as per the laws of India (currently, 18 years or over for females and 21 years or over for males).
            </p>

            <h4>3. Account Security.</h4>
            <p>
              You are responsible for maintaining the confidentiality of your login credentials you designate during the registration process, and you are solely responsible for all activities that occur under your account.
            </p>

            <h4>4. Term and Termination.</h4>
            <p>
              This Agreement will be effective, valid and subsisting as long as you use our Site. You may terminate your membership at any time, for any reason, by deleting your profile from the Site or writing to Samasti Matrimony.
            </p>

            <h4>5. Non-Commercial Use by Members.</h4>
            <p>
              The Site is for the personal use of individual members to advertise and promote their profiles for the purpose of generating accepts from relevant matches and cannot be used in connection with any commercial endeavors.
            </p>

            <h4>6. Other Terms of Use for Members.</h4>
            <p>
              Samasti Matrimony reserves the right in its sole discretion to review the activity and status of each account and block access to the member based on such review.
            </p>

            <h4>7. Proprietary Rights in the Content on Samasti Matrimony.</h4>
            <p>
              Samasti Matrimony owns and retains all proprietary rights in the Samasti Matrimony Site and the Samasti Matrimony Service.
            </p>

            <h4>8. Content Posted on the Site.</h4>
            <p>
              You understand and agree that Samasti Matrimony may delete any listing, content, communication, photos or profiles that in the sole judgment of Samasti Matrimony violate this Agreement or which might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of either Samasti Matrimony and/or its Members.
            </p>

            <h4>9. Your Interaction with other Members & Member Disputes.</h4>
            <p>
              You are solely responsible for your advertisement/communications with other Samasti Matrimony Members. Samasti Matrimony reserves the right, but has no obligation, to monitor disputes between you and other Members.
            </p>

            <h4>10. Privacy.</h4>
            <p>
              Use of the Samasti Matrimony Site and/or the Samasti Matrimony Service is governed by our Privacy Policy and follow guidelines to protect your privacy.
            </p>
            <h4>11. Disclaimers.</h4>
            <p>
              Samasti Matrimony is not responsible for any incorrect or inaccurate Content/listing posted on the Site or in connection with the Samasti Matrimony Service, whether caused by Users, Members or by any of the equipment or programming associated with or utilized in the Service.
            </p>

            <h4>12. Limitation on Liability.</h4>
            <p>
              In no event will Samasti Matrimony be liable to you or any third person for any indirect, consequential, exemplary, incidental, special or punitive damages, including also lost profits arising from your use of the Site or the Samasti Matrimony Service.
            </p>

            <h4>13. Jurisdiction.</h4>
            <p>
              If there is any dispute about or involving the Site and/or the Service, by using the Site, you unconditionally agree that all such disputes and/or differences will be governed by the laws of India and shall be subject to the exclusive jurisdiction of the Competent Courts in Mumbai, India only.
            </p>

            <h4>14. Indemnity.</h4>
            <p>
              You agree to indemnify and hold Samasti Matrimony, its subsidiaries, affiliates, officers, agents, and other partners and employees, fully indemnified and harmless from any loss, liability, claim, or demand, including reasonable attorneys fees, made by any third party due to or arising out of your use of the Service in violation of this Agreement.
            </p>


          </Col>
        </Row>
      </Container>
      <Footer />


    </div>


  );
}