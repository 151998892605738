import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // FontAwesome package for React web
import { faUsers, faFemale, faMale, faCity, faHome, faBuilding } from '@fortawesome/free-solid-svg-icons'; // FontAwesome icons

export default function UpdateFamilyDetails({ onPress, details, nav }) {
    return (
        <div className="card5" style={{ backgroundColor: 'rgba(44, 17, 84, 0.4)', backdropFilter: 'blur(10px)', color: 'white' }}>
            <div style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Family Details</span>

                </div>



                {details.fatherstatus ? (
                    <DetailRow icon={<FontAwesomeIcon icon={faMale} size="2x" style={{ color: 'white' }} />} label="Father" value={details.fatherstatus.name} />
                ) : (
                    <PlaceholderRow />
                )}

                {details.motherstatus ? (
                    <DetailRow icon={<FontAwesomeIcon icon={faFemale} size="2x" style={{ color: 'white' }} />} label="Mother" value={details.motherstatus.name} />
                ) : (
                    <PlaceholderRow />
                )}




                {details.familyaffluence ? (
                    <DetailRow icon={<FontAwesomeIcon icon={faBuilding} size="2x" style={{ color: 'white' }} />} label="Affluence" value={details.familyaffluence.name} />
                ) : (
                    <PlaceholderRow />
                )}

                {details.familytype ? (
                    <DetailRow icon={<FontAwesomeIcon icon={faUsers} size="2x" style={{ color: 'white' }} />} label="Family Type" value={details.familytype.name} />
                ) : (
                    <PlaceholderRow />
                )}

                {details.familyvalues ? (
                    <DetailRow icon={<FontAwesomeIcon icon={faHome} size="2x" style={{ color: 'white' }} />} label="Family Values" value={details.familyvalues.name} />
                ) : (
                    <PlaceholderRow />
                )}

                {details.noofsis ? (
                    <DetailRow icon={<FontAwesomeIcon icon={faFemale} size="2x" style={{ color: 'white' }} />} label="No of sisters" value={details.noofsis} />
                ) : (
                    <PlaceholderRow />
                )}
                {details.noofbro ? (
                    <DetailRow icon={<FontAwesomeIcon icon={faMale} size="2x" style={{ color: 'white' }} />} label="No of brothers" value={details.noofbro} />
                ) : (
                    <PlaceholderRow />
                )}
            </div>
        </div>
    );
}

const DetailRow = ({ icon, label, value }) => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }}>
        <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }}>
            {icon}
        </div>
        <div style={{ flex: 1, marginLeft: '20px' }}>
            <span style={{ color: '#777' }}>{label}</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{value}</span>
            </div>
        </div>
    </div>
);

const PlaceholderRow = () => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '16px', cursor: 'pointer' }}>
        <div style={{ padding: '10px', width: '50px', height: '50px', borderRadius: '25px' }} />
        <div style={{ flex: 1, marginLeft: '20px' }}>
            <span style={{ color: '#777' }}>Add details</span>
        </div>
    </div>
);
