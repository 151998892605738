import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ActivePlans from './ActivePlans';

const MembershipUi = ({ onRefresh, subscriptions, exists, loading }) => {
    const [refreshing, setRefreshing] = useState(false);
    const token = useSelector((state) => state.auth?.token);

    const handleRefresh = () => {
        setRefreshing(true);
        onRefresh().finally(() => setRefreshing(false)); // Ensure refreshing state is reset
    };

    return (
        <div >
            {/* <button onClick={handleRefresh} style={styles.refreshButton}>
                {refreshing ? 'Refreshing...' : 'Refresh'}
            </button> */}

            {Array.isArray(exists) && exists.length > 0 ? (
                <div>
                    <h2 style={styles.title}>Active Plan</h2>
                    {exists.map((subscription) => (
                        <ActivePlans key={subscription._id} subscription={subscription} />
                        // <div key={subscription._id} style={styles.subscriptionCard}>
                        //     <p style={styles.subscriptionName}>{subscription.name}</p>
                        //     <p style={styles.subscriptionDuration}>{subscription.duration} days left</p>
                        // </div>
                    ))}
                </div>
            ) : (
                <div style={styles.noActivePlan}>
                    <p style={styles.noActivePlanText}>No active plan available.</p>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#fff',
        padding: '16px',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        margin: '16px',
    },
    refreshButton: {
        padding: '10px 20px',
        marginBottom: '16px',
        backgroundColor: '#3498db',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '16px',
        color: '#3498db',
    },
    subscriptionCard: {
        padding: '16px',
        marginBottom: '20px',
        borderWidth: '1px',
        borderColor: '#ddd',
        borderRadius: '8px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    subscriptionName: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#3498db',
    },
    subscriptionDuration: {
        fontSize: '16px',
        color: '#555',
    },
    noActivePlan: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    },
    noActivePlanText: {
        fontSize: '16px',
        color: 'orange',
    },
};

export default MembershipUi;
