// import React, { useEffect } from 'react';
// import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { retrieveUser } from './app/redux/authActions';
// import HomePage from './pages/HomePage';
// import Member from './pages/Member';
// import AboutUs from './pages/AboutUs';
// import Contact from './pages/Contact';
// import Besafe from './pages/components/Besafe';
// import Terms from './pages/components/Terms';
// import Privacy from './pages/components/Privacy';
// import Report from './pages/components/Report';
// import MyAccount from './pages/components/Myaccount';
// import Login from './pages/components/Login';
// import Inbox from './pages/components/Inbox';
// import Packages from './pages/components/Packages';
// import Search from './pages/components/Search';
// import MyProfile from './pages/components/MyProfile';
// import MyEdit from './pages/components/MyEdit';
// import MyPref from './pages/components/MyPref';
// import ViewProfile from './pages/components/ViewProfile';
// import Home from './pages/Home';
// import AuthHandler from './AuthHandler';


// export default function Main() {
//   const token = useSelector((state) => state.auth.token);
// console.log("token from Main",token)
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/membership" element={<Member />} />
//         <Route path="/about" element={<AboutUs />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/besafe" element={<Besafe />} />
//         <Route path="/terms" element={<Terms />} />
//         <Route path="/privacy" element={<Privacy />} />
//         <Route path="/report" element={<Report />} />
//         <Route path="/myaccount" element={<MyAccount />} /> {/* Always accessible */}
//         <Route path="/login" element={token ? <Navigate to="/profilepage" replace /> : <Login />} /> {/* Redirect to profile page if authenticated */}
//         <Route path="/profilepage" element={<HomePage />} />
//         <Route path="/inbox" element={<Inbox />} />
//         <Route path="/packages" element={<Packages />} />
//         <Route path="/search" element={<Search />} />
//         <Route path="/myprofile" element={<MyProfile />} />
//         <Route path="/edit" element={<MyEdit />} />
//         <Route path="/mypreference" element={<MyPref />} />
//         <Route path="/viewprofile" element={<ViewProfile />} />
//       </Routes>
//       <AuthHandler /> {/* Ensure this is rendered inside BrowserRouter */}
//     </BrowserRouter>
//   );
// }











import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveUser } from './app/redux/authActions';
import Home from './pages/Home';
import Member from './pages/Member';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Besafe from './pages/components/Besafe';
import Terms from './pages/components/Terms';
import Privacy from './pages/components/Privacy';
import Report from './pages/components/Report';
import MyAccount from './pages/components/Myaccount';
import Login from './pages/components/Login';
import Inbox from './pages/components/Inbox';
import Packages from './pages/components/Packages';
import Search from './pages/components/Search';
import MyProfile from './pages/components/MyProfile';
import MyEdit from './pages/components/MyEdit';
import MyPref from './pages/components/MyPref';
import ViewProfile from './pages/components/ViewProfile';
import DashBoard from './pages/DashBoard';
import ProtectedRoute from './ProtectedRoute';
import Chat from './pages/components/Chat';
import ImageUpload from './pages/components/ImageUpload';
import ContactReg from './pages/components/ContactReg';


const Main = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(retrieveUser());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactreg" element={<ContactReg />} />
        <Route path="/membership" element={<Member />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/besafe" element={<Besafe />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/report" element={<Report />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/login" element={token ? <Navigate to="/dashboard" /> : <Login />} />

        {token && (
          <>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashBoard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/inbox"
              element={
                <ProtectedRoute>
                  <Inbox />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat/:conversationID"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/packages"
              element={
                <ProtectedRoute>
                  <Packages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/myprofile"
              element={
                <ProtectedRoute>
                  <MyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit"
              element={
                <ProtectedRoute>
                  <MyEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mypreference"
              element={
                <ProtectedRoute>
                  <MyPref />
                </ProtectedRoute>
              }
            />
            <Route
              path="/search"
              element={
                <ProtectedRoute>
                  <Search />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload"
              element={
                <ProtectedRoute>
                  <ImageUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/viewprofile/:profileId"
              element={
                <ProtectedRoute>
                  <ViewProfile />
                </ProtectedRoute>
              }
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Main;
