import React from 'react';

export default function DownloadApp() {
  return (
    <div>
      <section className="bg-white mt-4 mb-4">
        <div className="container mt-5 mb-5">
          <div className="row">
            {/* Left side: App image */}
            <div className="col-md-6">
              <img
                src="/images/garland.jpg"
                className="img-fluid"
                alt="App Preview"
                style={{ height: "auto", width: "500px" }}
              />
            </div>

            {/* Right side: Description and app store logos */}
            <div className="col-md-6 d-flex align-items-center">
              <div>
                <h2>Download Samasti Matrimony App  </h2>
                <p>
                  Finding your perfect match has never been easier with Samasti Matrimony. Our app offers a seamless and personalized experience, connecting you with potential partners who share your interests and values. With advanced search filters and user-friendly features, you can browse profiles, chat, and arrange meetings all in one place. Download now and take the first step towards your happily ever after!
                </p>
                <div className="mt-4 mb-4">
                  <img
                    src="/images/ios2.png"
                    className="mr-3"
                    alt="App Store"
                    style={{ maxWidth: '160px', maxHeight: '90px' }}
                  />
                  <img
                    src="/images/play.png"
                    alt="Play Store"
                    style={{ maxWidth: '160px', maxHeight: '90px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center py-5">
        <p className="text-center font-weight-bold mr-md-3 mb-4 mb-md-0" style={{ fontSize: '2rem' }}>
          Your story is waiting to happen!
        </p>
        <a href="/" className="text-decoration-none">
          <button className="btn btn-dark px-4 py-2" style={{ fontSize: '1.5rem', borderRadius: '3px' }}>
            Get Started
          </button>
        </a>
      </div>

    </div>
  );
}
