// import React from 'react';
// import { BiUserPlus, BiChat, BiLink } from 'react-icons/bi';
// export default function Features() {
//   return (
//     <div>


//       <section className="how-it-works py-5 bg-white mt-3"> {/* Adjusted marginTop value */}
//         <div className="container">
//           <h1 className="section-title text-center mb-5 " style={{ fontSize: '27px', fontWeight: 'bold' }}>How It Works</h1>
//           <div className="row">
//             <div className="col-md-4">
//               <div className="feature-card text-center">
//                 <div className='mb-4'>
//                   <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#2C1154" className="bi bi-search-heart" viewBox="0 0 16 16">
//                     <path d="M6.5 4.482c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.69 0-5.018" />
//                     <path d="M13 6.5a6.47 6.47 0 0 1-1.258 3.844q.06.044.115.098l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1-.1-.115h.002A6.5 6.5 0 1 1 13 6.5M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11" />
//                   </svg>
//                 </div>
//                 <h3>Search Your Partner</h3>
//                 <p>Find your perfect match by searching through our extensive database.</p>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className="feature-card text-center">
//                 <div className='mb-4'>
//                   <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#2C1154" className="bi bi-balloon-heart-fill" viewBox="0 0 16 16">
//                     <path fillRule="evenodd" d="M8.49 10.92C19.412 3.382 11.28-2.387 8 .986 4.719-2.387-3.413 3.382 7.51 10.92l-.234.468a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2 2 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.235-.468ZM6.726 1.269c-1.167-.61-2.8-.142-3.454 1.135-.237.463-.36 1.08-.202 1.85.055.27.467.197.527-.071.285-1.256 1.177-2.462 2.989-2.528.234-.008.348-.278.14-.386" />
//                   </svg>
//                 </div>
//                 <h3>100% Match People</h3>
//                 <p>Discover profiles that are perfectly matched to your preferences.</p>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className="feature-card text-center">
//                 <div className='mb-4'>
//                   <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#2C1154" className="bi bi-person-badge" viewBox="0 0 16 16">
//                     <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
//                     <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492z" />
//                   </svg>
//                 </div>
//                 <h3>Find Out Partner</h3>
//                 <p>Connect with your potential life partner and begin your journey together.</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>


//       <section className="features"
//         style={{
//           backgroundPosition: 'center center',
//           backgroundSize: 'cover',
//           backgroundRepeat: 'no-repeat',
//           backgroundColor: '#2C1154',
//           height: 'auto',
//           padding: '30px 0',
//           overflowX: 'hidden',

//         }}>
//         <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: 'auto', }}>
//           <div className="text-center">
//             <h1 className="section-title" style={{ color: 'white', fontSize: '27px', fontWeight: 'bold', marginBottom: '2rem' }}>
//               Search for Your Ideal Partner
//             </h1>
//             <p className="section-title mb-4 responsive-text" style={{ color: 'white', }}>
//               Our matrimony platform is like a breath of fresh air. Clean and trendy design with ready-to-use features we are sure you will love.
//             </p>
//             <div className="row justify-content-center" style={{ marginRight: '20px', }}>
//               <div className="col-xl-3 col-md-6 col-sm-10 mb-4">
//                 <div className="card custom-card h-100" style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px' }}>
//                   <div className="card-body text-center">
//                     <BiUserPlus size={38} style={{ color: '#2C1154' }} />
//                     <h3 className="card-title mt-3" style={{ color: '#2C1154', fontSize: '1.3rem' }}>Sign up</h3>
//                     <p className="card-text" style={{ color: '#2C1154', fontSize: '1rem' }}>Create your account and start your journey.</p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-xl-3 col-md-6 col-sm-10 mb-4">
//                 <div className="card custom-card h-100" style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px' }}>
//                   <div className="card-body text-center">
//                     <BiChat size={38} style={{ color: '#2C1154' }} />
//                     <h3 className="card-title mt-3" style={{ color: '#2C1154', fontSize: '1.3rem' }}>Connect</h3>
//                     <p className="card-text" style={{ color: '#2C1154', fontSize: '1rem' }}>Find and connect with potential matches.</p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-xl-3 col-md-6 col-sm-10 mb-4">
//                 <div className="card custom-card h-100" style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px' }}>
//                   <div className="card-body text-center">
//                     <BiLink size={38} style={{ color: '#2C1154' }} />
//                     <h3 className="card-title mt-3" style={{ color: '#2C1154', fontSize: '1.3rem' }}>Integrate</h3>
//                     <p className="card-text" style={{ color: '#2C1154', fontSize: '1rem' }}>Seamlessly integrate with other platforms.</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>





//     </div>
//   );
// }
import React from 'react';
import { BiUserPlus, BiChat, BiLink } from 'react-icons/bi';
// Import the custom CSS file

export default function Features() {
  return (
    <div>
      <section className="how-it-works py-5 bg-white mt-3">
        <div className="container">
          <h1 className="section-title text-center mb-5" style={{ fontSize: '27px', fontWeight: 'bold' }}>How It Works</h1>
          <div className="row">
            <div className="col-md-4">
              <div className="feature-card text-center">
                <div className="mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#2C1154" className="bi bi-search-heart" viewBox="0 0 16 16">
                    <path d="M6.5 4.482c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.69 0-5.018" />
                    <path d="M13 6.5a6.47 6.47 0 0 1-1.258 3.844q.06.044.115.098l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1-.1-.115h.002A6.5 6.5 0 1 1 13 6.5M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11" />
                  </svg>
                </div>
                <h3>Search Your Partner</h3>
                <p>Find your perfect match by searching through our extensive database.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-card text-center">
                <div className="mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#2C1154" className="bi bi-balloon-heart-fill" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8.49 10.92C19.412 3.382 11.28-2.387 8 .986 4.719-2.387-3.413 3.382 7.51 10.92l-.234.468a.25.25 0 1 0 .448.224l.04-.08c.009.17.024.315.051.45.068.344.208.622.448 1.102l.013.028c.212.422.182.85.05 1.246-.135.402-.366.751-.534 1.003a.25.25 0 0 0 .416.278l.004-.007c.166-.248.431-.646.588-1.115.16-.479.212-1.051-.076-1.629-.258-.515-.365-.732-.419-1.004a2 2 0 0 1-.037-.289l.008.017a.25.25 0 1 0 .448-.224l-.235-.468ZM6.726 1.269c-1.167-.61-2.8-.142-3.454 1.135-.237.463-.36 1.08-.202 1.85.055.27.467.197.527-.071.285-1.256 1.177-2.462 2.989-2.528.234-.008.348-.278.14-.386" />
                  </svg>
                </div>
                <h3>100% Match People</h3>
                <p>Discover profiles that are perfectly matched to your preferences.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-card text-center">
                <div className="mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="#2C1154" className="bi bi-person-badge" viewBox="0 0 16 16">
                    <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                    <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492z" />
                  </svg>
                </div>
                <h3>Find Out Partner</h3>
                <p>Connect with your potential life partner and begin your journey together.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features  text-white py-5" style={{ background: '#2C1154' }}>
        <div className="container">
          <h1 className="section-title text-center text-white mb-5">Search for Your Ideal Partner</h1>
          <p className="section-subtitle text-center mb-4">
            Our matrimony platform is like a breath of fresh air. Clean and trendy design with ready-to-use features we are sure you will love.
          </p>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-md-6 col-sm-10 mb-4">
              <div className="card custom-card h-100 bg-light">
                <div className="card-body text-center">
                  <BiUserPlus size={38} style={{ color: '#2C1154' }} />
                  <h3 className="card-title mt-3" style={{ color: '#2C1154' }}>Sign up</h3>
                  <p className="card-text" style={{ color: '#2C1154' }}>Create your account and start your journey.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-sm-10 mb-4">
              <div className="card custom-card h-100 bg-light">
                <div className="card-body text-center">
                  <BiChat size={38} style={{ color: '#2C1154' }} />
                  <h3 className="card-title mt-3" style={{ color: '#2C1154' }}>Connect</h3>
                  <p className="card-text" style={{ color: '#2C1154' }}>Find and connect with potential matches.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-sm-10 mb-4">
              <div className="card custom-card h-100 bg-light">
                <div className="card-body text-center">
                  <BiLink size={38} style={{ color: '#2C1154' }} />
                  <h3 className="card-title mt-3" style={{ color: '#2C1154' }}>Integrate</h3>
                  <p className="card-text" style={{ color: '#2C1154' }}>Seamlessly integrate with other platforms.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
