// authActions.js
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, ADD_USER, ACTIVE_PLAN, ADD_USER_DATA } from './Types';
import authApi from '../Services/Api/authApi';
import profileApi from '../Services/Api/profileApi';

// Action creator for login success
export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData,
});

// Action creator for login failure
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// Action creator for logout
export const logout = () => {
  localStorage.removeItem('authToken');
  return { type: LOGOUT };
};

export const addUser = (data) => ({
  type: ADD_USER,
  payload: data,
});

export const Active_Plan = (data) => ({
  type: ACTIVE_PLAN,
  payload: data,
});

// Async action for login
export const login = (phone, otp) => async (dispatch) => {
  try {
    const response = await authApi.login(phone, otp);
    const userData = response.data;

    // Store the auth token and user data in local storage
    localStorage.setItem('authToken', userData.token);
    localStorage.setItem('userData', JSON.stringify(userData.user));
    console.log('Token and user data stored in localStorage:', userData.token, userData.user); // Debug logging

    const userResponse = await profileApi.getminprofile(userData.token);
    const user = userResponse;
    console.log('Complete user profile data:', user);
    localStorage.setItem('userProfile', JSON.stringify(user));
    // Dispatch login success action with user data
    dispatch(loginSuccess(userData));
    dispatch(addUser(user))
  } catch (error) {
    console.error('Login failed:', error); // Error logging
    dispatch(loginFailure(error));
  }
};

// Async action to retrieve user data from local storage
export const retrieveUser = () => async (dispatch) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const storedUserData = localStorage.getItem('userData');
    const storedUserProfile = localStorage.getItem('userProfile');

    if (!authToken || !storedUserData || !storedUserProfile) {
      console.error('Token, user data, or user profile is missing from local storage');
      return;
    }

    const userData = JSON.parse(storedUserData);
    const userProfile = JSON.parse(storedUserProfile);

    console.log('Token and user data retrieved from localStorage:', authToken, userData);
    console.log('User profile data retrieved from localStorage:', userProfile);

    // Dispatch login success action with retrieved token and user data
    dispatch(loginSuccess({ token: authToken, user: userData }));
    dispatch(addUser(userProfile));
  } catch (error) {
    console.error('Error retrieving user data from localStorage:', error); // Error logging
  }
};
