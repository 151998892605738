import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import authApi from '../../app/Services/Api/authApi';
import { Country } from 'country-state-city';

export default function MyAccount() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [isCountryModalVisible, setCountryModalVisible] = useState(false);
  const [searchCountry, setSearchCountry] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const Countries = Country.getAllCountries();
  const [isOpen, setIsOpen] = useState(true);

  const handleLogin = async () => {
    try {
      if (!phoneNumber || !selectedCountry) {
        return alert("Enter Valid Inputs");
      }
      setLoading(true);
      const response = await authApi.generatehotp(selectedCountry?.phonecode, phoneNumber);
      console.log('API Response:', response);

      if (response.status === 200) {
        setLoading(false);
        navigate("/login", { state: { country_code: selectedCountry?.phonecode, phone: phoneNumber, Prefix: selectedCountry?.phone } });
      } else if (response.status === 404) {
        setLoading(false);
        alert('User not found');
      } else {
        setLoading(false);
        console.log('Error generating OTP:', response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log('Error during login:', error.response ? error.response.data : error.message);
      alert("Something went wrong. Please try again.");
    }
  };

  const getIndia = async () => {
    try {
      const data = Country.getCountryByCode("IN");
      setSelectedCountry(data);
    } catch (error) {
      console.log("Error while fetching India data:", error);
    }
  };

  useEffect(() => {
    getIndia();
  }, []);

  const filterItems = (data, searchQuery) => {
    return data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    toggleCountryModal();
  };

  const toggleCountryModal = () => setCountryModalVisible(!isCountryModalVisible);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Header />
      <div
        className="loginForm"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '130vh',
          background: 'linear-gradient(to bottom, rgba(44, 17, 84, 0.6), rgba(44, 17, 84, 0.45)), url("/images/sitting.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div
          style={{
            width: '90%',
            maxWidth: '600px',
            padding: '40px',
            backgroundColor: 'rgba(44, 17, 84, 0.3)',
            backdropFilter: 'blur(10px)',
            borderRadius: '10px',
            color: '#FFFFFF',
            position: 'relative'
          }}
        >
          <h1 style={{ textAlign: 'center', marginBottom: '30px', color: "white" }}>Generate OTP for Login</h1>
          <a href='/' onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '10px', color: '#FFFFFF', background: 'none', border: 'none', cursor: 'pointer', fontSize: '25px' }}>&times;</a>

          <div style={{ marginBottom: '20px' }}>
            <label style={{ marginBottom: '10px', display: 'block' }}>Select Country</label>
            <div style={{ position: 'relative' }}>
              <div onClick={toggleCountryModal} style={{ backgroundColor: '#FFFFFF', color: 'black', borderRadius: '5px', padding: '10px', cursor: 'pointer' }}>
                {selectedCountry ? selectedCountry.name : 'Select Country'}
                {isCountryModalVisible && (
                  <div style={{ position: 'absolute', backgroundColor: '#FFFFFF', width: '100%', zIndex: '1', borderRadius: '5px', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', overflowY: 'auto', maxHeight: '150px' }}>
                    {filterItems(Countries, searchCountry).map((country) => (
                      <div key={country.isoCode} onClick={() => handleCountrySelect(country)} style={{ padding: '10px', cursor: 'pointer' }}>{country.name}</div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label style={{ marginBottom: '10px', display: 'block' }}>Phone Number/Email ID</label>
            <input
              type="text"
              placeholder="Enter Phone Number or Email ID"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ backgroundColor: '#FFFFFF', color: 'black', borderRadius: '5px', padding: '10px', width: '100%' }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <button onClick={handleLogin} style={{ backgroundColor: '#FFFFFF', color: '#2C1154', border: 'none', borderRadius: '5px', padding: '10px 20px', cursor: 'pointer', flex: '1', marginRight: '10px' }}>
              Get OTP
            </button>

          </div>

          <div style={{ textAlign: 'center' }}>
            <a href="/" style={{ color: '#FFFFFF', marginRight: '26px', textDecoration: 'none', }}>Dont have an Account? <span style={{ color: 'orange', textDecoration: 'underline', fontWeight: 'bold' }}>Register</span></a>
            <a href="/terms" style={{ color: 'white', textDecoration: 'underline' }}>Terms and Conditions</a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

