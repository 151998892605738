import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import AboutSection from './components/AboutSection';

export default function AboutUs() {
  return (
    <div>
     
     <Header/>
      <AboutSection/>
      <Footer/>
      
    </div>
);
}
