const generaldata =
  [{
    "_id": {
      "$oid": "658924b76c452003ef341391"
    },
    "name": "Employed",
    "type": "Fatherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    }
  },
  {
    "_id": {
      "$oid": "658924b76c452003ef341392"
    },
    "name": "Business",
    "type": "Fatherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    }
  },
  {
    "_id": {
      "$oid": "658924b76c452003ef341393"
    },
    "name": "Retired",
    "type": "Fatherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    }
  },
  {
    "_id": {
      "$oid": "658924b76c452003ef341395"
    },
    "name": "Deceased",
    "type": "Fatherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    }
  },
  {
    "_id": {
      "$oid": "658924b76c452003ef341394"
    },
    "name": "Not Employed",
    "type": "Fatherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:44:07.778Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef34139f"
    },
    "name": "Remarried",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.593Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.593Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef341399"
    },
    "name": "Business",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef34139b"
    },
    "name": "Not Employed",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef34139e"
    },
    "name": "Divorced",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.593Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.593Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef341398"
    },
    "name": "Employed",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef34139d"
    },
    "name": "Single Parent",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.593Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.593Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef341397"
    },
    "name": "Homemaker",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef34139c"
    },
    "name": "Deceased",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef3413a0"
    },
    "name": "Separated",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.593Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.593Z"
    }
  },
  {
    "_id": {
      "$oid": "658924f36c452003ef34139a"
    },
    "name": "Retired",
    "type": "Motherstatus",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:45:07.592Z"
    }
  },
  {
    "_id": {
      "$oid": "6589255c6c452003ef3413a3"
    },
    "name": "HIV Positive",
    "type": "Health",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    }
  },
  {
    "_id": {
      "$oid": "6589255c6c452003ef3413a7"
    },
    "name": "Heart Ailments",
    "type": "Health",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    }
  },
  {
    "_id": {
      "$oid": "6589255c6c452003ef3413a2"
    },
    "name": "No Health Problems",
    "type": "Health",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:46:52.232Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:46:52.232Z"
    }
  },
  {
    "_id": {
      "$oid": "6589255c6c452003ef3413a5"
    },
    "name": "Low Blood Pressure",
    "type": "Health",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    }
  },
  {
    "_id": {
      "$oid": "6589255c6c452003ef3413a8"
    },
    "name": "Other",
    "type": "Health",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    }
  },
  {
    "_id": {
      "$oid": "6589255c6c452003ef3413a4"
    },
    "name": "Diabetes",
    "type": "Health",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    }
  },
  {
    "_id": {
      "$oid": "6589255c6c452003ef3413a6"
    },
    "name": "High Blood Pressure",
    "type": "Health",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:46:52.233Z"
    }
  },
  {
    "_id": {
      "$oid": "65892652cf056cdeb54c8ed8"
    },
    "name": "AB+",
    "type": "Bloodgroup",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    }
  },
  {
    "_id": {
      "$oid": "65892652cf056cdeb54c8eda"
    },
    "name": "O+",
    "type": "Bloodgroup",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    }
  },
  {
    "_id": {
      "$oid": "65892652cf056cdeb54c8ed6"
    },
    "name": "B+",
    "type": "Bloodgroup",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    }
  },
  {
    "_id": {
      "$oid": "65892652cf056cdeb54c8ed4"
    },
    "name": "A+",
    "type": "Bloodgroup",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    }
  },
  {
    "_id": {
      "$oid": "65892652cf056cdeb54c8ed9"
    },
    "name": "AB-",
    "type": "Bloodgroup",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    }
  },
  {
    "_id": {
      "$oid": "65892652cf056cdeb54c8ed5"
    },
    "name": "A-",
    "type": "Bloodgroup",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    }
  },
  {
    "_id": {
      "$oid": "65892652cf056cdeb54c8ed7"
    },
    "name": "B-",
    "type": "Bloodgroup",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    }
  },
  {
    "_id": {
      "$oid": "65892652cf056cdeb54c8edb"
    },
    "name": "O-",
    "type": "Bloodgroup",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:50:58.509Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def5621"
    },
    "name": "Chef",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.815Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.815Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def5619"
    },
    "name": "Engineer",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def561a"
    },
    "name": "Teacher",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def5618"
    },
    "name": "Doctor",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def561b"
    },
    "name": "Lawyer",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def5620"
    },
    "name": "Salesperson",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.815Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.815Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def561d"
    },
    "name": "Business Owner",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def561e"
    },
    "name": "Nurse",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def561c"
    },
    "name": "Artist",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.814Z"
    }
  },
  {
    "_id": {
      "$oid": "6589270a22da3dee2def561f"
    },
    "name": "Programmer",
    "type": "Occupation",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:54:02.815Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:54:02.815Z"
    }
  },
  {
    "_id": {
      "$oid": "658927ad97df3c337add7c7f"
    },
    "name": "Childless Family",
    "type": "Familytype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    }
  },
  {
    "_id": {
      "$oid": "658927ad97df3c337add7c7a"
    },
    "name": "Nuclear Family",
    "type": "Familytype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:56:45.620Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:56:45.620Z"
    }
  },
  {
    "_id": {
      "$oid": "658927ad97df3c337add7c80"
    },
    "name": "Empty Nest Family",
    "type": "Familytype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    }
  },
  {
    "_id": {
      "$oid": "658927ad97df3c337add7c7c"
    },
    "name": "Extended Family",
    "type": "Familytype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    }
  },
  {
    "_id": {
      "$oid": "658927ad97df3c337add7c7b"
    },
    "name": "Joint Family",
    "type": "Familytype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:56:45.620Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:56:45.620Z"
    }
  },
  {
    "_id": {
      "$oid": "658927ad97df3c337add7c7e"
    },
    "name": "Blended Family",
    "type": "Familytype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    }
  },
  {
    "_id": {
      "$oid": "658927ad97df3c337add7c81"
    },
    "name": "Other",
    "type": "Familytype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    }
  },
  {
    "_id": {
      "$oid": "658927ad97df3c337add7c7d"
    },
    "name": "Single-Parent Family",
    "type": "Familytype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:56:45.621Z"
    }
  },
  {
    "_id": {
      "$oid": "6589285e97df3c337add7c85"
    },
    "name": "Liberal",
    "type": "Familyvalues",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:59:42.769Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:59:42.769Z"
    }
  },
  {
    "_id": {
      "$oid": "6589285e97df3c337add7c84"
    },
    "name": "Moderate",
    "type": "Familyvalues",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:59:42.768Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:59:42.768Z"
    }
  },
  {
    "_id": {
      "$oid": "6589285e97df3c337add7c83"
    },
    "name": "Traditional",
    "type": "Familyvalues",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T06:59:42.768Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T06:59:42.768Z"
    }
  },
  {
    "_id": {
      "$oid": "658929675e5bff6ecddee8e2"
    },
    "name": "Middle Class",
    "type": "Familyaffluence",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:04:07.441Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:04:07.441Z"
    }
  },
  {
    "_id": {
      "$oid": "658929675e5bff6ecddee8e1"
    },
    "name": "Affluent",
    "type": "Familyaffluence",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:04:07.440Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:04:07.440Z"
    }
  },
  {
    "_id": {
      "$oid": "658929675e5bff6ecddee8e3"
    },
    "name": "Lower Middle Class",
    "type": "Familyaffluence",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:04:07.441Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:04:07.441Z"
    }
  },
  {
    "_id": {
      "$oid": "658929675e5bff6ecddee8e4"
    },
    "name": "Not Applicable",
    "type": "Familyaffluence",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:04:07.441Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:04:07.441Z"
    }
  },
  {
    "_id": {
      "$oid": "65892a235e5bff6ecddee8e9"
    },
    "name": "Very Low Income",
    "type": "Incometype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:07:15.578Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:07:15.578Z"
    }
  },
  {
    "_id": {
      "$oid": "65892a235e5bff6ecddee8e8"
    },
    "name": "Low Income",
    "type": "Incometype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:07:15.577Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:07:15.577Z"
    }
  },
  {
    "_id": {
      "$oid": "65892a235e5bff6ecddee8e6"
    },
    "name": "High Income",
    "type": "Incometype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:07:15.576Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:07:15.576Z"
    }
  },
  {
    "_id": {
      "$oid": "65892a235e5bff6ecddee8ea"
    },
    "name": "Not Applicable",
    "type": "Incometype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:07:15.580Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:07:15.580Z"
    }
  },
  {
    "_id": {
      "$oid": "65892a235e5bff6ecddee8e7"
    },
    "name": "Middle Income",
    "type": "Incometype",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:07:15.577Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:07:15.577Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d81"
    },
    "name": "Private Sector",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.657Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.657Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d88"
    },
    "name": "Retired",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d89"
    },
    "name": "Other",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.659Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.659Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d86"
    },
    "name": "Not Employed",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d83"
    },
    "name": "Self-Employed",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d82"
    },
    "name": "Public Sector",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d85"
    },
    "name": "Entrepreneur",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d84"
    },
    "name": "Freelance",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    }
  },
  {
    "_id": {
      "$oid": "65892b8792b858c4ce738d87"
    },
    "name": "Student",
    "type": "Workingin",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:13:11.658Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e86979"
    },
    "name": "Professional Certification",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e86978"
    },
    "name": "Vocational Training",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e8697b"
    },
    "name": "Other",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.710Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.710Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e86975"
    },
    "name": "Bachelor's Degree",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.708Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.708Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e86973"
    },
    "name": "High School Diploma",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.708Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.708Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e86977"
    },
    "name": "Doctorate",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e86976"
    },
    "name": "Master's Degree",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e86974"
    },
    "name": "Associate's Degree",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.708Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.708Z"
    }
  },
  {
    "_id": {
      "$oid": "65892ddd560a8d5ba4e8697a"
    },
    "name": "No Formal Education",
    "type": "Qualification",
    "parentId": null,
    "__v": 0,
    "createdAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    },
    "updatedAt": {
      "$date": "2023-12-25T07:23:09.709Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "name": "India",
    "type": "Country",
    "code": 91,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.954Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.954Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffbc"
    },
    "name": "USA/Cannada +1",
    "type": "Country",
    "code": 1,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.963Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.963Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffbd"
    },
    "name": "United Kingdom",
    "type": "Country",
    "code": 44,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.964Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.964Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffbf"
    },
    "name": "Australia",
    "type": "Country",
    "code": 61,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.966Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.966Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc0"
    },
    "name": "United Arab Emirates",
    "type": "Country",
    "code": 971,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.967Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.967Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc1"
    },
    "name": "Saudi Arabia",
    "type": "Country",
    "code": 966,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.968Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.968Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc2"
    },
    "name": "Qatar",
    "type": "Country",
    "code": 974,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.969Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.969Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc3"
    },
    "name": "Singapore",
    "type": "Country",
    "code": 65,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.969Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.969Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc4"
    },
    "name": "Malaysia",
    "type": "Country",
    "code": 60,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.970Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.970Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc5"
    },
    "name": "Oman",
    "type": "Country",
    "code": 968,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.971Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.971Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc6"
    },
    "name": "New Zealand",
    "type": "Country",
    "code": 64,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.971Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.971Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc7"
    },
    "name": "Kuwait",
    "type": "Country",
    "code": 965,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.972Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.972Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc8"
    },
    "name": "Bahrain",
    "type": "Country",
    "code": 973,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.973Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.973Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffc9"
    },
    "name": "Germany",
    "type": "Country",
    "code": 49,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.980Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.980Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffca"
    },
    "name": "South Africa",
    "type": "Country",
    "code": 27,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.981Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.981Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffcb"
    },
    "name": "Netherlands",
    "type": "Country",
    "code": 31,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.981Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.981Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffcc"
    },
    "name": "France",
    "type": "Country",
    "code": 33,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.983Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.983Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffcd"
    },
    "name": "Sweden",
    "type": "Country",
    "code": 46,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.984Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.984Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffce"
    },
    "name": "Norway",
    "type": "Country",
    "code": 47,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.984Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.984Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffcf"
    },
    "name": "Switzerland",
    "type": "Country",
    "code": 41,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.984Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.984Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd0"
    },
    "name": "Denmark",
    "type": "Country",
    "code": 45,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.985Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.985Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd1"
    },
    "name": "Ireland",
    "type": "Country",
    "code": 353,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.985Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.985Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd2"
    },
    "name": "Italy",
    "type": "Country",
    "code": 39,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.985Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.985Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd3"
    },
    "name": "Spain",
    "type": "Country",
    "code": 34,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.986Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.986Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd4"
    },
    "name": "Belgium",
    "type": "Country",
    "code": 32,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.987Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.987Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd5"
    },
    "name": "Austria",
    "type": "Country",
    "code": 43,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.988Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.988Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd6"
    },
    "name": "Hong Kong",
    "type": "Country",
    "code": 852,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.990Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.990Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd7"
    },
    "name": "Japan",
    "type": "Country",
    "code": 81,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.992Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.992Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd8"
    },
    "name": "China",
    "type": "Country",
    "code": 86,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.992Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.992Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffd9"
    },
    "name": "South Korea",
    "type": "Country",
    "code": 82,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.995Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.995Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffda"
    },
    "name": "Thailand",
    "type": "Country",
    "code": 66,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.996Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.996Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffdb"
    },
    "name": "Philippines",
    "type": "Country",
    "code": 63,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.996Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.996Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffdc"
    },
    "name": "Indonesia",
    "type": "Country",
    "code": 62,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.996Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.996Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffdd"
    },
    "name": "Fiji",
    "type": "Country",
    "code": 679,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.996Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.996Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffdf"
    },
    "name": "Ghana",
    "type": "Country",
    "code": 233,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.998Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.998Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe0"
    },
    "name": "Kenya",
    "type": "Country",
    "code": 254,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.998Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.998Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe1"
    },
    "name": "Nigeria",
    "type": "Country",
    "code": 234,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.998Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.998Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe2"
    },
    "name": "Tanzania",
    "type": "Country",
    "code": 255,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.999Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.999Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe3"
    },
    "name": "Uganda",
    "type": "Country",
    "code": 256,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:52.999Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:52.999Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe4"
    },
    "name": "Zimbabwe",
    "type": "Country",
    "code": 263,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:53.000Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:53.000Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe5"
    },
    "name": "Zambia",
    "type": "Country",
    "code": 260,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:53.000Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:53.000Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe6"
    },
    "name": "Botswana",
    "type": "Country",
    "code": 267,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:53.000Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:53.000Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe7"
    },
    "name": "Namibia",
    "type": "Country",
    "code": 264,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:53.000Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:53.000Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe8"
    },
    "name": "Mauritius",
    "type": "Country",
    "code": 230,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:53.001Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:53.001Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffe9"
    },
    "name": "Mauritania",
    "type": "Country",
    "code": 222,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:53.001Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:53.001Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffea"
    },
    "name": "Seychelles",
    "type": "Country",
    "code": 248,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:53.001Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:53.001Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08310078d7d45aff9ffeb"
    },
    "name": "Maldives",
    "type": "Country",
    "code": 960,
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:13:53.002Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:13:53.002Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9ffed"
    },
    "name": "Hinduism",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.075Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.075Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9ffee"
    },
    "name": "Islam",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.076Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.076Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9ffef"
    },
    "name": "Christianity",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.076Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.076Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff0"
    },
    "name": "Jainism",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.077Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.077Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff1"
    },
    "name": "Sikhism",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.077Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.077Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff2"
    },
    "name": "Buddhism",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.077Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.077Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff3"
    },
    "name": "Zoroastrianism",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.079Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.079Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff4"
    },
    "name": "Judaism",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.080Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.080Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff5"
    },
    "name": "Jewish",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.081Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.081Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff6"
    },
    "name": "Parsi",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.082Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.082Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff7"
    },
    "name": "Other",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.082Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.082Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff8"
    },
    "name": "No Religion",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.083Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.083Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08a9d078d7d45aff9fff9"
    },
    "name": "Spiritual-Not Religious",
    "type": "Religion",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:46:05.084Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:46:05.084Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45aff9fffb"
    },
    "name": "Andhra Pradesh",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.756Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.756Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45aff9fffc"
    },
    "name": "Arunachal Pradesh",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.757Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.757Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45aff9fffd"
    },
    "name": "Assam",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.757Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.757Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45aff9fffe"
    },
    "name": "Bihar",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.759Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.759Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45aff9ffff"
    },
    "name": "Chhattisgarh",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.760Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.760Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0000"
    },
    "name": "Goa",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.760Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.760Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0001"
    },
    "name": "Gujarat",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.760Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.760Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0002"
    },
    "name": "Haryana",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.761Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.761Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0003"
    },
    "name": "Himachal Pradesh",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.761Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.761Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0004"
    },
    "name": "Jharkhand",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.761Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.761Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0005"
    },
    "name": "Karnataka",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.761Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.761Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0006"
    },
    "name": "Kerala",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.762Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.762Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0007"
    },
    "name": "Madhya Pradesh",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.762Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.762Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0008"
    },
    "name": "Maharashtra",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.762Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.762Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0009"
    },
    "name": "Manipur",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.762Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.762Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa000a"
    },
    "name": "Meghalaya",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.763Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.763Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa000b"
    },
    "name": "Mizoram",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.763Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.763Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa000c"
    },
    "name": "Nagaland",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.763Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.763Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa000d"
    },
    "name": "Odisha",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.766Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.766Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa000e"
    },
    "name": "Punjab",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.767Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.767Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa000f"
    },
    "name": "Rajasthan",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.768Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.768Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0010"
    },
    "name": "Sikkim",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.768Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.768Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0011"
    },
    "name": "Tamil Nadu",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.769Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.769Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "name": "Telangana",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.770Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.770Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0013"
    },
    "name": "Tripura",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.770Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.770Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0014"
    },
    "name": "Uttar Pradesh",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.772Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.772Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0015"
    },
    "name": "Uttarakhand",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.772Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.772Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08b7f078d7d45affa0016"
    },
    "name": "West Bengal",
    "type": "State",
    "parentId": {
      "$oid": "65e08310078d7d45aff9ffbb"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:49:51.773Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:49:51.773Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663369"
    },
    "name": "Hindi",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.486Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.486Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac66336a"
    },
    "name": "English",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.489Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.489Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac66336b"
    },
    "name": "Marathi",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.491Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.491Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac66336c"
    },
    "name": "Urdu",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.492Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.492Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac66336d"
    },
    "name": "Punjabi",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.494Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.494Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac66336e"
    },
    "name": "Bengali",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.496Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.496Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac66336f"
    },
    "name": "Gujarati",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.496Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.496Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663370"
    },
    "name": "Tamil",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.496Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.496Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663371"
    },
    "name": "Marwari",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.496Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.496Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663372"
    },
    "name": "Odia",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.497Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.497Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663373"
    },
    "name": "Kannada",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.497Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.497Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663374"
    },
    "name": "Telugu",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.497Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.497Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663375"
    },
    "name": "Malayalam",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.497Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.497Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663376"
    },
    "name": "Assamese",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.498Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.498Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663377"
    },
    "name": "Konkani",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.499Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.499Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663378"
    },
    "name": "Sanskrit",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.499Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.499Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08d809b56a69eac663379"
    },
    "name": "Sindhi",
    "type": "Mothertounge",
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T13:58:24.500Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T13:58:24.500Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337b"
    },
    "name": "Hyderabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.274Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.274Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337c"
    },
    "name": "Warangal",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.274Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.274Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337d"
    },
    "name": "Karimnagar",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.275Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.275Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337e"
    },
    "name": "Khammam",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.276Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.276Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337f"
    },
    "name": "Ranga Reddy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.277Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.277Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663380"
    },
    "name": "Nalgonda",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.277Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.277Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663381"
    },
    "name": "Adilabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.278Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.278Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663382"
    },
    "name": "Mahbubnagar",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.279Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.279Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663383"
    },
    "name": "Nizamabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.279Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.279Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663384"
    },
    "name": "Medak",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.280Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.280Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663385"
    },
    "name": "Siddipet",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.280Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.280Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663386"
    },
    "name": "Jagtial",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.281Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.281Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663387"
    },
    "name": "Kothagudem",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.282Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.282Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663388"
    },
    "name": "Kamareddy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.282Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.282Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663389"
    },
    "name": "Sangareddy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.283Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.283Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338a"
    },
    "name": "Bhupalpalle",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.283Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.283Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338b"
    },
    "name": "Nirmal",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.285Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.285Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338c"
    },
    "name": "Wanaparthy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.286Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.286Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338d"
    },
    "name": "Peddapalli",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.287Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.287Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338e"
    },
    "name": "Suryapet",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.289Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.289Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338f"
    },
    "name": "Mancherial",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.290Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.290Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663390"
    },
    "name": "Yadadri Bhuvanagiri",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.291Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.291Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663391"
    },
    "name": "Jangaon",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.292Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.292Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663392"
    },
    "name": "Kamareddy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.292Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.292Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663393"
    },
    "name": "Komaram Bheem Asifabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.293Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.293Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663394"
    },
    "name": "Rajanna Sircilla",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.293Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.293Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663395"
    },
    "name": "Vikarabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.294Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.294Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663396"
    },
    "name": "Sircilla",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.295Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.295Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac663398"
    },
    "name": "Arya Vysya",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.085Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.085Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac663399"
    },
    "name": "Balagi",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac66339a"
    },
    "name": "Balaji Naidu",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac66339b"
    },
    "name": "Brahmin",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac66339c"
    },
    "name": "Goud",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac66339d"
    },
    "name": "Kamma",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.089Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac66339e"
    },
    "name": "Kapu",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac66339f"
    },
    "name": "Kapu Naidu",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a0"
    },
    "name": "Madiga",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a1"
    },
    "name": "Mala",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a2"
    },
    "name": "Mudiraj",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.090Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a3"
    },
    "name": "Naidu",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a4"
    },
    "name": "Padmasali",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a5"
    },
    "name": "Dobi",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a6"
    },
    "name": "Reddy",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a7"
    },
    "name": "SC",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.091Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a8"
    },
    "name": "Velama",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633a9"
    },
    "name": "Vishwabrahmin",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633aa"
    },
    "name": "Yadav",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633ab"
    },
    "name": "ST",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633ac"
    },
    "name": "OBC",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.092Z"
    }
  },
  {
    "_id": {
      "$oid": "65e15e429b56a69eac6633ad"
    },
    "name": "Other",
    "type": "Community",
    "__v": 0,
    "createdAt": {
      "$date": "2024-03-01T04:49:06.093Z"
    },
    "updatedAt": {
      "$date": "2024-03-01T04:49:06.093Z"
    }
  }, {
    "_id": {
      "$oid": "65e08de49b56a69eac66337b"
    },
    "name": "Hyderabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.274Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.274Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337c"
    },
    "name": "Warangal",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.274Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.274Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337d"
    },
    "name": "Karimnagar",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.275Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.275Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337e"
    },
    "name": "Khammam",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.276Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.276Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66337f"
    },
    "name": "Ranga Reddy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.277Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.277Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663380"
    },
    "name": "Nalgonda",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.277Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.277Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663381"
    },
    "name": "Adilabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.278Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.278Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663382"
    },
    "name": "Mahbubnagar",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.279Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.279Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663383"
    },
    "name": "Nizamabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.279Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.279Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663384"
    },
    "name": "Medak",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.280Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.280Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663385"
    },
    "name": "Siddipet",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.280Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.280Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663386"
    },
    "name": "Jagtial",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.281Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.281Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663387"
    },
    "name": "Kothagudem",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.282Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.282Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663388"
    },
    "name": "Kamareddy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.282Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.282Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663389"
    },
    "name": "Sangareddy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.283Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.283Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338a"
    },
    "name": "Bhupalpalle",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.283Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.283Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338b"
    },
    "name": "Nirmal",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.285Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.285Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338c"
    },
    "name": "Wanaparthy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.286Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.286Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338d"
    },
    "name": "Peddapalli",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.287Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.287Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338e"
    },
    "name": "Suryapet",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.289Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.289Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac66338f"
    },
    "name": "Mancherial",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.290Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.290Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663390"
    },
    "name": "Yadadri Bhuvanagiri",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.291Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.291Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663391"
    },
    "name": "Jangaon",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.292Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.292Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663392"
    },
    "name": "Kamareddy",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.292Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.292Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663393"
    },
    "name": "Komaram Bheem Asifabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.293Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.293Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663394"
    },
    "name": "Rajanna Sircilla",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.293Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.293Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663395"
    },
    "name": "Vikarabad",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.294Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.294Z"
    }
  },
  {
    "_id": {
      "$oid": "65e08de49b56a69eac663396"
    },
    "name": "Sircilla",
    "type": "City",
    "parentId": {
      "$oid": "65e08b7f078d7d45affa0012"
    },
    "__v": 0,
    "createdAt": {
      "$date": "2024-02-29T14:00:04.295Z"
    },
    "updatedAt": {
      "$date": "2024-02-29T14:00:04.295Z"
    }
  }]


export default generaldata;
