// connectionApi.js
import axiosInstance from "./axios";

const connectionApi = {
    getAllconnections: async (token) => {
        const response = await axiosInstance.get(`/connection/all`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getconnectionsRequests: async (token) => {
        const response = await axiosInstance.get(`/connection/all`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    },

    createconnection: async (receiverId, token) => {
        console.log(receiverId, token, "APi ddadada")
        const response = await axiosInstance.post('/connection', { receiverId }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    },

    removeconnection: async (receiverId, token) => {
        const response = await axiosInstance.post('/connections/removeconnection', { receiverId }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    },

    connectionstatus_update: async (id, accepted, token) => {
        console.log(id, accepted, token, "ppppppppppppp")
        const response = await axiosInstance.put(`/connection/${id}/${accepted}`, null, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    },

    mysavedprofiles: async (id, token) => {
        const response = await axiosInstance.get(`/match/all`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    },

    removesavedprofile: async (id, token) => {
        const response = await axiosInstance.get(`/match/remove/${id}/remove`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    },
};

export default connectionApi;
