

// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux'; // Assuming you're using Redux for state management
// import Header2 from './Header2';
// import Footer2 from './Footer2';
// import profileApi from '../../app/Services/Api/profileApi';
// import ConnectNow from './ConnectNow';
// import UpdateAbout from '../../app/UpdateDetails/UpdateAbout';

// import UpdateContact from '../../app/UpdateDetails/UpdateContact';
// import UpdateFamilyDetails from '../../app/UpdateDetails/UpdateFamilyDetails';
// import UpdateHoroScope from '../../app/UpdateDetails/UpdateHoroScope';
// import { useParams } from 'react-router-dom';
// import UpdateBasic from '../../app/UpdateDetails/UpdateBasic';
// import UpdateCareer from '../../app/UpdateDetails/UpdateCareer';

// export default function ViewProfile() {
//   const { profileId } = useParams();
//   const [details, setDetails] = useState(null);
//   const [connection, setConnection] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const token = useSelector((state) => state.auth?.token);

//   const getProfiles = async () => {
//     try {
//       const response = await profileApi.getuserprofile(profileId, token);
//       console.log(response, "ipopop")
//       setDetails(response.profile);
//       setConnection(response.connection);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error fetching profiles:', error);
//       setError(error.message);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (profileId) {
//       getProfiles();
//     }
//   }, [profileId, token]);

//   if (loading) {
//     return <div style={{ textAlign: 'center', font: '1.2em' }}>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   // Ensure details is not null before accessing nested properties
//   if (!details) {
//     return <div>No profile details found.</div>;
//   }

//   return (
//     <div>
//       <Header2 />
//       <div className="container-fluid" style={{
//         justifyContent: 'center',
//         alignItems: 'center',
//         minHeight: '130vh',
//         backgroundImage: 'linear-gradient(to bottom, rgba(44, 17, 84, 0.7), rgba(44, 17, 84, 0.45)), url("/images/1234.jpg")',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//       }}  >
//         {details ? (
//           <>
//             <ConnectNow details={details} connection={connection} getProfiles={getProfiles} />
//             {details.about ? (<UpdateAbout details={details} />) : " "}
//             {details.occupation ? (<UpdateCareer details={details} />) : " "}
//             <UpdateBasic details={details} />
//             {
//               !details?.contactprivacy ? (<UpdateContact details={details} />) : " "
//             }

//             {details?.fatherstatus ? (<UpdateFamilyDetails details={details} />) : " "}
//             {details?.rashi ? (<UpdateHoroScope detailss={details} />) : " "}
//           </>
//         ) : (
//           <div>
//             <p>No details available</p>
//           </div>
//         )}
//       </div>
//       <Footer2 />
//     </div>
//   );
// }
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header2 from './Header2';
import Footer2 from './Footer2';
import profileApi from '../../app/Services/Api/profileApi';
import ConnectNow from './ConnectNow';
import UpdateAbout from '../../app/UpdateDetails/UpdateAbout';
import UpdateContact from '../../app/UpdateDetails/UpdateContact';
import UpdateFamilyDetails from '../../app/UpdateDetails/UpdateFamilyDetails';
import UpdateHoroScope from '../../app/UpdateDetails/UpdateHoroScope';
import UpdateBasic from '../../app/UpdateDetails/UpdateBasic';
import UpdateCareer from '../../app/UpdateDetails/UpdateCareer';
import { Margin } from '@mui/icons-material';

export default function ViewProfile() {
  const { profileId } = useParams();
  const [details, setDetails] = useState(null);
  const [connection, setConnection] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth?.token);

  const getProfiles = async () => {
    try {
      const response = await profileApi.getuserprofile(profileId, token);
      console.log(response.profile.user.privacy, "lll");
      setDetails(response.profile);
      setConnection(response.connection);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileId) {
      getProfiles();
    }
  }, [profileId, token]);

  if (loading) {
    return <div style={{ textAlign: 'center', font: '1.2em' }}>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!details) {
    return <div>No profile details found.</div>;
  }

  return (
    <div>
      <Header2 />
      <div className="container-fluid"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'linear-gradient(to bottom, rgba(44, 17, 84, 0.9), rgba(44, 17, 84, 0.45)), url("/images/flowers.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>

        <div className='MyPref' style={{
          padding: '40px',
          background: 'white',
          borderRadius: '1px',
          color: 'white',
          height: "auto",
          maxWidth: '75vh',
          boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.0)',
          backgroundColor: 'rgba(44, 17, 84, 0.0)',
        }}>
          <p style={{ fontWeight: 'bold', textAlign: 'center', color: 'white', marginLeft: '30px' }}> Currently viewing <strong style={{ color: 'orange' }}>{details.fullName}&apos;s</strong>  Profile</p>

          <div className="col-md-6 mb-4" >
            <ConnectNow details={details} connection={connection} getProfiles={getProfiles} />
          </div>
          {details.about && (
            <div className="col-md-6 mb-4">
              <UpdateAbout details={details} />
            </div>
          )}
          {details.occupation && (
            <div className="col-md-6 mb-4">
              <UpdateCareer details={details} />
            </div>
          )}
          {details.fatherstatus && (
            <div className="col-md-6 mb-4">
              <UpdateFamilyDetails details={details} />
            </div>
          )}
          <div className="col-md-6 mb-4">
            <UpdateBasic details={details} />
          </div>
          {!details.contactprivacy && (
            <div className="col-md-6 mb-4">
              <UpdateContact details={details} />
            </div>
          )}
          {details.rashi && (
            <div className="col-md-6 mb-4">
              <UpdateHoroScope detailss={details} />
            </div>
          )}
        </div>
      </div>
      <Footer2 />
    </div>
  );
}
