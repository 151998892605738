import React from 'react';

export default function MemberSection() {
  return (
    <div>
      <section className="page-header-section ptb-50" style={{
        backgroundImage: `linear-gradient(to bottom, rgba(44, 17, 84, 0.8), rgba(44, 17, 84, 0.8)), url("/images/cour1.jpg")`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="page-header-content text-center">
                <h1 className="mb-3" style={{ color: 'white', fontSize: '2.5rem', fontWeight: 'bold', marginTop: "50px" }}>Membership Plans</h1>
                <p className="lead text-white responsive-text">Join Samasti community Today, and start your journey</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-section pt-5 pb-5" style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#2C1154', }}>
        <div className="container">
          <h2 className="section-title mb-4 text-center" style={{ color: "white" }}>Benefits of Premium Membership</h2>
          <div className="row justify-content-center" style={{ marginRight: '30px' }}>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card text-center">
                <div className="card-header bg-primary text-white">
                  <h4>Basic Plan</h4>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled">
                    <li>Access to basic features</li>
                    <li>Limited matches per month</li>
                    <li>Email support</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card text-center">
                <div className="card-header bg-success text-white">
                  <h4>Premium Plan</h4>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled">
                    <li>Access to premium features</li>
                    <li>Unlimited matches</li>
                    <li>24/7 customer support</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card text-center">
                <div className="card-header bg-warning text-white">
                  <h4>Ultimate Plan</h4>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled">
                    <li>Access to all features</li>
                    <li>Personalized matches</li>
                    <li>Priority support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-section pt-5 pb-5">
        <div className="container">
          <h2 className="text-center mb-4">Membership Plans</h2>
          <div className="row" style={{ marginRight: '30px' }}>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card mb-4">
                <div className="card-header bg-primary text-white text-center">
                  <h4>Basic Plan</h4>
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">₹500/month</h5>
                  <ul className="list-unstyled">
                    <li>Access to basic features</li>
                    <li>Limited matches per month</li>
                    <li>Email support</li>
                  </ul>
                  <button className="btn btn-primary btn-">Choose Plan</button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card mb-4">
                <div className="card-header bg-success text-white text-center">
                  <h4>Premium Plan</h4>
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">₹1000/month</h5>
                  <ul className="list-unstyled">
                    <li>Access to premium features</li>
                    <li>Unlimited matches</li>
                    <li>24/7 customer support</li>
                  </ul>
                  <button className="btn btn-success btn-">Choose Plan</button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card mb-4">
                <div className="card-header bg-warning text-white text-center">
                  <h4>Ultimate Plan</h4>
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">₹2000/month</h5>
                  <ul className="list-unstyled">
                    <li>Access to all features</li>
                    <li>Personalized matches</li>
                    <li>Priority support</li>
                  </ul>
                  <button className="btn btn-warning btn-">Choose Plan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="membership-section mt-5" style={{ backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundColor: '#2C1154', color: 'white' }}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <h2 className="section-title text-center mb-4" style={{ marginTop: "50px", color: 'white' }}>How Membership Works?</h2>
              <div className="row" >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h5 style={{ color: "white" }}>1. Membership Tiers</h5>
                  <p>Discover different membership tiers like Basic, Premium, and Ultimate plans, each offering unique benefits.</p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h5 style={{ color: "white" }}>2. Registration Process</h5>
                  <p>Complete a simple registration process. Provide personal details, create a profile, and select a plan.</p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h5 style={{ color: "white" }}>3. Access Profiles</h5>
                  <p>Gain access to view profiles of potential matches. Explore detailed profiles and connect with individuals.</p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h5 style={{ color: "white" }}>4. Communication with Matches</h5>
                  <p>Initiate communication through our messaging platform. Exchange messages and build connections.</p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h5 style={{ color: "white" }}>5. Profile Management</h5>
                  <p>Manage your profile to reflect your personality. Update information and add photos.</p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <img src="/images/marriage-8345923_1280.jpg" alt="Membership Image" className="img-fluid" style={{ width: "100%", height: "auto", marginTop: "60px", marginBottom: "50px" }} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
